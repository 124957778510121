import React, { useMemo } from 'react'
import { useRequest } from '../../services/api'
import AirTable from "../ui/table"
import moment from 'moment'

function BalanceSheetDifferences() {
  const { data, error } = useRequest(`admin/differences/balance-sheets`)

  const columns = useMemo(() => {
    return [
      {
        Header: 'Symbol',
        accessor: 'symbol',
      },
      {
        Header: 'BZ Freq',
        accessor: 'bz_freq',
      },
      {
        Header: 'MS Freq',
        accessor: 'ms_freq',
      },
      {
        Header: 'BZ Cash On Hand',
        accessor: 'bz_cash_on_hand',
      },
      {
        Header: 'MS Cash On Hand',
        accessor: 'ms_cash_on_hand',
      },
      {
        Header: 'BZ Shares Outstanding',
        accessor: 'bz_shares_outstanding',
      },
      {
        Header: 'MS Shares Outstanding',
        accessor: 'ms_shares_outstanding',
      },
      {
        Header: 'BZ Total Assets',
        accessor: 'bz_total_assets',
      },
      {
        Header: 'MS Total Assets',
        accessor: 'ms_total_assets',
      },
      {
        Header: 'BZ Total Debt',
        accessor: 'bz_total_debt',
      },
      {
        Header: 'MS Total Debt',
        accessor: 'ms_total_debt',
      },
      {
        Header: 'BZ Period',
        accessor: 'bz_period',
        Cell({ value }) {
          return moment(value).format('MM-DD-YYYY HH:mm')
        }
      },
      {
        Header: 'MS Period',
        accessor: 'ms_period',
        Cell({ value }) {
          return moment(value).format('MM-DD-YYYY HH:mm')
        }
      },
      {
        Header: 'Differences',
        accessor: 'differences',
        Cell({ value }) {
          return value.join(', ')
        }
      },
    ]
  }, [])

  return (
    <div className="container-fluid p-3">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h2>Balance Sheets Differences</h2>
      </div>
      <AirTable
        columns={columns}
        data={data}
        loading={!data && !error}
      />
    </div>
  )
}

export default React.memo(BalanceSheetDifferences)

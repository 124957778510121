import React from 'react'
import Select from 'react-select'
import moment from 'moment'

function AutoComplete(props) {
  const options = props.periods.map((s) => {
    return {
      value: s,
      label: moment(s).format('MM-DD-YYYY HH:mm')
    }
  })

  const defaultOption = {label: options[0]?.label, value: options[0]?.value}

  return (
    <div>
      {props.periods.length > 0 && options.length > 0 && <Select
        styles={{
          // Fixes the overlapping problem of the component
          menu: provided => ({ ...provided, zIndex: 9999 })
        }}
        options={options}
        onChange={props.onChange}
        defaultValue={props.defaultOption || defaultOption}
      />}
    </div>
  )
}

export default AutoComplete
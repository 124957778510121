import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

class Header extends Component {
  signOut = () => {
    localStorage.removeItem("btw_user");
    window.location.replace("/login");
  };

  render() {
    return (
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        {/* Left navbar links */}
        <ul className="navbar-nav">
          <li className="nav-item">
            <a
              className="nav-link"
              data-widget="pushmenu"
              href="#"
              role="button"
            >
              <i className="fas fa-bars" />
            </a>
          </li>
        </ul>
        {/* Right navbar links */}
        <ul className="navbar-nav ml-auto">
          {/* Messages Dropdown Menu */}
          <li className="nav-item dropdown">
            <a
              className="nav-link d-flex justify-content-center align-items-center"
              data-toggle="dropdown"
              href="#"
            >
              <span className="ml-2">
                {this.props.user.firstName + " " + this.props.user.lastName}
              </span>
            </a>
            <div className="dropdown-menu dropdown-menu-md dropdown-menu-right">
              <span className="dropdown-item">
                <div className="media">
                  <NavLink to="/profile">
                    Profile
                  </NavLink>
                </div>
              </span>
              <a href="#" className="dropdown-item" onClick={this.signOut}>
                <div className="media">
                  <span>Sign out</span>
                </div>
              </a>
            </div>
          </li>
        </ul>
      </nav>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, null)(Header);

import React, {useState} from 'react'
import Select from 'react-select'
import { useSymbols } from '../../../helpers/hooks/shared'

function AutoComplete(props) {
  const [search, setSearch] = useState('')
  const symbols = useSymbols(search)
  const options = symbols.map((s) => {
    return {
      value: s.symbol,
      label: s.symbol
    }
  })
  const defaultOption = {label: 'AAPL', value: 'AAPL'}
  const onKeyUp = (e) => {
    setSearch(e.target.value)
  }
  return (
    <div onKeyUp={onKeyUp}>
      <Select
        styles={{
          // Fixes the overlapping problem of the component
          menu: provided => ({ ...provided, zIndex: 9999 })
        }}
        options={options}
        onChange={props.onChange}
        defaultValue={props.defaultOption || defaultOption}
      />
    </div>
  )
}

export default AutoComplete
import React, {useMemo, useState} from 'react'
import {useRequest} from '../../services/api'
import moment from "moment"
import AirTable from "../ui/table"

function IpoCalendar() {
  const {data, error} = useRequest(`/admin/fin-hub/ipo-calendar`)
  const [requesting, setRequesting] = useState(false)

  const {ipoCalendar = []} = data || {}

  const columns = useMemo(() => {
    return [
      {
        Header: 'Symbol',
        accessor: 'symbol',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Exchange',
        accessor: 'exchange',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Price',
        accessor: 'price',
      },
      {
        Header: 'Total Shares Value',
        accessor: 'totalSharesValue',
      },
      {
        Header: 'Number Of Shares',
        accessor: 'numberOfShares',
      },
      {
        Header: 'Date',
        accessor: 'date',
        Cell({ value }) {
          return moment(value).format('MMMM Do YYYY')
        },
      },
    ]
  }, [requesting])

  return (
    <div className="container-fluid p-3">
      <div className="flex justify-between items-center mb-6">
        <h2>IPO Calendar</h2>
      </div>
      <AirTable
        columns={columns}
        data={ipoCalendar}
        loading={!data && !error}
      />
    </div>
  )
}

export default React.memo(IpoCalendar)

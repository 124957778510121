import QRCode from "qrcode";
import React, { useEffect, useState } from "react";
import { useField, useForm } from "react-final-form-hooks";
import { Modal } from "react-responsive-modal";
import { required } from "../../helpers/hooks/validators";
import { authService } from "../../services";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { onStoreUser } from "../../store/actions/auth";

function TwoFactorAuthModal({ showModal, setShowModal, secret }) {
  const [qrcodeUrl, setqrCodeUrl] = useState("");
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    QRCode.toDataURL(secret.otpAuthUrl).then(setqrCodeUrl);
  }, [secret.otpAuthUrl]);

  const { form, handleSubmit, pristine, submitting } = useForm({
    async onSubmit(values, formAPI) {
      try {
        console.log(values);
        const { data } = await authService.verifyOtp(values, formAPI);
        console.log(data);
        setShowModal(false);
        toast.success("2FA enabled successfully");
        // history.push("/trade-logs");

        dispatch(onStoreUser({ ...user, otpEnabled: data.otpEnabled, otpVerified: data.otpVerified }));
      } catch (err) {
        console.log(err);
        return toast.error("Invalid authentication code.");
      }
    },
    initialValues: {
      token: "",
    },
  });

  const fieldToken = useField("token", form, required);

  return (
    <Modal
      open={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      center
      styles={{ modal: { maxWidth: "700px", minWidth: "700px" } }}
    >
      <div>
        <h3>Two-Factor Authentication (2FA)</h3>
        <hr className="my-1" />
        <h4 className="text-primary mt-3">
          Configuring Google Authenticator or Authy
        </h4>
        <div>
          <li>
            Install Google Authenticator (IOS - Android) or Authy (IOS -
            Android).
          </li>
          <li>In the authenticator app, select "+" icon.</li>
          <li>
            Select "Scan a barcode (or QR code)" and use the phone's camera to
            scan this barcode.
          </li>
        </div>
        <div>
          <h4 className="text-primary mt-3">Scan QR Code</h4>

          <div className="d-flex justify-content-center">
            <img
              style={{ width: "256px", height: "256px" }}
              src={qrcodeUrl}
              alt="qr code"
            />
          </div>
        </div>
        <div>
          <h4 className="text-primary mt-3">Or Enter Code Into Your App</h4>
          <p className="text-sm">
            SecretKey: <span className="font-weight-bold">{secret.base32}</span>{" "}
            (Base32 encoded)
          </p>
        </div>
        <div>
          <h4 className="text-primary mt-3">Verify Code</h4>
          <p>
            For changing the settings, please verify the authentication code:
          </p>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <input
                    {...fieldToken.input}
                    placeholder="Enter token"
                    className="form-control rounded"
                  />
                  {fieldToken.meta.touched && fieldToken.meta.error && (
                    <span className="text-danger text-sm">
                      {fieldToken.meta.error}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-12 mt-4">
                <div className="form-group">
                  <div
                    className="btn btn-light mr-3"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={pristine || submitting}
                  >
                    Verify
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}

export default React.memo(TwoFactorAuthModal);

import React, { useMemo, useState } from 'react'
import { useRequest } from '../../services/api'
import AirTable from '../ui/table'
import moment from "moment";
import qs from "querystring";
import AutoComplete from "../ui/symbol-dropdown";

function EarningEstimatesListPage() {
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const query = qs.stringify({
    symbol: search,
    page,
    pageSize: 50,
  })

  const { data, error } = useRequest(`admin/fin-hub/earnings-estimates?${query}`)
  console.log(data)
  const { totalItems, totalPages, earningsEstimates = {} } = data || {}

  const columns = useMemo(() => {
    return [
      {
        Header: 'Eps Avg',
        accessor: 'epsAvg',
      },
      {
        Header: 'Eps High',
        accessor: 'epsHigh',
      },
      {
        Header: 'Eps Low',
        accessor: 'epsLow',
      },
      {
        Header: 'Number Analysts',
        accessor: 'numberAnalysts',
      },
      {
        Header: 'Period',
        accessor: 'period',
      },
    ]
  }, [])

  const onInputChange = (e) => {
    setSearch(e.value)
  }

  return (
    <div className="container-fluid p-3">
      <div className="d-flex justify-content-between align-items-center mb-6">
        <h2>Earning Estimates</h2>
        <div style={{ width: '320px' }}>
          <AutoComplete onChange={onInputChange} />
        </div>
      </div>
      <AirTable
        columns={columns}
        data={earningsEstimates?.data}
        loading={!data && !error}
        totalItem={totalItems}
        totalPage={totalPages}
        onChangePageIndex={setPage}
        pagging
      />
    </div>
  )
}

export default React.memo(EarningEstimatesListPage)

import AuthHandler from "./AuthHandler";

export default class SectorStockService {
  constructor(baseUrl) {
    if (!baseUrl) {
      throw new Error("missing baseUrl parameter");
    }

    this.baseUrl = baseUrl;
    this.authHandler = new AuthHandler(baseUrl); // Initialize AuthHandler
    this.axiosInstance = this.authHandler.axiosInstance; // Use axiosInstance from AuthHandler
  }

  async create(data) {
    try {
      return await this.authHandler.requestWithTokenRefresh(() =>
        this.axiosInstance.put(`${this.baseUrl}/admin/sector-stocks`, data)
      );
    } catch (error) {
      console.error("Error creating sector stocks:", error);
      throw error;
    }
  }

  async delete(id) {
    try {
      return await this.authHandler.requestWithTokenRefresh(() =>
        this.axiosInstance.delete(`${this.baseUrl}/admin/sector-stocks/${id}`)
      );
    } catch (error) {
      console.error(`Error deleting sector stocks with ID ${id}:`, error);
      throw error;
    }
  }

  async edit(data, id) {
    try {
      return await this.authHandler.requestWithTokenRefresh(() =>
        this.axiosInstance.post(
          `${this.baseUrl}/admin/sector-stocks/${id}`,
          data
        )
      );
    } catch (error) {
      console.error(`Error editing sector stocks with ID ${id}:`, error);
      throw error;
    }
  }

  async getStocks(q) {
    try {
      return await this.authHandler.requestWithTokenRefresh(() =>
        this.axiosInstance.get(
          `${this.baseUrl}/admin/sector-stocks/stocks?q=${q}`
        )
      );
    } catch (error) {
      console.error("Error getting stocks:", error);
      throw error;
    }
  }

  async searchSectorStocks(q) {
    try {
      return await this.authHandler.requestWithTokenRefresh(() =>
        this.axiosInstance.get(
          `${this.baseUrl}/admin/sector-stocks/search?q=${q}`
        )
      );
    } catch (error) {
      console.error("Error searching sector stocks:", error);
      throw error;
    }
  }

  async getSectors() {
    try {
      return await this.authHandler.requestWithTokenRefresh(() =>
        this.axiosInstance.get(`${this.baseUrl}/admin/sector-stocks/sectors`)
      );
    } catch (error) {
      console.error("Error getting sectors:", error);
      throw error;
    }
  }

  async getAdvisers() {
    try {
      return await this.authHandler.requestWithTokenRefresh(() =>
        this.axiosInstance.get(`${this.baseUrl}/admin/advisers`)
      );
    } catch (error) {
      console.error("Error getting advisers:", error);
      throw error;
    }
  }

  async getMarkets() {
    try {
      return await this.authHandler.requestWithTokenRefresh(() =>
        this.axiosInstance.get(`${this.baseUrl}/admin/markets`)
      );
    } catch (error) {
      console.error("Error getting markets:", error);
      throw error;
    }
  }

  async getIndexes() {
    try {
      return await this.authHandler.requestWithTokenRefresh(() =>
        this.axiosInstance.get(`${this.baseUrl}/admin/indexes`)
      );
    } catch (error) {
      console.error("Error getting indexes:", error);
      throw error;
    }
  }
}

import React, { useMemo, useState } from 'react'
import { useRequest } from '../../services/api'
import AirTable from '../ui/table'
import moment from "moment";
import AutoComplete from "../ui/symbol-dropdown";
import qs from "querystring";

function RecommendationTrends() {
  const [search, setSearch] = useState('')
  const query = qs.stringify({
    symbol: search,
  })
  const { data, error } = useRequest(`/admin/fin-hub/recommendation-trends?${query}`)
  const [requesting, setRequesting] = useState(false)

  const {recommendationTrends = []} = data || {}

  const columns = useMemo(() => {
    return [
      {
        Header: 'Symbol',
        accessor: 'symbol',
      },
      {
        Header: 'Buy',
        accessor: 'buy',
      },
      {
        Header: 'Strong Buy',
        accessor: 'strongBuy',
      },
      {
        Header: 'Sell',
        accessor: 'sell',
      },
      {
        Header: 'Strong Sell',
        accessor: 'strongSell',
      },
      {
        Header: 'Hold',
        accessor: 'hold',
      },
      {
        Header: 'Period',
        accessor: 'period',
        Cell({ value }) {
          return moment(value).format('MMMM Do YYYY')
        },
      },
    ]
  }, [requesting])

  const onInputChange = (e) => {
    setSearch(e.value)
  }

  return (
    <div className="container-fluid p-3">
      <div className="d-flex justify-content-between align-items-center mb-6">
        <h2>Recommendation Trends</h2>
        <div style={{width: '320px'}}>
          <AutoComplete onChange={onInputChange}/>
        </div>
      </div>
      <AirTable
        columns={columns}
        data={recommendationTrends}
        loading={!data && !error}
      />
    </div>
  )
}

export default React.memo(RecommendationTrends)
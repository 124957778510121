import React, { Component } from "react";
import { onLogin } from "../../store/actions/auth";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Alert from "../../ui/alert";

class Login extends Component {
  state = {
    email: "",
    password: "",
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onFormSubmit = () => {
    const email = this.state.email;
    const password = this.state.password;

    this.props.onLogin({ email, password });
  };

  render() {
    if (this.props.user) {
      return <Redirect to="/" />;
    }

    if (this.props.location.pathname !== "/login") {
      return <Redirect to="/login" />;
    }

    return (
      <div className="container-fluid d-flex justify-content-center">
        <div className="login-box">
          <div className="login-logo">
            <b>Admin</b> Trillions
          </div>
          {/* /.login-logo */}
          <div className="card">
            <div className="card-body login-card-body">
              <p className="login-box-msg">Sign in to start your session</p>
              <div className="input-group mb-3">
                <input
                  type="email"
                  value={this.state.email}
                  onChange={this.onChange}
                  className="form-control"
                  name="email"
                  placeholder="Email"
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope" />
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input
                  type="password"
                  value={this.state.password}
                  onChange={this.onChange}
                  name="password"
                  className="form-control"
                  placeholder="Password"
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock" />
                  </div>
                </div>
              </div>
              <div className="row">
                {/* /.col */}
                <div className="col-5">
                  <button
                    type="submit"
                    onClick={this.onFormSubmit}
                    disabled={this.props.loading}
                    className="btn btn-primary btn-block"
                  >
                    {this.props.loading ? "Signing in..." : "Sign In"}
                  </button>
                </div>
                <div style={{ paddingTop: "30px" }}>
                  {this.props.errorMessage ? (
                    <Alert
                      cssClass="alert-danger"
                      message={this.props.errorMessage}
                    />
                  ) : null}
                </div>
                {/* /.col */}
              </div>
              {/* /.social-auth-links */}
            </div>
            {/* /.login-card-body */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    loading: state.auth.loading,
    errorMessage: state.auth.errorMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (data) => dispatch(onLogin(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

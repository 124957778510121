import React, { useMemo } from 'react'
import { useRequest } from '../../services/api'
import AirTable from "../ui/table"
import helpers from '../../services/helpers'

function EarningsNetIncomeGrowth() {
  const { data, error } = useRequest(`/admin/fin-hub/earnings-net-income-growth`)

  const { earnings = [] } = data || {}

  const columns = useMemo(() => {
    return [
      {
        Header: 'Date',
        accessor: 'date',
      },
      {
        Header: 'EPS actual',
        accessor: 'epsActual',
      },
      {
        Header: 'EPS estimate',
        accessor: 'espEstimate',
      },
      {
        Header: 'Hour',
        accessor: 'hour',
      },
      {
        Header: 'Quarter',
        accessor: 'quarter',
      },
      {
        Header: 'Revenue actual',
        accessor: 'revenueActual',
        Cell: ({ value }) => {
          return helpers.formatCurrency(value)
        }
      },
      {
        Header: 'Revenue estimate',
        accessor: 'revenueEstimate',
        Cell: ({ value }) => {
          return helpers.formatCurrency(value)
        }
      },
      {
        Header: 'Symbol',
        accessor: 'symbol',
      },
      {
        Header: 'Year',
        accessor: 'year',
      },
      {
        Header: 'Growth from previous quarter',
        accessor: 'growthFromPreviousQuarter',
      },
      {
        Header: 'Growth from last year quarter',
        accessor: 'growthFromLastYearQuarter',
      },
    ]
  }, [])

  return (
    <div className="container-fluid p-3">
      <div className="flex justify-between items-center mb-6">
        <h2>Earnings / Net income growth</h2>
      </div>
      <AirTable
        columns={columns}
        data={earnings}
        loading={!data && !error}
      />
    </div>
  )
}

export default React.memo(EarningsNetIncomeGrowth)

import React, { useCallback } from 'react'
import Form from './Form'
import { sectorStockService } from '../../services/index'
import { toast } from 'react-toastify'

function SectorStocksCreatePage({ history }) {
  const onCreate = useCallback(
    async (data, form) => {
      try {
        await sectorStockService.create(data)
        history.push('/sector-stocks')
        toast.success('New sector stock created')
      } catch (err) {
        return toast.error(err.response.data.message)
      }
    },
    [history],
  )
  return (
    <div className="p-3">
      <div className="row">
        <div className="col-md-12">
          <h3>Create sector stock</h3>
        </div>
        <div className="col-12">
          <Form onSave={onCreate}/>
        </div>
      </div>
    </div>
  )
}

export default SectorStocksCreatePage

import React, { useMemo, useState } from 'react'
import AirTable from '../ui/table'
import { useRequest } from '../../services/api'
import _ from 'lodash'
import AutoComplete from '../ui/autocomplete'
import { useSearchSectorStocks } from '../../helpers/hooks/shared'
import qs from 'querystring'
import Select from 'react-select'

function MonthlyPriceChangeListPage() {
  const [search, setSearch] = useState('')
  const [period, setPeriod] = useState({ label: 'Month', value: 'month' })
  const [symbol, setSymbol] = useState({})
  const [page, setPage] = useState(1)

  const stocks = useSearchSectorStocks(search)

  const stocksOptions = stocks?.map((s) => {
    return {
      value: s,
      label: s,
    }
  })

  const query = qs.stringify({
    symbol: symbol.value || "",
    interval: period,
  })

  const onKeyUp = (e) => {
    setSearch(e.target.value)
  }

  const { data } = useRequest(`/admin/fin-hub/monthly-price-changes?${query}`)

  const { items = [], totalItems, totalPages } = data || {}

  const columns = useMemo(() => {
    return [
      {
        Header: 'Symbol',
        accessor: 'symbol',
      },
      {
        Header: 'Period',
        accessor: 'period',
      },
      {
        Header: 'Close',
        accessor: 'close',
      },
      {
        Header: 'Previous Close',
        accessor: 'prev_close',
      },
      {
        Header: 'Change',
        accessor: 'change',
      },
    ]
  }, [items])

  return (
    <div className="container-fluid p-3">
      <div className="row">
        <div className="col-md-6">
          <div className="flex justify-between items-center mb-3">
            <label className="font-bold text-sm">Select symbol</label>
            <div className="w-100">
              <AutoComplete
                options={stocksOptions}
                value={stocksOptions.filter((o) => o.symbol === symbol.name)[0] || ""}
                onChange={(e) => {
                  console.log(e)
                  setSymbol(e)
                  setPage(1)
                }}
                inputProps={{ className: "bg-snow w-100 text-sm rounded-lg font-poppins border" }}
                onKeyUp={(e) => {
                  console.log(e, 'keyup')
                  onKeyUp(e)
                }}
                getOptionLabel={(option) => option.symbol}
                getOptionValue={(option) => option.symbol}
                className=""
                styles={{
                  control: (provided) => ({
                    ...provided,
                    border: '0',
                    backgroundColor: 'white',
                    boxShadow: 'none',
                  }),
                  singleValue: (provided) => ({ ...provided, paddingLeft: '5px' }),
                  menu: (provided) => ({
                    ...provided,
                    left: '0px',
                    zIndex: 99999999
                  }),
                  valueContainer: (provided) => ({
                    ...provided,
                    padding: 0,
                  }),
                  indicatorSeparator: () => ({ display: 'none' }),
                }}
              />
              <label>Period:</label>
              <Select
                options={[
                  { label: 'Month', value: 'month' },
                  { label: 'Week', value: 'week' },
                  { label: 'Day', value: 'day' },
                ]}
                styles={{
                  menu: provided => ({ ...provided, zIndex: 9999 })
                }}
                value={period}
                onChange={(e) => setPeriod(e.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="d-flex w-100 justify-content-between align-items-center mb-2">
            <h2>Monthly Price Change</h2>
          </div>
          <AirTable
            columns={columns}
            data={items}
            loading={_.isEmpty(data)}
            totalItem={totalItems}
            totalPage={totalPages}
            pagging
          />
        </div>
      </div>
    </div>
  )
}

export default React.memo(MonthlyPriceChangeListPage)

import React, { useEffect, useMemo, useState } from 'react'
import { useField, useForm } from 'react-final-form-hooks'
import { required } from '../../helpers/hooks/validators'
import { useAdvisers, useIndexes, useMarkets, useSectors, useStocks } from '../../helpers/hooks/shared'
import AutoComplete from '../ui/autocomplete'
import _ from 'lodash'
import moment from 'moment'
import { toast, ToastContainer } from 'react-toastify'

function Form({ data = {}, onSave = () => null }) {
  const [q, setQ] = useState('')
  const [loading, setLoading] = useState(true)
  const [companyName, setCompanyName] = useState(data?.companyName)

  const stocks = useStocks(q)
  const sectors = useSectors()
  const advisers = useAdvisers()
  const indexes = useIndexes()
  const markets = useMarkets()

  const onKeyUp = (e) => {
    setQ(e.target.value)
  }

  const stocksOptions = stocks?.map((s) => {
    return {
      value: s.symbol,
      label: s.symbol,
      companyName: s.name
    }
  })

  const sectorOptions = useMemo(() => sectors?.map((s) => {
    return {
      value: s.id,
      label: s.name
    }
  }), [sectors])

  const indexOptions = indexes.map((s) => {
    return {
      value: s.id,
      label: s.name
    }
  })

  const adviserOptions = advisers?.map((s) => {
    return {
      value: s.id,
      label: s.name
    }
  })

  const marketOptions = markets?.map((s) => {
    return {
      value: s.id,
      label: s.name
    }
  })

  const initAnalystRecommendations = useMemo(() => {
    if (!_.size(data.analystRecommendations)) {
      return [{}]
    }
    return data.analystRecommendations.map((c) => ({
      date: c.date,
      id: c.id,
      buy: c.buy,
      sell: c.sell
    }))
  }, [data.analystRecommendations])

  const initStockFundOwnership = useMemo(() => {
    if (!_.size(data.stockFundOwnership)) {
      return [{}]
    }
    return data.stockFundOwnership.map((c) => ({
      filingDate: c.filingDate,
      symbol: c.symbol,
      value: c.value,
      shares: c.shares
    }))
  }, [data.stockFundOwnership])

  const initEstimates = useMemo(() => {
    if (!_.size(data.estimates)) {
      return [{}]
    }
    return data.estimates.map((c) => ({
      filingDate: c.filingDate,
      revenue: c.revenue,
      netIncome: c.netIncome,
      statementType: c.statementType,
      quarter: c.quarter,
      earningsPerShare: c.earningsPerShare
    }))
  }, [data.estimates])

  const initPriceTargets = useMemo(() => {
    if (!_.size(data.priceTargets)) {
      return [{}]
    }
    return data.priceTargets.map((c) => ({
      date: c.date,
      analystFirm: c.analystFirm,
      currentRating: c.currentRating,
      priceTarget: c.priceTarget,
    }))
  }, [data.priceTargets])

  const initFinancials = useMemo(() => {
    if (!_.size(data.financials)) {
      return [{}]
    }
    return data.financials.map((c) => ({
      filingDate: c.filingDate,
      sales: c.sales,
      netIncome: c.netIncome,
      cashOnHand: c.cashOnHand,
      statementType: c.statementType,
      quarter: c.quarter,
      debt: c.debt,
      earningsPerShare: c.earningsPerShare,
      netOperatingCashFlow: c.netOperatingCashFlow
    }))
  }, [data.financials])

  const initGrowthRates = useMemo(() => {
    if (!_.size(data.growthRates)) {
      return [{}]
    }
    return data.growthRates.map((c) => ({
      date: c.date,
      growth: c.growth,
      type: c.type,
    }))
  }, [data.growthRates])

  const { form, handleSubmit, pristine, submitting } = useForm({
    async onSubmit(values, formAPI) {
      let sectorId = []
      if (values.sector) {
        values.sector.map((sector) => {
          sectorId.push(sector.value)
        })
      } else {
        sectorId = data.sectorId || []
      }

      let adviserId = []
      if (values.adviser) {
        values.adviser.map((adviser) => {
          adviserId.push(adviser.value)
        })
      } else {
        adviserId = data.adviserId || []
      }

      let marketId = []
      if (values.market) {
        values.market.map((market) => {
          marketId.push(market.value)
        })
      } else {
        marketId = data.marketId || []
      }

      let indexId = []
      if (values.index) {
        values.index.map((index) => {
          indexId.push(index.value)
        })
      } else {
        indexId = data.indexId || []
      }

      //removing symbol from values if data symbol exist
      data.symbol && delete values.symbol

      // if (!values.analystRecommendations[0]?.date || !values.analystRecommendations[0]?.buy || !values.analystRecommendations[0]?.sell) {
      //   return toast.error('Analyst Recommendations are required.')
      // }

      // if (values.stockFundOwnership[0]?.filingDate &&
      //   !values.stockFundOwnership[0]?.shares
      // ) {
      //   return toast.error('Stock Fund Ownership shares is required.')
      // }

      // if (!values.financials[0]?.filingDate ||
      //   !values.financials[0]?.sales ||
      //   !values.financials[0]?.netIncome ||
      //   !values.financials[0]?.cashOnHand ||
      //   !values.financials[0]?.statementType
      // ) {
      //   return toast.error('Financials are required.')
      // }


      try {
        await onSave(
          {
            companyName: values.companyName || companyName,
            symbol: values?.symbol?.value,
            sectorId: sectorId || data.sectorId,
            adviserId: adviserId || data.adviserId,
            indexId: indexId || data.indexId,
            marketId: marketId || data.marketId,
            sharesOutstanding: values.sharesOutstanding,
            analystRecommendations: values.analystRecommendations,
            stockFundOwnership: values.stockFundOwnership,
            financials: values.financials,
            estimates: values.estimates,
            priceTargets: values.priceTargets,
            growthRates: values.growthRates,
          }
        )
      } catch (err) {
        console.error(err)
      }
    },
    initialValues: {
      companyName: data.companyName,
      symbol: data.symbol,
      sectorId: data.sector,
      adviserId: data.adviser,
      indexId: data.index,
      marketId: data.market,
      sharesOutstanding: data.sharesOutstanding,
      analystRecommendations: initAnalystRecommendations,
      stockFundOwnership: initStockFundOwnership,
      financials: initFinancials,
      estimates: initEstimates,
      priceTargets: initPriceTargets,
      growthRates: initGrowthRates,
    },
  })

  const fieldCompanyName = useField('companyName', form)
  const fieldSymbol = useField('symbol', form)
  const fieldSector = useField('sector', form)
  const fieldAdviser = useField('adviser', form)
  const fieldMarket = useField('market', form)
  const fieldIndex = useField('index', form)
  const fieldSharesOutstanding = useField('sharesOutstanding', form, required)
  const fieldAnalystRecommendations = useField('analystRecommendations', form, required)
  const fieldStockFundOwnership = useField('stockFundOwnership', form)
  const fieldFinancials = useField('financials', form)
  const fieldEstimates = useField('estimates', form)
  const fieldPriceTargets = useField('priceTargets', form)
  const fieldGrowthRates = useField('growthRates', form)

  useEffect(() => {
    if (sectorOptions.length > 0 && adviserOptions.length > 0 && marketOptions.length > 0 && indexOptions.length > 0) {
      setLoading(false)
    }
  }, [sectorOptions, adviserOptions, marketOptions, indexOptions])

  return (
    !loading && (
      <form className="bg-white rounded p-3" onSubmit={handleSubmit}>
        <ToastContainer/>
        <div className="row">
          <div className="col-lg-12">
            <div className="form-group">
              <label className="d-block">Symbol</label>
              {data.symbol ?
                <input className="form-control rounded" disabled={true} value={data.symbol}/>
                : (
                  <>
                    <AutoComplete
                      {...fieldSymbol.input}
                      className="form-control rounded"
                      onKeyUp={(e) => onKeyUp(e)}
                      onChange={(e) => {
                        setCompanyName(e.companyName)
                        fieldSymbol.input.onChange(e)
                      }}
                      value={data.sectorId || fieldSymbol.input.value}
                      options={stocksOptions}
                      isMulti={false}
                    />
                    {fieldSymbol.meta.touched && fieldSymbol.meta.error && (
                      <span className="text-danger text-sm">{fieldSymbol.meta.error}</span>
                    )}
                  </>
                )}
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-group">
              <label>Company Description</label>
              <textarea
                rows={10}
                value={data.companyProfile?.description}
                className="form-control rounded"
                disabled={true}
              />
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-group">
              <label>Company Name</label>
              <input
                {...fieldCompanyName.input}
                placeholder="Company Name"
                className="form-control rounded"
                value={companyName}
                onChange={(e) => {
                  fieldCompanyName.input.onChange(e)
                  setCompanyName(e.target.value)
                }}
              />
              {fieldCompanyName.meta.touched && fieldCompanyName.meta.error && (
                <span className="text-danger text-sm">{fieldCompanyName.meta.error}</span>
              )}
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-group">
              <label>Sector</label>
              <AutoComplete
                {...fieldSector.input}
                className="form-control rounded"
                onChange={(e) => {
                  fieldSector.input.onChange(e)
                }}
                options={sectorOptions}
                isMulti={true}
                defaultOption={data.sectorId && true}
                selectedOptions={data.sectorId}
              />
              {fieldSector.meta.touched && fieldSector.meta.error && (
                <span className="text-danger text-sm">{fieldSector.meta.error}</span>
              )}
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-group">
              <label>Adviser</label>
              <AutoComplete
                {...fieldAdviser.input}
                className="form-control rounded"
                onChange={(e) => {
                  fieldAdviser.input.onChange(e)
                  if (e === null) {
                    fieldAdviser.input.value = []
                  }
                }}
                options={adviserOptions}
                isMulti={true}
                defaultOption={data.adviserId && true}
                selectedOptions={data.adviserId}
              />
              {fieldAdviser.meta.touched && fieldAdviser.meta.error && (
                <span className="text-danger text-sm">{fieldAdviser.meta.error}</span>
              )}
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-group">
              <label>Market</label>
              <AutoComplete
                {...fieldMarket.input}
                className="form-control rounded"
                onChange={fieldMarket.input.onChange}
                options={marketOptions}
                isMulti={true}
                defaultOption={data.marketId && true}
                selectedOptions={data.marketId}
              />
              {fieldMarket.meta.touched && fieldMarket.meta.error && (
                <span className="text-danger text-sm">{fieldMarket.meta.error}</span>
              )}
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-group">
              <label>Index</label>
              <AutoComplete
                {...fieldIndex.input}
                className="form-control rounded"
                onChange={fieldIndex.input.onChange}
                options={indexOptions}
                isMulti={true}
                defaultOption={data.indexId && true}
                selectedOptions={data.indexId}
              />
              {fieldIndex.meta.touched && fieldIndex.meta.error && (
                <span className="text-danger text-sm">{fieldIndex.meta.error}</span>
              )}
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-group">
              <label>Shares Outstanding</label>
              <input
                {...fieldSharesOutstanding.input}
                placeholder="Shares Outstanding"
                className="form-control rounded"
                type='number'
              />
              {fieldSharesOutstanding.meta.touched && fieldSharesOutstanding.meta.error && (
                <span className="text-danger text-sm">{fieldSharesOutstanding.meta.error}</span>
              )}
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-group row">
              <div className="col-md-12">
                <label style={{ color: '#2821E6' }}>Analyst Recommendations</label>
              </div>
              {_.map(fieldAnalystRecommendations.input.value, (recommendation, idx) => (
                <div className="col-md-12" key={idx}>
                  <div className="row mt-2">
                    <div className="col-md-4">
                      <label className="text-sm">Date</label>
                      <input
                        type="date"
                        className="form-control rounded mb-2"
                        value={recommendation?.date}
                        onChange={(e) => {
                          console.log(e.target.value)
                          const { value = [], onChange } = fieldAnalystRecommendations.input
                          onChange(value.map((c, index) => (index === idx ? {
                            ...c,
                            date: e.target.value
                          } : c)))
                        }}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="text-sm">Buy</label>
                      <input
                        type="text"
                        className="form-control rounded mb-2"
                        value={recommendation?.buy}
                        placeholder="Buy"
                        onChange={(e) => {
                          const { value = [], onChange } = fieldAnalystRecommendations.input
                          onChange(value.map((c, index) => (index === idx ? {
                            ...c,
                            buy: e.target.value
                          } : c)))
                        }}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="text-sm">Sell</label>
                      <input
                        type="text"
                        className="form-control rounded mb-2"
                        value={recommendation?.sell}
                        placeholder="Sell"
                        onChange={(e) => {
                          const { value = [], onChange } = fieldAnalystRecommendations.input
                          onChange(value.map((c, index) => (index === idx ? {
                            ...c,
                            sell: e.target.value
                          } : c)))
                        }}
                      />
                    </div>
                    <div className="col-md-12 mb-3">
                      <button
                        type="button"
                        className="btn-danger btn-square mt-1"
                        onClick={() =>
                          fieldAnalystRecommendations.input.onChange(
                            fieldAnalystRecommendations.input.value.filter((c, index) => index !== idx),
                          )
                        }
                      >
                        <i className="fa fa-trash"/>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
              <div className="col-md-12">
                <button
                  type="button"
                  className="btn btn-primary px-2 py-0 btn-sm"
                  onClick={() => fieldAnalystRecommendations.input.onChange([...fieldAnalystRecommendations.input.value, null])}
                >
                  + Add more
                </button>
              </div>
            </div>
          </div>
          
          <div className="col-lg-12">
            <div className="form-group row">
              <div className="col-md-12">
                <label style={{ color: '#2821E6' }}>Estimates</label>
              </div>
              {_.map(fieldEstimates.input.value, (item, idx) => (
                <div className="col-md-12" key={idx}>
                  <div className="row mt-2">
                    <div className="col-md-2">
                      <label className="text-sm">Date</label>
                      <input
                        type="date"
                        className="form-control rounded mb-2"
                        value={item?.filingDate}
                        onChange={(e) => {
                          const { value = [], onChange } = fieldEstimates.input
                          onChange(value.map((c, index) => (index === idx ? {
                            ...c,
                            filingDate: e.target.value
                          } : c)))
                        }}
                      />
                    </div>
                    <div className="col-md-2">
                      <label className="text-sm">Revenue</label>
                      <input
                        type="text"
                        className="form-control rounded mb-2"
                        value={item?.revenue}
                        placeholder="Revenue"
                        onChange={(e) => {
                          const { value = [], onChange } = fieldEstimates.input
                          onChange(value.map((c, index) => (index === idx ? {
                            ...c,
                            revenue: e.target.value
                          } : c)))
                        }}
                      />
                    </div>
                    <div className="col-md-2">
                      <label className="text-sm">Net Income</label>
                      <input
                        type="text"
                        className="form-control rounded mb-2"
                        value={item?.netIncome}
                        placeholder="Net Income"
                        onChange={(e) => {
                          const { value = [], onChange } = fieldEstimates.input
                          onChange(value.map((c, index) => (index === idx ? {
                            ...c,
                            netIncome: e.target.value
                          } : c)))
                        }}
                      />
                    </div>
                    <div className="col-md-2">
                      <label className="text-sm">Statement Type</label>
                      <select
                        className="form-control rounded mb-2"
                        defaultValue={item?.statementType}
                        onChange={(e) => {
                          const { value = [], onChange } = fieldEstimates.input
                          onChange(value.map((c, index) => (index === idx ? {
                            ...c,
                            statementType: e.target.value
                          } : c)))
                        }}
                      >
                        <option>Please select...</option>
                        <option value="annual">Annual</option>
                        <option value="quarterly">Quarterly</option>
                      </select>
                    </div>
                    <div className="col-md-2">
                      <label className="text-sm">Quarter</label>
                      <select
                        className="form-control rounded mb-2"
                        defaultValue={item?.quarter}
                        onChange={(e) => {
                          const { value = [], onChange } = fieldEstimates.input
                          onChange(value.map((c, index) => (index === idx ? {
                            ...c,
                            quarter: e.target.value
                          } : c)))
                        }}
                      >
                        <option value="">Please select...</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                      </select>
                    </div>
                    <div className="col-md-2">
                      <label className="text-sm">Earnings per Share</label>
                      <input
                        type="text"
                        className="form-control rounded mb-2"
                        value={item?.earningsPerShare}
                        placeholder="Earnings per Share"
                        onChange={(e) => {
                          const { value = [], onChange } = fieldEstimates.input
                          onChange(value.map((c, index) => (index === idx ? {
                            ...c,
                            earningsPerShare: e.target.value
                          } : c)))
                        }}
                      />
                    </div>
                    <div className="col-md-12 mb-3">
                      <button
                        type="button"
                        className="btn-danger btn-square mt-1"
                        onClick={() =>
                          fieldEstimates.input.onChange(
                            fieldEstimates.input.value.filter((c, index) => index !== idx),
                          )
                        }
                      >
                        <i className="fa fa-trash"/>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
              <div className="col-md-12">
                <button
                  type="button"
                  className="btn btn-primary px-2 py-0 btn-sm"
                  onClick={() => fieldEstimates.input.onChange([...fieldEstimates.input.value, null])}
                >
                  + Add more
                </button>
              </div>
            </div>
          </div>


          <div className="col-lg-12">
            <div className="form-group row">
              <div className="col-md-12">
                <label style={{ color: '#2821E6' }}>Fund ownership</label>
              </div>
              {_.map(fieldStockFundOwnership.input.value, (ownership, idx) => (
                <div className="col-md-12" key={idx}>
                  <div className="row mt-2">
                    <div className="col-md-4">
                      <label className="text-sm">Filling Date</label>
                      <input
                        type="date"
                        className="form-control rounded mb-2"
                        value={ownership?.filingDate}
                        onChange={(e) => {
                          const { value = [], onChange } = fieldStockFundOwnership.input
                          onChange(value.map((c, index) => (index === idx ? {
                            ...c,
                            filingDate: e.target.value
                          } : c)))
                        }}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="text-sm">Value</label>
                      <input
                        type="text"
                        className="form-control rounded mb-2"
                        value={ownership?.value}
                        placeholder="value"
                        onChange={(e) => {
                          const { value = [], onChange } = fieldStockFundOwnership.input
                          onChange(value.map((c, index) => (index === idx ? {
                            ...c,
                            value: e.target.value
                          } : c)))
                        }}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="text-sm">Shares</label>
                      <input
                        type="text"
                        className="form-control rounded mb-2"
                        value={ownership?.shares}
                        placeholder="Shares"
                        onChange={(e) => {
                          const { value = [], onChange } = fieldStockFundOwnership.input
                          onChange(value.map((c, index) => (index === idx ? {
                            ...c,
                            shares: e.target.value
                          } : c)))
                        }}
                      />
                    </div>
                    <div className="col-md-12 mb-3">
                      <button
                        type="button"
                        className="btn-danger btn-square mt-1"
                        onClick={() =>
                          fieldStockFundOwnership.input.onChange(
                            fieldStockFundOwnership.input.value.filter((c, index) => index !== idx),
                          )
                        }
                      >
                        <i className="fa fa-trash"/>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
              <div className="col-md-12">
                <button
                  type="button"
                  className="btn btn-primary px-2 py-0 btn-sm"
                  onClick={() => fieldStockFundOwnership.input.onChange([...fieldStockFundOwnership.input.value, null])}
                >
                  + Add more
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-group row">
              <div className="col-md-12">
                <label style={{ color: '#2821E6' }}>Price Targets</label>
              </div>
              {_.map(fieldPriceTargets.input.value, (item, idx) => (
                <div className="col-md-12" key={idx}>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      <label className="text-sm">Date</label>
                      <input
                        type="date"
                        className="form-control rounded mb-2"
                        value={item?.date}
                        onChange={(e) => {
                          const { value = [], onChange } = fieldPriceTargets.input
                          onChange(value.map((c, index) => (index === idx ? {
                            ...c,
                            date: e.target.value
                          } : c)))
                        }}
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="text-sm">Analyst Firm</label>
                      <input
                        type="text"
                        className="form-control rounded mb-2"
                        value={item?.analystFirm}
                        placeholder="Analyst Firm"
                        onChange={(e) => {
                          const { value = [], onChange } = fieldPriceTargets.input
                          onChange(value.map((c, index) => (index === idx ? {
                            ...c,
                            analystFirm: e.target.value
                          } : c)))
                        }}
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="text-sm">Current Rating</label>
                      <input
                        type="text"
                        className="form-control rounded mb-2"
                        value={item?.currentRating}
                        placeholder="Current Rating"
                        onChange={(e) => {
                          const { value = [], onChange } = fieldPriceTargets.input
                          onChange(value.map((c, index) => (index === idx ? {
                            ...c,
                            currentRating: e.target.value
                          } : c)))
                        }}
                      />
                    </div>
                    <div className="col-md-3">
                      <label className="text-sm">Price Target</label>
                      <input
                        type="text"
                        className="form-control rounded mb-2"
                        value={item?.priceTarget}
                        placeholder="Price Target"
                        onChange={(e) => {
                          const { value = [], onChange } = fieldPriceTargets.input
                          onChange(value.map((c, index) => (index === idx ? {
                            ...c,
                            priceTarget: e.target.value
                          } : c)))
                        }}
                      />
                    </div>
                    <div className="col-md-12 mb-3">
                      <button
                        type="button"
                        className="btn-danger btn-square mt-1"
                        onClick={() =>
                          fieldPriceTargets.input.onChange(
                            fieldPriceTargets.input.value.filter((c, index) => index !== idx),
                          )
                        }
                      >
                        <i className="fa fa-trash"/>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
              <div className="col-md-12">
                <button
                  type="button"
                  className="btn btn-primary px-2 py-0 btn-sm"
                  onClick={() => fieldPriceTargets.input.onChange([...fieldPriceTargets.input.value, null])}
                >
                  + Add more
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-group row">
              <div className="col-md-12">
                <label style={{ color: '#2821E6' }}>Growth Rates</label>
              </div>
              {_.map(fieldGrowthRates.input.value, (item, idx) => (
                <div className="col-md-12" key={idx}>
                  <div className="row mt-2">
                    <div className="col-md-4">
                      <label className="text-sm">Date</label>
                      <input
                        type="date"
                        className="form-control rounded mb-2"
                        value={item?.date}
                        onChange={(e) => {
                          const { value = [], onChange } = fieldGrowthRates.input
                          onChange(value.map((c, index) => (index === idx ? {
                            ...c,
                            date: e.target.value
                          } : c)))
                        }}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="text-sm">Growth</label>
                      <input
                        type="number"
                        className="form-control rounded mb-2"
                        value={item?.growth}
                        placeholder="Growth"
                        onChange={(e) => {
                          const { value = [], onChange } = fieldGrowthRates.input
                          onChange(value.map((c, index) => (index === idx ? {
                            ...c,
                            growth: e.target.value
                          } : c)))
                        }}
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="text-sm">Type</label>
                      <select
                        className="form-control rounded mb-2"
                        defaultValue={item?.type}
                        onChange={(e) => {
                          const { value = [], onChange } = fieldGrowthRates.input
                          onChange(value.map((c, index) => (index === idx ? {
                            ...c,
                            type: e.target.value
                          } : c)))
                        }}
                      >
                        <option value="">Please select...</option>
                        <option value="sales">Sales</option>
                        <option value="net-income">Net Income</option>
                      </select>
                    </div>
                    <div className="col-md-12 mb-3">
                      <button
                        type="button"
                        className="btn-danger btn-square mt-1"
                        onClick={() =>
                          fieldGrowthRates.input.onChange(
                            fieldGrowthRates.input.value.filter((c, index) => index !== idx),
                          )
                        }
                      >
                        <i className="fa fa-trash"/>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
              <div className="col-md-12">
                <button
                  type="button"
                  className="btn btn-primary px-2 py-0 btn-sm"
                  onClick={() => fieldGrowthRates.input.onChange([...fieldGrowthRates.input.value, null])}
                >
                  + Add more
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group row">
              <div className="col-md-12">
                <label style={{ color: '#2821E6' }}>Financials</label>
              </div>
              {_.map(fieldFinancials.input.value, (item, idx) => (
                <div className="col-md-12" key={idx}>
                  <div className="row mt-2">
                    <div className="col-md-2">
                      <label className="text-sm">Filling Date</label>
                      <input
                        type="date"
                        className="form-control rounded mb-2"
                        value={item?.filingDate}
                        onChange={(e) => {
                          const { value = [], onChange } = fieldFinancials.input
                          onChange(value.map((c, index) => (index === idx ? {
                            ...c,
                            filingDate: e.target.value
                          } : c)))
                        }}
                      />
                    </div>
                    <div className="col-md-1">
                      <label className="text-sm">Sales</label>
                      <input
                        type="text"
                        className="form-control rounded mb-2"
                        value={item?.sales}
                        placeholder="Sales"
                        onChange={(e) => {
                          const { value = [], onChange } = fieldFinancials.input
                          onChange(value.map((c, index) => (index === idx ? {
                            ...c,
                            sales: e.target.value
                          } : c)))
                        }}
                      />
                    </div>
                    <div className="col-md-2">
                      <label className="text-sm">Net Income</label>
                      <input
                        type="text"
                        className="form-control rounded mb-2"
                        value={item?.netIncome}
                        placeholder="Net Income"
                        onChange={(e) => {
                          const { value = [], onChange } = fieldFinancials.input
                          onChange(value.map((c, index) => (index === idx ? {
                            ...c,
                            netIncome: e.target.value
                          } : c)))
                        }}
                      />
                    </div>
                    <div className="col-md-1">
                      <label className="text-sm">Cash on Hand</label>
                      <input
                        type="text"
                        className="form-control rounded mb-2"
                        value={item?.cashOnHand}
                        placeholder="Cash On Hand"
                        onChange={(e) => {
                          const { value = [], onChange } = fieldFinancials.input
                          onChange(value.map((c, index) => (index === idx ? {
                            ...c,
                            cashOnHand: e.target.value
                          } : c)))
                        }}
                      />
                    </div>
                    <div className="col-md-1">
                      <label className="text-sm">Type</label>
                      <select
                        className="form-control rounded mb-2"
                        defaultValue={item?.statementType}
                        onChange={(e) => {
                          const { value = [], onChange } = fieldFinancials.input
                          onChange(value.map((c, index) => (index === idx ? {
                            ...c,
                            statementType: e.target.value
                          } : c)))
                        }}
                      >
                        <option>Please select...</option>
                        <option value="annual">Annual</option>
                        <option value="quarterly">Quarterly</option>
                      </select>
                    </div>
                    <div className="col-md-1">
                      <label className="text-sm">Quarter</label>
                      <select
                        className="form-control rounded mb-2"
                        defaultValue={item?.quarter}
                        onChange={(e) => {
                          const { value = [], onChange } = fieldFinancials.input
                          onChange(value.map((c, index) => (index === idx ? {
                            ...c,
                            quarter: e.target.value
                          } : c)))
                        }}
                      >
                        <option value="">Please select...</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                      </select>
                    </div>
                    <div className="col-md-1">
                      <label className="text-sm">Debt</label>
                      <input
                        type="text"
                        className="form-control rounded mb-2"
                        value={item?.debt}
                        placeholder="Debt"
                        onChange={(e) => {
                          const { value = [], onChange } = fieldFinancials.input
                          onChange(value.map((c, index) => (index === idx ? {
                            ...c,
                            debt: e.target.value
                          } : c)))
                        }}
                      />
                    </div>
                    <div className="col-md-1">
                      <label className="text-sm">EPS</label>
                      <input
                        type="text"
                        className="form-control rounded mb-2"
                        value={item?.earningsPerShare}
                        placeholder="Earnings per Share"
                        onChange={(e) => {
                          const { value = [], onChange } = fieldFinancials.input
                          onChange(value.map((c, index) => (index === idx ? {
                            ...c,
                            earningsPerShare: e.target.value
                          } : c)))
                        }}
                      />
                    </div>
                    <div className="col-md-1">
                      <label className="text-sm">OP. cash flow</label>
                      <input
                        type="text"
                        className="form-control rounded mb-2"
                        value={item?.netOperatingCashFlow}
                        placeholder="Cash flow"
                        onChange={(e) => {
                          const { value = [], onChange } = fieldFinancials.input
                          onChange(value.map((c, index) => (index === idx ? {
                            ...c,
                            netOperatingCashFlow: e.target.value
                          } : c)))
                        }}
                      />
                    </div>
                    <div className="col-md-12 mb-3">
                      <button
                        type="button"
                        className="btn-danger btn-square mt-1"
                        onClick={() =>
                          fieldFinancials.input.onChange(
                            fieldFinancials.input.value.filter((c, index) => index !== idx),
                          )
                        }
                      >
                        <i className="fa fa-trash"/>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
              <div className="col-md-12">
                <button
                  type="button"
                  className="btn btn-primary px-2 py-0 btn-sm"
                  onClick={() => fieldFinancials.input.onChange([...fieldFinancials.input.value, null])}
                >
                  + Add more
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-12 mt-4">
            <div className="form-group">
              <button type="submit" className="btn btn-primary" disabled={pristine || submitting}>
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    )
  )
}

export default Form
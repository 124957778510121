import {useEffect, useState} from 'react'
import { symbolService, periodService, sectorStockService } from "../../services"


export function useSymbols(search) {
  const [symbols, setSymbols] = useState([])

  useEffect(() => {
    symbolService.getSymbols(search).then(({data: {stockSymbols: resp}}) => setSymbols(resp))
  }, [search])
  return symbols
}

export function usePeriods(search) {
  const [periods, setPeriods] = useState([])

  useEffect(() => {
    periodService.getPeriods(search).then(({data: {periods: resp}}) => setPeriods(resp))
  }, [search])
  return periods
}

export function useStocks(q) {
  const [stocks, setStocks] = useState([])

  useEffect(() => {
    sectorStockService.getStocks(q).then(({ data: { stocks: resp }}) => setStocks(resp))
  }, [q])

  return stocks
}

export function useSearchSectorStocks(q) {
  const [stocks, setStocks] = useState([])

  useEffect(() => {
    sectorStockService.searchSectorStocks(q).then(({ data: { stocks: resp }}) => setStocks(resp))
  }, [q])

  return stocks
}


export function useSectors() {
  const [sectors, setSectors] = useState([])

  useEffect(() => {
    sectorStockService.getSectors().then(({ data: { sectors: resp }}) => setSectors(resp))
  }, [])

  return sectors
}

export function useAdvisers() {
  const [advisers, setAdvisers] = useState([])

  useEffect(() => {
    sectorStockService.getAdvisers().then(({ data: { advisers: resp }}) => setAdvisers(resp))
  }, [])

  return advisers
}

export function useIndexes() {
  const [indexes, setIndexes] = useState([])

  useEffect(() => {
    sectorStockService.getIndexes().then(({ data: { indexes: resp }}) => setIndexes(resp))
  }, [])

  return indexes
}

export function useMarkets() {
  const [markets, setMarkets] = useState([])

  useEffect(() => {
    sectorStockService.getMarkets().then(({ data: { markets: resp }}) => setMarkets(resp))
  }, [])

  return markets
}
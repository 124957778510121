import React, { useEffect, useMemo, useState } from 'react'
import AirTable from '../ui/table'
import qs from 'querystring'
import { useRequest } from '../../services/api'
import { stocks } from '../../common/constants'
import Select from 'react-select'
import _ from 'lodash'

function OptionChainsPage() {
  const [expirationDate, setExpirationDate] = useState()
  const [symbol, setSymbol] = useState({ name: 'AAPL', value: 'AAPL' })
  const [page, setPage] = useState(1)

  const query = qs.stringify({
    symbol: symbol.value,
    expirationDate,
    page,
    pageSize: 500,
  })

  const { data, error } = useRequest(expirationDate !== "" && `/admin/fin-hub/option-chains?${query}`)
  const { data: optionsDates = [] } = useRequest('/admin/fin-hub/option-dates')

  useEffect(() => {
    if (optionsDates.length > 0) {
      setExpirationDate(optionsDates[0])
    }
  }, [optionsDates])

  const { puts = [], calls = [] } = data || {}

  const columns = useMemo(() => {
    return [
      {
        Header: 'Ticker',
        accessor: 'ticker',
      },
      {
        Header: 'Strike Price',
        accessor: 'strike_price',
      },
      {
        Header: 'Expiration Date',
        accessor: 'expiration_date',
      },
      {
        Header: 'Contract Type',
        accessor: 'contract_type',
      },
      {
        Header: 'Open Interest',
        accessor: 'open_interest',
      },
      {
        Header: 'Ask',
        accessor: 'ask',
      },
      {
        Header: 'Bid',
        accessor: 'bid',
      },
      {
        Header: 'Implied Volatility',
        accessor: 'impliedVolatility',
      },
      {
        Header: 'Todays Volume',
        accessor: 'todaysVolume',
      },
      {
        Header: 'Break Even Price',
        accessor: 'breakEvenPrice',
      },
      {
        Header: 'Last Day Volume',
        accessor: 'lastDayVolume',
      },
    ]
  }, [calls, puts])

  return (
    <div className="container-fluid p-3">
      <div className="row">
        <div className="col-md-6">
          <div className="flex justify-between items-center mb-3">
            <label className="font-bold text-sm">Select symbol</label>
            <Select
              options={stocks}
              defaultValue={stocks.filter((o) => o.name === symbol.name)[0]}
              onChange={(e) => setSymbol(e)}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.name}
              className="bg-snow text-sm rounded-lg font-poppins border"
              styles={{
                control: (provided) => ({
                  ...provided,
                  border: '0',
                  backgroundColor: 'white',
                  boxShadow: 'none',
                }),
                singleValue: (provided) => ({ ...provided, paddingLeft: '5px' }),
                menu: (provided) => ({
                  ...provided,
                  left: '0px',
                  zIndex: 99999999
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  padding: 0,
                }),
                indicatorSeparator: () => ({ display: 'none' }),
              }}
            />
          </div>
        </div>
        <div className="col-md-6">
          <label className="font-bold text-sm block">Day</label>
          <select
            className="w-100 rounded form-control"
            onChange={(e) => setExpirationDate(e.target.value)}
          >
            {optionsDates.map(date => (
              <option key={date}>{date}</option>
            ))}
          </select>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <h2>Calls</h2>
          <AirTable
            columns={columns}
            data={calls}
            loading={_.isEmpty(data)}
          />
        </div>
        <div className="col-md-6">
          <h2>Puts</h2>
          <AirTable
            columns={columns}
            data={puts}
            loading={_.isEmpty(data)}
          />
        </div>
      </div>
    </div>
  )
}

export default React.memo(OptionChainsPage)

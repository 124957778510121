import React, { useEffect, useMemo, useState } from 'react'
import AirTable from '../ui/table'
import 'react-toastify/dist/ReactToastify.css'
import { w3cwebsocket } from 'websocket'
import moment from "moment";
import { useRequest } from '../../services/api'

function LastPriceUpdates() {
  const [loading, setLoading] = useState(true)
  const [lastPriceUpdates, setLastPriceUpdates] = useState([])

  const { data, error } = useRequest(`/admin/trades`)

  const { trades = [] } = data || {}

  const launchWebsocket = () => {
    const client = new w3cwebsocket('wss://ws.finnhub.io?token=bv0r50n48v6u4eacbi10')

    client.onerror = (e) => {
      console.log('Connection Error');
      console.log(e)
    };

    client.onopen = () => {
      console.log('WebSocket Client Connected');
      client.send(JSON.stringify({ type: 'subscribe', symbol: 'AAPL' }))
    };

    client.onclose = (e) => {
      console.log('echo-protocol Client Closed');
      console.log(e)
    };

    client.onmessage = (event) => {
      const trade = JSON.parse(event.data).data[0]
      const mapped = {
        price: trade.p,
        symbol: trade.s,
        timestamp: trade.t,
        volume: trade.v,
      }
      setLastPriceUpdates([mapped])
      setLoading(false)
    }
  }

  useEffect(() => {
    // launchWebsocket()
  }, [])

  const columns = useMemo(() => {
    return [
      {
        Header: 'Symbol',
        accessor: 'symbol',
      },
      {
        Header: 'Price',
        accessor: 'price',
      },
      {
        Header: 'Volume',
        accessor: 'volume',
      },
      {
        Header: 'Timestamp',
        accessor: 'timestamp',
        Cell({ value }) {
          return moment(value).format('MMMM Do YYYY, hh:mm:ms')
        },
      },
    ]
  }, [])

  return (
    <div className="container-fluid p-3">
      <div className="flex justify-between items-center mb-6">
        <h2>Last Price Updates</h2>
      </div>
      <AirTable
        columns={columns}
        data={trades}
        loading={!data && !error}
      />
    </div>
  )
}

export default React.memo(LastPriceUpdates)


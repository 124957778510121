import React, { useRef, useState } from 'react'
import Calendar from 'rc-calendar'
import RcDatePicker from 'rc-calendar/lib/Picker'
import enUS from 'rc-calendar/lib/locale/en_US'
import 'rc-calendar/assets/index.css'

function DatePicker(
  {
    value,
    defaultCalendarValue,
    disabledDate = () => false,
    disabled,
    dateInputPlaceholder = 'Please select date',
    onOpenChange,
    onChange,
    timeFormat = 'DD/MM/YYYY',
    className,
    onSelect,
    ...props
  },
) {
  const containerRef = useRef(null)
  const [open, setOpen] = useState(false)
  const calendar = (
    <Calendar
      locale={enUS}
      style={{ zIndex: 1001 }}
      dateInputPlaceholder={dateInputPlaceholder}
      format={timeFormat}
      showToday={false}
      showDateInput={false}
      disabledDate={disabledDate}
      focusablePanel={false}
      onSelect={onSelect}
      readOnly
    />
  )

  return (
    <div
      className="w-100"
      css={`
        .rc-calendar-today .rc-calendar-date {
          border: 1px solid #ae8628;
          color: #ae8628;
        }

        .rc-calendar-selected-day .rc-calendar-date {
          background: #cb971d14;
          color: #ae8628;
        }

        .rc-calendar-selected-date .rc-calendar-date {
          background: #ae8628;
          color: #fff;
        }

        .rc-calendar-date:hover {
          background: #cb971d14;
          color: #ae8628;
        }
      `}
    >
      <RcDatePicker
        animation="slide-up"
        calendar={calendar}
        getCalendarContainer={() => containerRef.current}
        onOpenChange={setOpen}
        open={open}
        style={{ zIndex: 1001 }}
        onChange={onChange}
        defaultValue={defaultCalendarValue}
        readOnly
      >
        {({ value }) => {
          return (
            <span tabIndex="0">
              <input
                placeholder="Select date"
                disabled={disabled}
                readOnly
                tabIndex="-1"
                className={className}
                value={(value && value.format(timeFormat)) || ''}
                css={`
                  border-radius: 8px;
                  background-color: #f8f9fa;
                  padding: 18px 21px;
                  width: 100%;
                  border: none;

                  @media (max-width: 991px) {
                    width: 100%;
                    margin-bottom: 20px;
                  }
                `}
              />
              <div ref={containerRef} />
            </span>
          )
        }}
      </RcDatePicker>
    </div>
  )
}

export default React.memo(DatePicker)

import constants from "../../common/constants";
import { authService } from "../../services";
import Cookies from "js-cookie";

export const LOGIN_STARTED = "LOGIN_STARTED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_ERROR";

export const loginStarted = () => {
  return {
    type: LOGIN_STARTED,
  };
};

export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    user,
  };
};

export const loginError = (payload) => {
  return {
    type: LOGIN_FAILED,
    errorMessage: payload.errorMessage,
  };
};

export const onStoreUser = (user) => {
  return (dispatch) => {
    // Temporarily keep localStorage for user data
    localStorage.setItem(constants.auth.userName, JSON.stringify(user));
    dispatch(loginSuccess(user));
  };
};

export const onLogin = (data) => {
  return (dispatch) => {
    dispatch(loginStarted());

    authService
      .login2(data)
      .then((res) => {
        const { accessToken, refreshToken } = res.data;

        // Temporarily keep localStorage for user data
        localStorage.setItem(constants.auth.userName, JSON.stringify(res.data));

        // Store tokens in cookies
        Cookies.set("accessToken", accessToken);
        Cookies.set("refreshToken", refreshToken); 

        dispatch(loginSuccess(res.data));

        setTimeout(() => {
          window.location.href = "/";
        }, 200);
      })
      .catch((err) => {
        if (!err.response || !err.response.data) {
          dispatch(loginError({ errorMessage: "something went wrong" }));
        } else {
          dispatch(loginError({ errorMessage: err.response.data.message }));
        }
      });
  };
};

import React, {useMemo, useState} from 'react'
import {useRequest} from '../../services/api'
import AirTable from '../ui/table'
import moment from "moment"
import AutoComplete from "../ui/symbol-dropdown"
import qs from 'querystring'

function StockDividends() {
  const [search, setSearch] = useState('')
  const query = qs.stringify({
    symbol: search,
  })
  const onInputChange = (e) => {
    setSearch(e.value)
  }
  const {data, error} = useRequest(`/admin/fin-hub/stock-dividends?${query}`)
  const [requesting, setRequesting] = useState(false)

  const columns = useMemo(() => {
    return [
      {
        Header: 'Symbol',
        accessor: 'symbol',
      },
      {
        Header: 'Amount',
        accessor: 'amount',
      },
      {
        Header: 'Adjusted Amount',
        accessor: 'adjustedAmount',
      },
      {
        Header: 'Currency',
        accessor: 'currency',
      },
      {
        Header: 'Date',
        accessor: 'date',
        Cell({ value }) {
          return moment(value).format('MMMM Do YYYY, h:mm')
        },
      },
      {
        Header: 'Declaration Date',
        accessor: 'declarationDate',
        Cell({ value }) {
          return moment(value).format('MMMM Do YYYY, h:mm')
        },
      },
      {
        Header: 'Pay Date',
        accessor: 'payDate',
        Cell({ value }) {
          return moment(value).format('MMMM Do YYYY, h:mm')
        },
      },
      {
        Header: 'RecordDate',
        accessor: 'recordDate',
        Cell({ value }) {
          return moment(value).format('MMMM Do YYYY, h:mm')
        },
      },
    ]
  }, [requesting])

  return (
    <div className="container-fluid p-3">
      <div className="d-flex justify-content-between align-items-center mb-6">
        <h2>Stock Dividends</h2>
        <div style={{width: '320px'}}>
          <AutoComplete onChange={onInputChange}/>
        </div>
      </div>
      <AirTable
        columns={columns}
        data={data}
        loading={!data && !error}
      />
    </div>
  )
}

export default React.memo(StockDividends)


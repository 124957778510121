import AuthHandler from './AuthHandler';

class SymbolService {
  constructor(baseUrl) {
    if (!baseUrl) {
      throw new Error('missing baseUrl parameter');
    }

    this.baseUrl = baseUrl;
    this.authHandler = new AuthHandler(baseUrl); // Initialize AuthHandler
    this.axiosInstance = this.authHandler.axiosInstance; // Use axiosInstance from AuthHandler
  }

  async getSymbols(search = "") {
    try {
      return await this.authHandler.requestWithTokenRefresh(() =>
        this.axiosInstance.get(`${this.baseUrl}/admin/stock-symbols?page=1&pageSize=50&q=${search}`)
      );
    } catch (error) {
      console.error("Error fetching symbols:", error);
      throw error;
    }
  }
}

export default SymbolService;

import React from 'react'
import { useField, useForm } from 'react-final-form-hooks'
import { composeValidators, emailValidation, required } from '../../helpers/hooks/validators'

function CreateAdministrator({data = {}, onSave = () => null}) {
  const {form, handleSubmit, pristine, submitting} = useForm({
    async onSubmit(values, formAPI) {
      try {
        console.log(values)
        await onSave(
          {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            password: values.password
          },
          formAPI,
        )
      } catch (err) {
        console.error(err)
      }
    },
    validate(values) {
      const {password, confirmPassword} = values
      const errors = {}
      if (!password && !data) {
        errors.password = 'Password is required'
      }
      if (password && password !== confirmPassword) {
        errors.confirmPassword = 'Confirm Password miss match'
      }
      return errors
    },
    initialValues: {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
    },
  })

  const fieldFirstName = useField('firstName', form, required)
  const fieldLastName = useField('lastName', form, required)
  const fieldEmail = useField('email', form, composeValidators(required, emailValidation))
  const fieldPwd = useField('password', form)
  const fieldConfirmPwd = useField('confirmPassword', form)

  return (
    <form className="bg-white rounded p-3" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <label>First Name</label>
            <input {...fieldFirstName.input} placeholder="First Name" className="form-control rounded" />
            {fieldFirstName.meta.touched && fieldFirstName.meta.error && (
              <span className="text-danger text-sm">{fieldFirstName.meta.error}</span>
            )}
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-group">
            <label>Last Name</label>
            <input {...fieldLastName.input} placeholder="Last Name" className="form-control rounded" />
            {fieldLastName.meta.touched && fieldLastName.meta.error && (
              <span className="text-danger text-sm">{fieldLastName.meta.error}</span>
            )}
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-group">
            <label>Email</label>
            <input {...fieldEmail.input} placeholder="Email" className="form-control rounded" />
            {fieldEmail.meta.touched && fieldEmail.meta.error && (
              <span className="text-danger text-sm">{fieldEmail.meta.error}</span>
            )}
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label>Password</label>
            <input {...fieldPwd.input} type="password" placeholder="Password" className="form-control rounded" />
            {fieldPwd.meta.touched && fieldPwd.meta.error && (
              <span className="text-danger text-sm">{fieldPwd.meta.error}</span>
            )}
          </div>
        </div>
        <div className="col-md-12">
          <label>Confirm Password</label>
          <input
            {...fieldConfirmPwd.input}
            type="password"
            placeholder="Confirm password"
            className="form-control rounded"
          />
          {fieldConfirmPwd.meta.touched && fieldConfirmPwd.meta.error && (
            <span className="text-danger text-sm">{fieldConfirmPwd.meta.error}</span>
          )}
        </div>
        <div className="col-md-12 mt-4">
          <div className="form-group">
            <button type="submit" className="btn btn-primary" disabled={pristine || submitting}>
              Save
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default CreateAdministrator
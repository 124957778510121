import React, { useEffect, useMemo, useState } from 'react'
import AirTable from '../ui/table'
import axios from 'axios'
import moment from 'moment'
import qs from 'querystring'
import { capitalCase } from 'change-case'

function AnalyticsBigQueryListPage() {
  const [page, setPage] = useState(1)
  const [data, setData] = useState([])

  const query = qs.stringify({
    page,
    pageSize: 500,
  })

  useEffect(() => {
    axios.get(`https://cltr.empoder.com/api/empoder/bq?${query}`).then((res) => setData(res.data))
  }, [page])

  const { events = [], totalPages, totalItems } = data || {}

  const columns = useMemo(() => {
    const cols = []

    if (events.length === 0) {
      return cols
    }
    const single = events[0]
    console.log(single)
    for (const col of Object.keys(single)) {
      if (col === 'url') {
        cols.push({
          Header: capitalCase(col),
          accessor: col,
          Cell({ value }) {
            return <div style={{ width: '229px', wordBreak: 'break-all' }}>{value}</div>
          }
        })
      } else {
        cols.push({
          Header: capitalCase(col),
          accessor: col,
        })
      }
    }

    return cols
  }, [events])

  return (
    <div className="container-fluid p-3">
      <div className="flex justify-between items-center mb-6">
        <h2>Analytics Big Query</h2>
      </div>
      <AirTable
        columns={columns}
        data={events}
        loading={events.length <= 0}
        totalItem={totalItems}
        totalPage={totalPages}
        onChangePageIndex={setPage}
        pagging
      />
    </div>
  )
}

export default React.memo(AnalyticsBigQueryListPage)

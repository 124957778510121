import React, { useState } from 'react'
import { settingsService, tradierAccountsService } from '../../services'
import { toast, ToastContainer } from 'react-toastify'

import { useRequest } from '../../services/api'

const SettingsPage = () => {
  const { data, revalidate } = useRequest('/admin/settings/status')
  const [loading, setIsLoading] = useState(false)

  const onSetInstanceStatus = async (status) => {
    try {
      setIsLoading(true)
      await settingsService.setInstanceStatus({ status })

      await revalidate()
      toast.success('Done')
    } catch (err) {
      console.log(err)
      toast.error(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  const onDisableAll = async () => {
    try {
      setIsLoading(true)

      await tradierAccountsService.disableAll()
      toast.success('Done')

    } catch (err) {
      console.log('err', err)
      toast.error(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  const onEnableAll = async () => {
    try {
      setIsLoading(true)

      await tradierAccountsService.enableAll()
      toast.success('Done')
    } catch (err) {
      console.log(err)
      toast.error(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  const forceCloseAllTrades = async () => {
    try {
      setIsLoading(true)

      await settingsService.forceCloseAllTrades()
      toast.success('Done')
    } catch (err) {
      console.log(err)
      toast.error(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="container-fluid p-3">
      <ToastContainer />
      <div className="d-flex justify-content-between align-items-center mb-6">
        <h3>Settings</h3>
      </div>
      <div className="bg-white p-3">
        <div className="row mt-3 mx-1">
          <button
            onClick={() => onSetInstanceStatus(data?.status === 'stopped' ? 'running' : 'stopped')}
            className="btn btn-primary mr-4"
            disabled={loading}
          >
            {data?.status === 'stopped' ? 'Enable scanner' : 'Disable Scanner'}
          </button>
        </div>
      </div>

      <div className="bg-white p-3">
        <div className="row mt-3 mx-1">
          <button
            onClick={() => onDisableAll()}
            className="btn btn-primary mr-4"
            disabled={loading}
          >
            De-active all tradier accounts
          </button>
        </div>
      </div>

      <div className="bg-white p-3">
        <div className="row mt-3 mx-1">
          <button
            onClick={() => onEnableAll()}
            className="btn btn-primary mr-4"
            disabled={loading}
          >
            Active all tradier accounts
          </button>
        </div>
      </div>

      <div className="bg-white p-3">
        <div className="row mt-3 mx-1">
          <button
            onClick={() => forceCloseAllTrades()}
            className="btn btn-primary mr-4"
            disabled={loading}
          >
            Force close all LIVE trades
          </button>
        </div>
      </div>
    </div>
  )
}

export default SettingsPage

import React, { useMemo, useState } from 'react'
import { useRequest } from '../../services/api'
import AirTable from '../ui/table'
import moment from 'moment'
import qs from "querystring";
import AutoComplete from "../ui/symbol-dropdown";

function UpgradeDowngrade() {
  const [search, setSearch] = useState('')
  const query = qs.stringify({
    symbol: search,
  })
  const { data, error } = useRequest(`/admin/fin-hub/upgrade-downgrade?${query}`)
  const [requesting, setRequesting] = useState(false)

  const { upgradeDowngrade = [] } = data || {}

  const columns = useMemo(() => {
    return [
      {
        Header: 'Symbol',
        accessor: 'symbol',
      },
      {
        Header: 'Company',
        accessor: 'company',
      },
      {
        Header: 'Action',
        accessor: 'action',
      },
      {
        Header: 'From Grade',
        accessor: 'fromGrade',
      },
      {
        Header: 'To Grade',
        accessor: 'toGrade',
      },
      {
        Header: 'Grade Time',
        accessor: 'gradeTime',
        Cell({ value }) {
          return moment(value).format('MMMM Do YYYY')
        },
      },
    ]
  }, [requesting])

  const onInputChange = (e) => {
    setSearch(e.value)
  }

  return (
    <div className="container-fluid p-3">
      <div className="d-flex justify-content-between align-items-center mb-6">
        <h2>Upgrade Downgrade</h2>
        <div style={{width: '320px'}}>
          <AutoComplete onChange={onInputChange}/>
        </div>
      </div>
      <AirTable
        columns={columns}
        data={upgradeDowngrade}
        loading={!data && !error}
      />
    </div>
  )
}

export default React.memo(UpgradeDowngrade)

import React, {useMemo, useState} from 'react'
import {useRequest} from '../../services/api'
import AirTable from '../ui/table'
import qs from 'querystring'
import AutoComplete from '../ui/symbol-dropdown'

function BarchartOptions() {
  const [search, setSearch] = useState('')
  const query = qs.stringify({
    symbol: search,
  })
  const onInputChange = (e) => {
    setSearch(e.value)
  }
  const {data, error} = useRequest(`/admin/barchart/options?${query}`)
  const [requesting, setRequesting] = useState(false)

  const {results = []} = data || {}

  const columns = useMemo(() => {
    return [
      {
        Header: 'Underlying Symbol',
        accessor: 'underlying_symbol',
      },
      {
        Header: 'Symbol',
        accessor: 'symbol',
      },
      {
        Header: 'Ask',
        accessor: 'ask',
      },
      {
        Header: 'Ask Date',
        accessor: 'askDate',
      },
      {
        Header: 'Ask Size',
        accessor: 'askSize',
      },
      {
        Header: 'Bid',
        accessor: 'bid',
      },
      {
        Header: 'Bid Date',
        accessor: 'bidDate',
      },
      {
        Header: 'Bid Size',
        accessor: 'bidSize',
      },
      {
        Header: 'Change',
        accessor: 'change',
      },
      {
        Header: 'Delta',
        accessor: 'delta',
      },
      {
        Header: 'Exchange',
        accessor: 'exchange',
      },
      {
        Header: 'Gamma',
        accessor: 'gamma',
      },
      {
        Header: 'High',
        accessor: 'high',
      },
      {
        Header: 'Last',
        accessor: 'last',
      },
      {
        Header: 'Last Trade Date',
        accessor: 'lastTradeDate',
      },
      {
        Header: 'Low',
        accessor: 'low',
      },
      {
        Header: 'Open',
        accessor: 'open',
      },
      {
        Header: 'Open Interest',
        accessor: 'openInterest',
      },
      {
        Header: 'Percent Change',
        accessor: 'percentChange',
      },
      {
        Header: 'Premium',
        accessor: 'premium',
      },
      {
        Header: 'Previous',
        accessor: 'previous',
      },
      {
        Header: 'RHO',
        accessor: 'rho',
      },
      {
        Header: 'Settlement',
        accessor: 'settlement',
      },
      {
        Header: 'Strike',
        accessor: 'strike',
      },
      {
        Header: 'Theta',
        accessor: 'theta',
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Vega',
        accessor: 'vega',
      },
      {
        Header: 'Volatility',
        accessor: 'volatility',
      },
      {
        Header: 'Expiration Type',
        accessor: 'expirationType',
      },
      {
        Header: 'Expiration Date',
        accessor: 'expirationDate',
      },
      {
        Header: 'Date',
        accessor: 'date',
      },
    ]
  }, [requesting])

  return (
    <div className="container-fluid p-3">
      <div className="d-flex justify-content-between align-items-center mb-6">
        <h2>Barchart options</h2>
        <div style={{width: '320px'}}>
          <AutoComplete onChange={onInputChange}/>
        </div>
      </div>
      <AirTable
        columns={columns}
        data={results || []}
        loading={!data && !error}
      />
    </div>
  )
}

export default React.memo(BarchartOptions)

import React, { useMemo, useState } from 'react'
import { useRequest } from '../../services/api'
import AirTable from "../ui/table"
import { ToastContainer } from 'react-toastify'
import qs from 'querystring'
import moment from 'moment'

function UsersListPage() {
  const [page, setPage] = useState(1)
  const query = qs.stringify({
    page,
    pageSize: 50,
  })

  const { data, error, revalidate } = useRequest(`/admin/users?${query}`)
  const [requesting, setRequesting] = useState(false)

  const { users = [], totalItems, totalPages } = data || {}

  console.log(users)

  const columns = useMemo(() => {
    return [
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Unique ID',
        accessor: 'uniqueId',
      },
      {
        Header: 'Referred By',
        accessor: 'referred_by',
      },
      {
        Header: 'Referrals',
        accessor: 'referrals',
      },
      {
        Header: 'Created At',
        accessor: 'created_at',
        Cell({ value }) {
          return moment(value).format('MMMM Do YYYY, h:mm')
        },
      }
    ]
  }, [requesting])

  return (
    <div className="p-3">
      <ToastContainer/>
      <div className="row">
        <div className="col-md-12 pb-3">
          <div className="d-flex justify-content-between align-items-center">
            <h3>Users</h3>
          </div>
        </div>
        <div className="col-md-12">
          <AirTable
            columns={columns}
            data={users}
            totalItem={totalItems}
            totalPage={totalPages}
            onChangePageIndex={setPage}
            pagging
          />
        </div>
      </div>
    </div>
  )
}

export default React.memo(UsersListPage)

import React from 'react'

const alert = props => {
  const cssClass = `alert ${props.cssClass} alert-dismissible`

  return (
    <div className="box-body">
      <div className={cssClass}>
        <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
        {props.message}
      </div>
    </div>
  )
}

export default alert

import React, { useMemo } from 'react'
import { useRequest } from '../../services/api'
import AirTable from "../ui/table"
import moment from 'moment'

function IncomeStatementDifferences() {
  const { data, error } = useRequest(`admin/differences/income-statements`)

  const columns = useMemo(() => {
    return [
      {
        Header: 'Symbol',
        accessor: 'symbol',
      },
      {
        Header: 'BZ Freq',
        accessor: 'bz_freq',
      },
      {
        Header: 'MS Freq',
        accessor: 'ms_freq',
      },
      {
        Header: 'BZ Revenue',
        accessor: 'bz_revenue',
      },
      {
        Header: 'MS Revenue',
        accessor: 'ms_revenue',
      },
      {
        Header: 'BZ operating income',
        accessor: 'bz_operating_income',
      },
      {
        Header: 'MS operating income',
        accessor: 'ms_operating_income',
      },
      {
        Header: 'BZ Net Income',
        accessor: 'bz_net_income',
      },
      {
        Header: 'MS Net Income',
        accessor: 'ms_net_income',
      },
      {
        Header: 'BZ Period',
        accessor: 'bz_period',
        Cell({value}) {
          return moment(value).format('MM-DD-YYYY HH:mm')
        }
      },
      {
        Header: 'MS Period',
        accessor: 'ms_period',
        Cell({value}) {
          return moment(value).format('MM-DD-YYYY HH:mm')
        }
      },
      {
        Header: 'Differences',
        accessor: 'differences',
        Cell({ value }) {
          return value.join(', ')
        }
      },
    ]
  }, [])

  return (
    <div className="container-fluid p-3">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h2>Income Statements Differences</h2>
      </div>
      <AirTable
        columns={columns}
        data={data}
        loading={!data && !error}
      />
    </div>
  )
}

export default React.memo(IncomeStatementDifferences)

import React, {useMemo, useState} from 'react'
import { useRequest } from '../../services/api'
import AirTable from '../ui/table'
import moment from 'moment'
import qs from 'querystring'
import AutoComplete from '../ui/symbol-dropdown'

function BasicFinancials() {
  const [search, setSearch] = useState('AAPL')
  const query = qs.stringify({
    symbol: search,
  })
  const onInputChange = (e) => {
    setSearch(e.value)
  }
  const { data, error } = useRequest(`/admin/fin-hub/stock-peers/basic-financials?${query}`)

  const basicFinancials = data || []

  const columns = useMemo(() => {
    return [
      {
        Header: 'Symbol',
        accessor: 'symbol',
      },
      {
        Header: 'Metric Type',
        accessor: 'metricType',
      },
      {
        Header: '3 Month Average Trading Volume',
        accessor: 'metric.3MonthAverageTradingVolume',
      },
      {
        Header: '5 Day Price Return Daily',
        accessor: 'metric.5DayPriceReturnDaily',
      },
      {
        Header: '10 Day Average Trading Volume',
        accessor: 'metric.10DayAverageTradingVolume',
      },
      {
        Header: '13 Week Price Return Daily',
        accessor: 'metric.13WeekPriceReturnDaily',
      },
      {
        Header: '26 Week Price Return Daily',
        accessor: 'metric.26WeekPriceReturnDaily',
      },
      {
        Header: '52 Week High',
        accessor: 'metric.52WeekHigh',
      },
      {
        Header: '52 Week High Date',
        accessor: 'metric.52WeekHighDate',
        Cell({ value }) {
          return moment(value).format('MMMM Do YYYY')
        },
      },
      {
        Header: '52 Week Low',
        accessor: 'metric.52WeekLow',
      },
      {
        Header: '52 Week Low Date',
        accessor: 'metric.52WeekLowDate',
        Cell({ value }) {
          return moment(value).format('MMMM Do YYYY')
        },
      },
      {
        Header: '52 Week Price Return Daily',
        accessor: 'metric.52WeekPriceReturnDaily',
      },
      {
        Header: 'Asset Turnover Annual',
        accessor: 'metric.assetTurnoverAnnual',
      },
      {
        Header: 'Asset Turnover TTM',
        accessor: 'metric.assetTurnoverTTM',
      },
      {
        Header: 'Beta',
        accessor: 'metric.beta',
      },
      {
        Header: 'Book Value Per Share Annual',
        accessor: 'metric.bookValuePerShareAnnual',
      },
      {
        Header: 'Book Value Per Share Quarterly',
        accessor: 'metric.bookValuePerShareQuarterly',
      },
      {
        Header: 'Book Value Share Growth 5Y',
        accessor: 'metric.bookValueShareGrowth5Y',
      },
      {
        Header: 'Capital Spending Growth 5Y',
        accessor: 'metric.capitalSpendingGrowth5Y',
      },
      {
        Header: 'Cash Flow Per Share Annual',
        accessor: 'metric.cashFlowPerShareAnnual',
      },
      {
        Header: 'Cash Flow Per Share TTM',
        accessor: 'metric.cashFlowPerShareTTM',
      },
      {
        Header: 'Cash Per Share Per Share Annual',
        accessor: 'metric.cashPerSharePerShareAnnual',
      },
      {
        Header: 'Cash Per Share Per Share Quarterly',
        accessor: 'metric.cashPerSharePerShareQuarterly',
      },
      {
        Header: 'Current Dividend Yield TTM',
        accessor: 'metric.currentDividendYieldTTM',
      },
      {
        Header: 'CurrentEv / Free Cash Flow Annual',
        accessor: 'metric.currentEv/freeCashFlowAnnual',
      },
      {
        Header: 'CurrentEv / Free Cash Flow TTM',
        accessor: 'metric.currentEv/freeCashFlowTTM',
      },
      {
        Header: 'Current Ratio Annual',
        accessor: 'metric.currentRatioAnnual',
      },
      {
        Header: 'Current Ratio Quarterly',
        accessor: 'metric.currentRatioQuarterly',
      },
      {
        Header: 'Dividend Growth Rate 5Y',
        accessor: 'metric.dividendGrowthRate5Y',
      },
      {
        Header: 'Dividend Per Share 5Y',
        accessor: 'metric.dividendPerShare5Y',
      },
      {
        Header: 'Dividend Per Share Annual',
        accessor: 'metric.dividendPerShareAnnual',
      },
      {
        Header: 'Dividend Yield 5Y',
        accessor: 'metric.dividendYield5Y',
      },
      {
        Header: 'Dividend Yield Indicated Annual',
        accessor: 'metric.dividendYieldIndicatedAnnual',
      },
      {
        Header: 'Dividends Per Share TTM',
        accessor: 'metric.dividendsPerShareTTM',
      },
      {
        Header: 'Ebitd Per Share TTM',
        accessor: 'metric.ebitdPerShareTTM',
      },
      {
        Header: 'Ebitda Cagr 5Y',
        accessor: 'metric.ebitdaCagr5Y',
      },
      {
        Header: 'Ebitda Interim Cagr 5Y',
        accessor: 'metric.ebitdaInterimCagr5Y',
      },
      {
        Header: 'Eps Basic Excl Extra Items Annual',
        accessor: 'metric.epsBasicExclExtraItemsAnnual',
      },
      {
        Header: 'Eps Basic Excl Extra Items TTM',
        accessor: 'metric.epsBasicExclExtraItemsTTM',
      },
      {
        Header: 'Eps Excl Extra Items Annual',
        accessor: 'metric.epsExclExtraItemsAnnual',
      },
      {
        Header: 'Eps Excl Extra Items TTM',
        accessor: 'metric.epsExclExtraItemsTTM',
      },
      {
        Header: 'Eps Growth 3Y',
        accessor: 'metric.epsGrowth3Y',
      },
      {
        Header: 'Eps Growth 5Y',
        accessor: 'metric.epsGrowth5Y',
      },
      {
        Header: 'Eps Growth Quarterly Yoy',
        accessor: 'metric.epsGrowthQuarterlyYoy',
      },
      {
        Header: 'Eps Growth TTM Yoy',
        accessor: 'metric.epsGrowthTTMYoy',
      },
      {
        Header: 'Eps Incl Extra Items Annual',
        accessor: 'metric.epsInclExtraItemsAnnual',
      },
      {
        Header: 'Eps Incl Extra Items TTM',
        accessor: 'metric.epsInclExtraItemsTTM',
      },
      {
        Header: 'Eps Normalized Annual',
        accessor: 'metric.epsNormalizedAnnual',
      },
      {
        Header: 'Focf Cagr 5Y',
        accessor: 'metric.focfCagr5Y',
      },
      {
        Header: 'Free Cash Flow Annual',
        accessor: 'metric.freeCashFlowAnnual',
      },
      {
        Header: 'Free Cash Flow Per Share TTM',
        accessor: 'metric.freeCashFlowPerShareTTM',
      },
      {
        Header: 'Free Cash Flow TTM',
        accessor: 'metric.freeCashFlowTTM',
      },
      {
        Header: 'Free Operating Cash Flow / Revenue 5Y',
        accessor: 'metric.freeOperatingCashFlow/revenue5Y',
      },
      {
        Header: 'Free Operating Cash Flow / Revenue TTM',
        accessor: 'metric.freeOperatingCashFlow/revenueTTM',
      },
      {
        Header: 'Gross Margin 5Y',
        accessor: 'metric.grossMargin5Y',
      },
      {
        Header: 'Gross Margin Annual',
        accessor: 'metric.grossMarginAnnual',
      },
      {
        Header: 'Gross Margin TTM',
        accessor: 'metric.grossMarginTTM',
      },
      {
        Header: 'Inventory Turnover Annual',
        accessor: 'metric.inventoryTurnoverAnnual',
      },
      {
        Header: 'Inventory Turnover TTM',
        accessor: 'metric.inventoryTurnoverTTM',
      },
      {
        Header: 'Long Term Debt / Equity Annual',
        accessor: 'metric.longTermDebt/equityAnnual',
      },
      {
        Header: 'Long Term Debt / Equity Quarterly',
        accessor: 'metric.longTermDebt/equityQuarterly',
      },
      {
        Header: 'Market Capitalization',
        accessor: 'metric.marketCapitalization',
      },
      {
        Header: 'Month To Date Price Return Daily',
        accessor: 'metric.monthToDatePriceReturnDaily',
      },
      {
        Header: 'Net Debt Annual',
        accessor: 'metric.netDebtAnnual',
      },
      {
        Header: 'Net Debt Interim',
        accessor: 'metric.netDebtInterim',
      },
      {
        Header: 'Net Income Employee Annual',
        accessor: 'metric.netIncomeEmployeeAnnual',
      },
      {
        Header: 'Net Income Employee TTM',
        accessor: 'metric.netIncomeEmployeeTTM',
      },
      {
        Header: 'Net Interest Coverage Annual',
        accessor: 'metric.netInterestCoverageAnnual',
      },
      {
        Header: 'Net Interest Coverage TTM',
        accessor: 'metric.netInterestCoverageTTM',
      },
      {
        Header: 'Net Margin Growth 5Y',
        accessor: 'metric.netMarginGrowth5Y',
      },
      {
        Header: 'Net Profit Margin 5Y',
        accessor: 'metric.netProfitMargin5Y',
      },
      {
        Header: 'Net Profit Margin Annual',
        accessor: 'metric.netProfitMarginAnnual',
      },
      {
        Header: 'Net Profit Margin TTM',
        accessor: 'metric.netProfitMarginTTM',
      },
      {
        Header: 'Operating Margin 5Y',
        accessor: 'metric.operatingMargin5Y',
      },
      {
        Header: 'Operating Margin Annual',
        accessor: 'metric.operatingMarginAnnual',
      },
      {
        Header: 'Operating Margin TTM',
        accessor: 'metric.operatingMarginTTM',
      },
      {
        Header: 'Payout Ratio Annual',
        accessor: 'metric.payoutRatioAnnual',
      },
      {
        Header: 'Payout Ratio TTM',
        accessor: 'metric.payoutRatioTTM',
      },
      {
        Header: 'Pb Annual',
        accessor: 'metric.pbAnnual',
      },
      {
        Header: 'Pb Quarterly',
        accessor: 'metric.pbQuarterly',
      },
      {
        Header: 'Pcf Share TTM',
        accessor: 'metric.pcfShareTTM',
      },
      {
        Header: 'Pe Basic Excl Extra TTM',
        accessor: 'metric.peBasicExclExtraTTM',
      },
      {
        Header: 'Pe Excl Extra Annual',
        accessor: 'metric.peExclExtraAnnual',
      },
      {
        Header: 'Pe Excl Extra High TTM',
        accessor: 'metric.peExclExtraHighTTM',
      },
      {
        Header: 'Pe Excl Extra TTM',
        accessor: 'metric.peExclExtraTTM',
      },
      {
        Header: 'Pe Excl Low TTM',
        accessor: 'metric.peExclLowTTM',
      },
      {
        Header: 'Pe Incl Extra TTM',
        accessor: 'metric.peInclExtraTTM',
      },
      {
        Header: 'Pe Normalized Annual',
        accessor: 'metric.peNormalizedAnnual',
      },
      {
        Header: 'Pfcf Share Annual',
        accessor: 'metric.pfcfShareAnnual',
      },
      {
        Header: 'Pfcf Share TTM',
        accessor: 'metric.pfcfShareTTM',
      },
      {
        Header: 'Pretax Margin 5Y',
        accessor: 'metric.pretaxMargin5Y',
      },
      {
        Header: 'Pretax Margin Annual',
        accessor: 'metric.pretaxMarginAnnual',
      },
      {
        Header: 'Pretax Margin TTM',
        accessor: 'metric.pretaxMarginTTM',
      },
      {
        Header: 'Price Relative To S&P500 Ytd',
        accessor: 'metric.priceRelativeToS&P500Ytd',
      },
      {
        Header: 'Price Relative To S&P5004 Week',
        accessor: 'metric.priceRelativeToS&P5004Week',
      },
      {
        Header: 'Price Relative To S&P50013 Week',
        accessor: 'metric.priceRelativeToS&P50013Week',
      },
      {
        Header: 'Price Relative To S&P50026 Week',
        accessor: 'metric.priceRelativeToS&P50026Week',
      },
      {
        Header: 'Price Relative To S&P50052 Week',
        accessor: 'metric.priceRelativeToS&P50052Week',
      },
      {
        Header: 'Ps Annual',
        accessor: 'metric.psAnnual',
      },
      {
        Header: 'Ps TTM',
        accessor: 'metric.psTTM',
      },
      {
        Header: 'Ptbv Annual',
        accessor: 'metric.ptbvAnnual',
      },
      {
        Header: 'Ptbv Quarterly',
        accessor: 'metric.ptbvQuarterly',
      },
      {
        Header: 'Quick Ratio Annual',
        accessor: 'metric.quickRatioAnnual',
      },
      {
        Header: 'Quick Ratio Quarterly',
        accessor: 'metric.quickRatioQuarterly',
      },
      {
        Header: 'Receivables Turnover Annual',
        accessor: 'metric.receivablesTurnoverAnnual',
      },
      {
        Header: 'Receivables Turnover TTM',
        accessor: 'metric.receivablesTurnoverTTM',
      },
      {
        Header: 'Revenue Employee Annual',
        accessor: 'metric.revenueEmployeeAnnual',
      },
      {
        Header: 'Revenue Employee TTM',
        accessor: 'metric.revenueEmployeeTTM',
      },
      {
        Header: 'Revenue Growth 3Y',
        accessor: 'metric.revenueGrowth3Y',
      },
      {
        Header: 'Revenue Growth 5Y',
        accessor: 'metric.revenueGrowth5Y',
      },
      {
        Header: 'Revenue Growth Quarterly Yoy',
        accessor: 'metric.revenueGrowthQuarterlyYoy',
      },
      {
        Header: 'Revenue Growth TTM Yoy',
        accessor: 'metric.revenueGrowthTTMYoy',
      },
      {
        Header: 'Revenue Per Share Annual',
        accessor: 'metric.revenuePerShareAnnual',
      },
      {
        Header: 'Revenue Per Share TTM',
        accessor: 'metric.revenuePerShareTTM',
      },
      {
        Header: 'Revenue Share Growth 5Y',
        accessor: 'metric.revenueShareGrowth5Y',
      },
      {
        Header: 'Roa Rfy',
        accessor: 'metric.roaRfy',
      },
      {
        Header: 'Roaa 5Y',
        accessor: 'metric.roaa5Y',
      },
      {
        Header: 'Roae 5Y',
        accessor: 'metric.roae5Y',
      },
      {
        Header: 'Roae TTM',
        accessor: 'metric.roaeTTM',
      },
      {
        Header: 'Roe Rfy',
        accessor: 'metric.roeRfy',
      },
      {
        Header: 'Roe TTM',
        accessor: 'metric.roeTTM',
      },
      {
        Header: 'Roi 5Y',
        accessor: 'metric.roi5Y',
      },
      {
        Header: 'Roi Annual',
        accessor: 'metric.roiAnnual',
      },
      {
        Header: 'Roi TTM',
        accessor: 'metric.roiTTM',
      },
      {
        Header: 'Tangible Book Value Per Share Annual',
        accessor: 'metric.tangibleBookValuePerShareAnnual',
      },
      {
        Header: 'Tangible Book Value Per Share Quarterly',
        accessor: 'metric.tangibleBookValuePerShareQuarterly',
      },
      {
        Header: 'Tbv Cagr 5Y',
        accessor: 'metric.tbvCagr5Y',
      },
      {
        Header: 'Total Debt / Total Equity Annual',
        accessor: 'metric.totalDebt/totalEquityAnnual',
      },
      {
        Header: 'Total Debt / Total Equity Quarterly',
        accessor: 'metric.totalDebt/totalEquityQuarterly',
      },
      {
        Header: 'Total Debt Cagr 5Y',
        accessor: 'metric.totalDebtCagr5Y',
      },
      {
        Header: 'Year To Date Price Return Daily',
        accessor: 'metric.yearToDatePriceReturnDaily',
      },
    ]
  }, [])

  return (
    <div className="container-fluid p-3">
      <div className="d-flex justify-content-between align-items-center mb-6">
        <h2>Peer basic financials: {search}</h2>
        <div style={{width: '320px'}}>
          <AutoComplete onChange={onInputChange}/>
        </div>
      </div>
      <AirTable
        columns={columns}
        data={basicFinancials}
        loading={!data && !error}
      />
    </div>
  )
}

export default React.memo(BasicFinancials)


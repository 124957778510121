import React, { useMemo, useState } from 'react'
import { useRequest } from '../../services/api'
import moment from "moment"
import AirTable from "../ui/table"
import helpers from '../../services/helpers'
import qs from "querystring";
import AutoComplete from "../ui/symbol-dropdown";

function EarningsCalendar() {
  const [search, setSearch] = useState('')
  const query = qs.stringify({
    symbol: search,
  })
  const { data, error } = useRequest(`/admin/fin-hub/earnings-calendar?${query}`)
  const [requesting, setRequesting] = useState(false)

  const { earningsCalendar = [] } = data || {}

  const columns = useMemo(() => {
    return [
      {
        Header: 'Symbol',
        accessor: 'symbol',
      },
      {
        Header: 'Hour',
        accessor: 'hour',
      },
      {
        Header: 'Eps Actual',
        accessor: 'epsActual',
      },
      {
        Header: 'Eps Estimate',
        accessor: 'epsEstimate',
      },
      {
        Header: 'Revenue Actual',
        accessor: 'revenueActual',
        Cell: ({ value }) => {
          console.log(value)
          return helpers.formatCurrency(value)
        }
      },
      {
        Header: 'Revenue Estimate',
        accessor: 'revenueEstimate',
        Cell: ({ value }) => {
          return helpers.formatCurrency(value)
        }
      },
      {
        Header: 'Quarter',
        accessor: 'quarter',
      },
      {
        Header: 'Year',
        accessor: 'year',
      },
      {
        Header: 'Date',
        accessor: 'date',
        Cell({ value }) {
          return moment(value).format('MMMM Do YYYY')
        },
      },
    ]
  }, [requesting])

  const onInputChange = (e) => {
    setSearch(e.value)
  }

  return (
    <div className="container-fluid p-3">
      <div className="d-flex justify-content-between align-items-center mb-6">
        <h2>Earnings Calendar</h2>
        <div style={{width: '320px'}}>
          <AutoComplete onChange={onInputChange}/>
        </div>
      </div>
      <AirTable
        columns={columns}
        data={earningsCalendar}
        loading={!data && !error}
      />
    </div>
  )
}

export default React.memo(EarningsCalendar)

import React, { useState } from 'react'
import qs from 'querystring'
import { strategyService } from '../../services'
import { toast } from 'react-toastify'
import fileDownload from 'js-file-download'

const ExportsPage = () => {
  const [from, setFrom] = useState()
  const [to, setTo] = useState()
  const [env, setEnv] = useState('sandbox')

  const query = qs.stringify({
    env: env,
    from: from || '',
    to: to || ''
  })

  const onExportSymbols = async () => {
    if(from && to) {
      try {
        const response = await strategyService.exportSymbols(query)
        fileDownload(response.data, `symbols-from-${from}-to${to}-env-${env}.csv`)
      } catch (err) {
        console.log(err)
        toast.error(err.message)
      }
    }
  }

  const onExportStrategies = async () => {
    if(from && to) {
      try {
        const response = await strategyService.exportStrategies(query)
        fileDownload(response.data, `strategies-from-${from}-to${to}-env-${env}.csv`)
      } catch (err) {
        console.log(err)
        toast.error(err.message)
      }
    }
  }

  const onExportBestPerformingSymbols = async () => {
    if(from && to) {
      try {
        const response = await strategyService.exportBestPerformingSymbols(query)
        fileDownload(response.data, `best-performing-symbols-from-${from}-to${to}-env-${env}.csv`)
      } catch (err) {
        console.log(err)
        toast.error(err.message)
      }
    }
  }

  const onExportBestPerformingStrategies = async () => {
    if(from && to) {
      try {
        const response = await strategyService.exportBestPerformingStrategies(query)
        fileDownload(response.data, `best-performing-strategies-from-${from}-to${to}-env-${env}.csv`)
      } catch (err) {
        console.log(err)
        toast.error(err.message)
      }
    }
  }

  const onExportRawTrades = async () => {
    if(from && to) {
      try {
        const response = await strategyService.exportRawTrades(query)
        fileDownload(response.data, `raw-trades-${from}-to${to}-env-${env}.csv`)
      } catch (err) {
        console.log(err)
        toast.error(err.message)
      }
    }
  }


  const onExportPrices = async () => {
    if(from && to) {
      try {
        const response = await strategyService.exportPrices(query)
        fileDownload(response.data, `prices-from-${from}-to${to}-env-${env}.csv`)
      } catch (err) {
        console.log(err)
        toast.error(err.message)
      }
    }
  }

  return (
    <div className="container-fluid p-3">
      <div className="d-flex justify-content-between align-items-center mb-6">
        <h3>Exports</h3>
      </div>
      <div className="bg-white p-3">
        <div className="row">
          <div className="col-md-4">
            <label>From date</label>
            <input
              type="date"
              className="form-control rounded mb-2"
              onChange={(e) => {
                setFrom(e.target.value)
              }}
            />
          </div>
          <div className="col-md-4">
            <label>To date</label>
            <input
              type="date"
              className="form-control rounded mb-2"
              onChange={(e) => {
                setTo(e.target.value)
              }}
            />
          </div>
          <div className="col-md-4">
            <label>Select env</label>
            <select
              className="form-control rounded mb-2"
              value={env}
              onChange={(e) => {
                setEnv(e.target.value)
              }}
            >
              <option>Please select</option>
              <option value="sandbox">Sandbox</option>
              <option value="live">Live</option>
            </select>
          </div>
        </div>
        <div className="row mt-3 mx-1">
          <button
            onClick={() => onExportSymbols()}
            className="btn btn-primary mr-4"
            disabled={!from || !to}
          >
            Symbols
          </button>
          <button
            onClick={() => onExportStrategies()}
            className="btn btn-primary mr-4"
            disabled={!from || !to}
          >
            Strategies
          </button>
          <button
            onClick={() => onExportBestPerformingSymbols()}
            className="btn btn-primary mr-4"
            disabled={!from || !to}
          >
            Best performing symbols
          </button>
          <button
            onClick={() => onExportBestPerformingStrategies()}
            className="btn btn-primary mr-4"
            disabled={!from || !to}
          >
            Best performing strategies
          </button>
          <button
            onClick={() => onExportPrices() }
            className="btn btn-primary mr-4"
            disabled={!from || !to}
          >
            Prices
          </button>
          <button
            onClick={() => onExportRawTrades()}
            className="btn btn-primary mr-4"
            disabled={!from || !to}
          >
            Raw trades
          </button>
        </div>
      </div>
    </div>
  )
}

export default React.memo(ExportsPage)

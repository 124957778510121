import React, { useMemo, useState } from 'react'
import { useRequest } from '../../services/api'
import AirTable from '../ui/table'
import moment from 'moment'

function SearchLogsListPage() {
  const [page, setPage] = useState(1)
  const { data, error } = useRequest(`/admin/searches?page=${page}&pageSize=50`)
  const [requesting, setRequesting] = useState(false)

  const { searches = [], totalItems, totalPages } = data || {}

  const columns = useMemo(() => {
    return [
      {
        Header: 'Query',
        accessor: 'query',
      },
      {
        Header: 'Has Results',
        accessor: 'hasResults',
        Cell({ value }) {
          return value ? 'Yes' : 'No'
        }
      },
    ]
  }, [requesting])

  return (
    <div className="container-fluid p-3">
      <div className="flex justify-between items-center mb-6">
        <h2>Search Logs</h2>
      </div>
      <AirTable
        columns={columns}
        data={searches}
        loading={!data && !error}
        totalItem={totalItems}
        totalPage={totalPages}
        onChangePageIndex={setPage}
        pagging
      />
    </div>
  )
}

export default React.memo(SearchLogsListPage)

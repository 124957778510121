import React, {useMemo, useState} from 'react'
import {useRequest} from '../../services/api'
import AirTable from '../ui/table'
import qs from 'querystring'
import AutoComplete from '../ui/symbol-dropdown'

function BenzingaRatings() {
  const [search, setSearch] = useState('')
  const query = qs.stringify({
    symbol: search,
  })
  const onInputChange = (e) => {
    setSearch(e.value)
  }
  const {data, error} = useRequest(`/admin/benzinga/ratings?${query}`)
  const [requesting, setRequesting] = useState(false)

  const {ratings = []} = data || {}

  const columns = useMemo(() => {
    return [
      {
        Header: 'Ticker',
        accessor: 'ticker',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Action Company',
        accessor: 'action_company',
      },
      {
        Header: 'Action PT',
        accessor: 'action_pt',
      },
      {
        Header: 'Analyst',
        accessor: 'analyst',
      },
      {
        Header: 'Analyst Name',
        accessor: 'analyst_name',
      },
      {
        Header: 'Currency',
        accessor: 'currency',
      },
      {
        Header: 'Exchange',
        accessor: 'exchange',
      },
      {
        Header: 'Importance',
        accessor: 'importance',
      },
      {
        Header: 'Notes',
        accessor: 'notes',
      },
      {
        Header: 'PT Current',
        accessor: 'pt_current',
      },
      {
        Header: 'PT Prior',
        accessor: 'pt_prior',
      },
      {
        Header: 'Rating Current',
        accessor: 'rating_current',
      },
      {
        Header: 'Rating Prior',
        accessor: 'rating_prior',
      },
      {
        Header: 'URL',
        accessor: 'url',
        Cell({value}) {
          return <a href={value} target="_blank">{value}</a>
        }
      },
      {
        Header: 'URL Calendar',
        accessor: 'url_calendar',
        Cell({value}) {
          return <a href={value} target="_blank">{value}</a>
        }
      },
      {
        Header: 'URL News',
        accessor: 'url_news',
        Cell({value}) {
          return <a href={value} target="_blank">{value}</a>
        }
      },
      {
        Header: 'Time',
        accessor: 'time',
      },
      {
        Header: 'Date',
        accessor: 'date',
      },
    ]
  }, [requesting])

  return (
    <div className="container-fluid p-3">
      <div className="d-flex justify-content-between align-items-center mb-6">
        <h2>Benzinga Ratings</h2>
        <div style={{width: '320px'}}>
          <AutoComplete onChange={onInputChange}/>
        </div>
      </div>
      <AirTable
        columns={columns}
        data={ratings}
        loading={!data && !error}
      />
    </div>
  )
}

export default React.memo(BenzingaRatings)

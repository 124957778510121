import React from 'react'
import { useField, useForm } from 'react-final-form-hooks'
import { required } from '../../helpers/hooks/validators'
import _ from 'lodash'
import { useRequest } from '../../services/api'
import { toast } from 'react-toastify'
import Select from 'react-select'

const SYMBOLS = _.sortBy([...new Set([
  'C',
  'DAL',
  'TLRY',
  'IWM',
  'SPY',
  'QQQ',
  'AMD',
  'NVDA',
  'NFLX',
  'META',
  'AMZN',
  'GME',
  'AMC',
  'PLTR',
  'AAPL',
  'MU',
  'COIN',
  'OXY',
  'XOM',
  'BABA',
  'NIO',
  'TGT',
  'WMT',
  'BA',
  'MSFT',
  'SOFI',
  'DKNG',
  'BAC',
  'TSLA',
  'GOOGL',
  'ABNB',
  'AFRM',
  'AI',
  'CS',
  'INTC',
  'JPM',
  'KRE',
  'SQ',
  'TLT',
  'WFC',
  'ARKK',
  'DIS',
  'EEM',
  'GLD',
  'PATH',
  'PYPL',
  'RBLX',
  'ROKU',
  'SCHW',
  'SHOP',
  'SLV',
  'SNAP',
  'SNOW',
  'AAL',
  'AFRM',
  'AI',
  'ARKK',
  'BB',
  'BSX',
  'C',
  'CCJ',
  'CCL',
  'CHPT',
  'CLF',
  'CMCSA',
  'CSCO',
  'CVNA',
  'DIS',
  'DVN',
  'EEM',
  'EFA',
  'ENVX',
  'EOSE',
  'ET',
  'EWZ',
  'F',
  'FCX',
  'FTCH',
  'FXI',
  'GDX',
  'GLD',
  'GM',
  'GOOG',
  'HYG',
  'INTC',
  'IWM',
  'JD',
  'JNJ',
  'JPM',
  'KRE',
  'KVUE',
  'KWEB',
  'LCID',
  'MARA',
  'MRVL',
  'NKLA',
  'ON',
  'ORCL',
  'PANW',
  'PBR',
  'PDD',
  'PFE',
  'PLUG',
  'PYPL',
  'RIOT',
  'RIVN',
  'SA',
  'SE',
  'SHOP',
  'SLV',
  'SNAP',
  'SNOW',
  'SOXL',
  'SOXS',
  'SQQQ',
  'SVXY',
  'TLT',
  'TQQQ',
  'UBER',
  'UPST',
  'UVXY',
  'VALE',
  'VXX',
  'VZ',
  'WBD',
  'WFC',
  'XLE',
  'XLF',
  'XPEV',
  'AA',
  'AAOI',
  'AAP',
  'ABBV',
  'ABCM',
  'ACHR',
  'ADBE',
  'ADI',
  'AEM',
  'AG',
  'AGNC',
  'AGQ',
  'AKRO',
  'ALB',
  'ALGM',
  'ALLY',
  'ALTO',
  'AM',
  'AMAT',
  'AMPY',
  'AMSC',
  'AMT',
  'ANF',
  'APA',
  'APLS',
  'APP',
  'APT',
  'AR',
  'ARDX',
  'ARKG',
  'ARRY',
  'ASHR',
  'ASML',
  'ATVI',
  'AVGO',
  'AXTA',
  'AZN',
  'BBIO',
  'BBWI',
  'BBY',
  'BCRX',
  'BEKE',
  'BGS',
  'BHC',
  'BIDU',
  'BILI',
  'BITF',
  'BITO',
  'BKKT',
  'BKNG',
  'BLNK',
  'BLUE',
  'BMBL',
  'BMO',
  'BMY',
  'BNTX',
  'BOIL',
  'BP',
  'BURL',
  'BX',
  'BYND',
  'CAG',
  'CANO',
  'CARR',
  'CAT',
  'CAVA',
  'CGC',
  'CHGG',
  'CHS',
  'CHTR',
  'CHWY',
  'CMG',
  'COST',
  'COTY',
  'CPNG',
  'CPRT',
  'CRDO',
  'CRM',
  'CRON',
  'CRWD',
  'CS',
  'CVS',
  'CVX',
  'CWB',
  'D',
  'DASH',
  'DBI',
  'DDOG',
  'DFS',
  'DHC',
  'DIA',
  'DISH',
  'DLR',
  'DOCN',
  'DOCU',
  'DOW',
  'DPST',
  'DWAC',
  'EBAY',
  'EDR',
  'EGIO',
  'EGO',
  'EMB',
  'ENPH',
  'ENTG',
  'EPD',
  'EQNR',
  'ERX',
  'ERY',
  'ETRN',
  'ETSY',
  'EVGO',
  'EWC',
  'EXTR',
  'FANG',
  'FAS',
  'FAZ',
  'FCEL',
  'FFIE',
  'FL',
  'FLR',
  'FNGR',
  'FREY',
  'FSLR',
  'FSLY',
  'FSR',
  'FTNT',
  'FUBO',
  'FUTU',
  'GDRX',
  'GDXJ',
  'GEHC',
  'GES',
  'GGAL',
  'GIS',
  'GLNG',
  'GNRC',
  'GNS',
  'GOEV',
  'GOLD',
  'GPN',
  'GPS',
  'GRPN',
  'GS',
  'GSK',
  'GT',
  'HAL',
  'HCP',
  'HD',
  'HE',
  'HES',
  'HON',
  'HOOD',
  'HPE',
  'HPQ',
  'HSBC',
  'HZNP',
  'IAU',
  'IBKR',
  'IBM',
  'ICLN',
  'IEF',
  'INDI',
  'INVZ',
  'IOT',
  'IQ',
  'IRBT',
  'ITB',
  'IYR',
  'JBLU',
  'JDST',
  'JETS',
  'JNUG',
  'JOBY',
  'JWN',
  'KBE',
  'KEY',
  'KGC',
  'KHC',
  'KMI',
  'KO',
  'KSS',
  'LABD',
  'LABU',
  'LAZR',
  'LC',
  'LEN',
  'LI',
  'LIT',
  'LLY',
  'LMND',
  'LNC',
  'LNG',
  'LOW',
  'LQD',
  'LRCX',
  'LSCC',
  'LTHM',
  'LUV',
  'LVS',
  'LYFT',
  'LYV',
  'LZ',
  'M',
  'MANU',
  'MBLY',
  'MCD',
  'MCHP',
  'MDB',
  'MDT',
  'MELI',
  'MET',
  'MGM',
  'MO',
  'MP',
  'MPC',
  'MPLX',
  'MPW',
  'MQ',
  'MREO',
  'MRK',
  'MRNA',
  'MRO',
  'MS',
  'MSTR',
  'MULN',
  'NCHL',
  'NCLH',
  'NEE',
  'NEM',
  'NEP',
  'NFE',
  'NKE',
  'NTNX',
  'NU',
  'NUGT',
  'NVAX',
  'NXE',
  'NXPI',
  'OARK',
  'OKTA',
  'OPEN',
  'OSTK',
  'PAGS',
  'PARA',
  'PAYC',
  'PBF',
  'PD',
  'PENN',
  'PG',
  'PGY',
  'PINS',
  'PRU',
  'PSQ',
  'PTON',
  'QCOM',
  'QS',
  'RAD',
  'RCL',
  'REAL',
  'RETA',
  'RF',
  'RIG',
  'RILY',
  'RKLB',
  'RPRX',
  'RTX',
  'RUM',
  'RUN',
  'S',
  'SA',
  'SAVE',
  'SBLK',
  'SBUX',
  'SCHD',
  'SCO',
  'SDOW',
  'SEDG',
  'SGEN',
  'SH',
  'SILV',
  'SIRI',
  'SLB',
  'SMCI',
  'SMH',
  'SNBR',
  'SO',
  'SPLK',
  'SPOT',
  'SPR',
  'SPWH',
  'SPWR',
  'SPXL',
  'SPXS',
  'SPXU',
  'SQ',
  'SQM',
  'SRTY',
  'STLA',
  'STNE',
  'STNG',
  'SWKS',
  'SWN',
  'T',
  'TECK',
  'TECL',
  'TELL',
  'TEVA',
  'TEX',
  'TFC',
  'TMUS',
  'TNA',
  'TOL',
  'TSEM',
  'TSLL',
  'TSLY',
  'TSM',
  'TTD',
  'TTOO',
  'TWLO',
  'TZA',
  'U',
  'UAL',
  'UBS',
  'UCO',
  'UDN',
  'UDOW',
  'ULTA',
  'UNG',
  'UNH',
  'UNIT',
  'UPS',
  'URA',
  'URBN',
  'URTY',
  'USB',
  'UTZ',
  'UUP',
  'UVIX',
  'V',
  'VET',
  'VFC',
  'VIX',
  'VIXY',
  'VLD',
  'VLO',
  'VNO',
  'VOO',
  'VRM',
  'VRT',
  'W',
  'WBA',
  'WMB',
  'WMG',
  'WTRG',
  'WYNN',
  'X',
  'XBI',
  'XEL',
  'XHB',
  'XLB',
  'XLI',
  'XLK',
  'XLP',
  'XLU',
  'XLV',
  'XOP',
  'XRT',
  'XSP',
  'YINN',
  'ZI',
  'ZIM',
  'ZM',
  'ZS',
])]).map(s => ({ label: s, value: s }))

function CreateStrategyForm({ onSave = () => null }) {
  const { data: indicatorsData } = useRequest('/admin/options-auto-signals/indicators')
  const { indicators = [] } = indicatorsData || {}

  const { form, handleSubmit, pristine, submitting } = useForm({
    async onSubmit(values, formAPI) {
      console.log(values)
      values.symbols = values.symbols.map(i => i.value)

      const indicators = values.indicators.map(i => {
        const { name } = i

        delete i.name
        delete i.indicator
        delete i.type

        return {
          name,
          type: 'entry',
          config: i,
        }
      })

      const priceTargetConditions = (values.priceTargetConditions || []).map(i => {
        const { name } = i

        delete i.name
        delete i.indicator
        delete i.type

        return {
          name,
          type: 'entry',
          config: i,
        }
      })

      const stopLossConditions = (values.stopLossConditions || []).map(i => {
        const { name } = i

        delete i.name
        delete i.indicator
        delete i.type

        return {
          name,
          type: 'entry',
          config: i,
        }
      })

      delete values.priceTargetConditions
      delete values.stopLossConditions

      try {
        await onSave(
          {
            ...values,
            indicators: [...indicators],
            priceTargetConditions,
            stopLossConditions
          },
          formAPI,
        )
        window.location.reload()
      } catch (err) {
        toast.error(err.response.data.message)
      }
    },
  })

  const fieldName = useField('name', form, required)
  const fieldSymbol = useField('symbols', form, required)
  const fieldEnv = useField('env', form, required)
  const fieldActive = useField('active', form, required)
  const fieldIndicators = useField('indicators', form, required)
  const fieldPriceTargetConditions = useField('priceTargetConditions', form)
  const fieldStopLossConditions = useField('stopLossConditions', form)
  const fieldContractType = useField('contractType', form, required)
  const fieldStrikePosition = useField('strikePosition', form, required)
  const fieldExpirationDatePosition = useField('expirationDatePosition', form, required)
  const fieldResolution = useField('resolution', form, required)
  const fieldStopLoss = useField('stopLoss', form, required)
  const fieldPriceTarget = useField('priceTarget', form, required)
  const fieldOptionPriceFrom = useField('optionPriceFrom', form)
  const fieldOptionPriceTo = useField('optionPriceTo', form)
  const fieldDescription = useField('description', form, required)
  const fieldAccountSize = useField('accountSize', form, required)

  return (
    <form className="bg-white rounded p-3" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <label>Name</label>
            <input {...fieldName.input} placeholder="Name" className="form-control rounded" />
            {fieldName.meta.touched && fieldName.meta.error && (
              <span className="text-danger text-sm">{fieldName.meta.error}</span>
            )}
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-group">
            <label>Description</label>
            <input {...fieldDescription.input} placeholder="Description" className="form-control rounded" />
            {fieldDescription.meta.touched && fieldDescription.meta.error && (
              <span className="text-danger text-sm">{fieldDescription.meta.error}</span>
            )}
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-group">
            <label>Symbol</label>
            <Select
              isMulti
              name="symbols"
              options={SYMBOLS}
              className="basic-multi-select"
              classNamePrefix="select"
              {...fieldSymbol.input}
            />
            {fieldSymbol.meta.touched && fieldSymbol.meta.error && (
              <span className="text-danger text-sm">{fieldSymbol.meta.error}</span>
            )}
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-group">
            <label>Environment</label>
            <select {...fieldEnv.input} className="form-control rounded">
              <option value="">Select...</option>
              <option value="test">Test</option>
              <option value="live">Live</option>
              <option value="sandbox">Sandbox</option>
            </select>
            {fieldEnv.meta.touched && fieldEnv.meta.error && (
              <span className="text-danger text-sm">{fieldEnv.meta.error}</span>
            )}
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-group">
            <label>Active</label>
            <select {...fieldActive.input} className="form-control rounded">
              <option value="">Select...</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
            {fieldActive.meta.touched && fieldActive.meta.error && (
              <span className="text-danger text-sm">{fieldActive.meta.error}</span>
            )}
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-group row">
            <div className="col-md-12">
              <label>Entry Conditions</label>
            </div>
            {_.map(fieldIndicators.input.value, (item, idx) => (
              <div className="col-md-12" key={idx}>
                <div className="row mt-2">
                  <div className="col-md-2" key={item?.name + idx}>
                    <select
                      onChange={(e) => {
                        const config = {}
                        const ind = indicators.find(i => i.name === e.target.value) || {}

                        for (const key of Object.keys(ind)) {
                          config[key] = ''
                        }

                        config.type = ind.type

                        const { value = [], onChange } = fieldIndicators.input
                        onChange(value.map((c, index) => (index === idx ? {
                          ...config,
                          name: e.target.value,
                          indicator: ind,
                        } : c)))
                      }}
                      className="form-control rounded"
                      defaultValue={item?.name}
                    >
                      <option value="">Select...</option>
                      {_.map(indicators, (item, idx) => (
                        <option key={idx} value={item?.name}>{item?.name}</option>
                      ))}
                    </select>
                    {fieldIndicators.meta.touched && fieldIndicators.meta.error && (
                      <span className="text-danger text-sm">{fieldIndicators.meta.error}</span>
                    )}
                  </div>
                  {
                    Object.keys(item.indicator || {}).map(key => {
                      if (key === 'name' || key === 'type') {
                        return null
                      }
                      if (item.indicator[key].type === 'enum') {
                        return (
                          <div className="col-md-2" key={key + idx}>
                            <select
                              className="form-control rounded mb-2"
                              value={item[key]}
                              onChange={(e) => {
                                const { value = [], onChange } = fieldIndicators.input
                                onChange(value.map((c, index) => (index === idx ? {
                                  ...c,
                                  [key]: e.target.value
                                } : c)))
                              }}
                            >
                              <option>Select {key}...</option>
                              {
                                item.indicator[key].values.map(c => {
                                  return (
                                    <option key={c} value={c}>{c}</option>
                                  )
                                })
                              }
                            </select>
                          </div>
                        )
                      }

                      return (
                        <div className="col-md-2" key={key + idx}>
                          <input
                            type={item.indicator[key].type}
                            placeholder={item.indicator[key].placeholder || key}
                            className="form-control rounded mb-2"
                            value={item[key]}
                            onChange={(e) => {
                              const { value = [], onChange } = fieldIndicators.input
                              onChange(value.map((c, index) => (index === idx ? {
                                ...c,
                                [key]: e.target.value
                              } : c)))
                            }}
                          />
                        </div>
                      )
                    })}
                  <div className="col-md-12 mb-3">
                    <button
                      type="button"
                      className="btn-danger btn-square mt-1"
                      onClick={() =>
                        fieldIndicators.input.onChange(
                          fieldIndicators.input.value.filter((c, index) => index !== idx),
                        )
                      }
                    >
                      <i className="fa fa-trash" />
                    </button>
                  </div>
                </div>
              </div>
            ))}

            <div className="col-md-12">
              <button
                type="button"
                className="btn btn-primary px-2 py-0 btn-sm"
                onClick={() => {
                  fieldIndicators.input.onChange([...fieldIndicators.input.value, { indicator: {} }])
                }}
              >
                + Add more
              </button>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-group row">
            <div className="col-md-12">
              <label>Price Target Conditions</label>
            </div>
            {_.map(fieldPriceTargetConditions.input.value, (item, idx) => (
              <div className="col-md-12" key={idx}>
                <div className="row mt-2">
                  <div className="col-md-2" key={item?.name + idx}>
                    <select
                      onChange={(e) => {
                        const config = {}
                        const ind = indicators.find(i => i.name === e.target.value) || {}

                        for (const key of Object.keys(ind)) {
                          config[key] = ''
                        }

                        config.type = ind.type

                        const { value = [], onChange } = fieldPriceTargetConditions.input
                        onChange(value.map((c, index) => (index === idx ? {
                          ...config,
                          name: e.target.value,
                          indicator: ind,
                        } : c)))
                      }}
                      className="form-control rounded"
                      defaultValue={item?.name}
                    >
                      <option value="">Select...</option>
                      {_.map(indicators, (item, idx) => (
                        <option key={idx} value={item?.name}>{item?.name}</option>
                      ))}
                    </select>
                    {fieldPriceTargetConditions.meta.touched && fieldPriceTargetConditions.meta.error && (
                      <span className="text-danger text-sm">{fieldPriceTargetConditions.meta.error}</span>
                    )}
                  </div>
                  {
                    Object.keys(item.indicator || {}).map(key => {
                      if (key === 'name' || key === 'type') {
                        return null
                      }
                      if (item.indicator[key].type === 'enum') {
                        return (
                          <div className="col-md-2" key={key + idx}>
                            <select
                              className="form-control rounded mb-2"
                              value={item[key]}
                              onChange={(e) => {
                                const { value = [], onChange } = fieldPriceTargetConditions.input
                                onChange(value.map((c, index) => (index === idx ? {
                                  ...c,
                                  [key]: e.target.value
                                } : c)))
                              }}
                            >
                              <option>Select {key}...</option>
                              {
                                item.indicator[key].values.map(c => {
                                  return (
                                    <option key={c} value={c}>{c}</option>
                                  )
                                })
                              }
                            </select>
                          </div>
                        )
                      }

                      return (
                        <div className="col-md-2" key={key + idx}>
                          <input
                            type={item.indicator[key].type}
                            placeholder={item.indicator[key].placeholder || key}
                            className="form-control rounded mb-2"
                            value={item[key]}
                            onChange={(e) => {
                              const { value = [], onChange } = fieldPriceTargetConditions.input
                              onChange(value.map((c, index) => (index === idx ? {
                                ...c,
                                [key]: e.target.value
                              } : c)))
                            }}
                          />
                        </div>
                      )
                    })}
                  <div className="col-md-12 mb-3">
                    <button
                      type="button"
                      className="btn-danger btn-square mt-1"
                      onClick={() =>
                        fieldPriceTargetConditions.input.onChange(
                          fieldPriceTargetConditions.input.value.filter((c, index) => index !== idx),
                        )
                      }
                    >
                      <i className="fa fa-trash" />
                    </button>
                  </div>
                </div>
              </div>
            ))}

            <div className="col-md-12">
              <button
                type="button"
                className="btn btn-primary px-2 py-0 btn-sm"
                onClick={() => {
                  fieldPriceTargetConditions.input.onChange([...fieldPriceTargetConditions.input.value, { indicator: {} }])
                }}
              >
                + Add more
              </button>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-group row">
            <div className="col-md-12">
              <label>Stop Loss Conditions</label>
            </div>
            {_.map(fieldStopLossConditions.input.value, (item, idx) => (
              <div className="col-md-12" key={idx}>
                <div className="row mt-2">
                  <div className="col-md-2" key={item?.name + idx}>
                    <select
                      onChange={(e) => {
                        const config = {}
                        const ind = indicators.find(i => i.name === e.target.value) || {}

                        for (const key of Object.keys(ind)) {
                          config[key] = ''
                        }

                        config.type = ind.type

                        const { value = [], onChange } = fieldStopLossConditions.input
                        onChange(value.map((c, index) => (index === idx ? {
                          ...config,
                          name: e.target.value,
                          indicator: ind,
                        } : c)))
                      }}
                      className="form-control rounded"
                      defaultValue={item?.name}
                    >
                      <option value="">Select...</option>
                      {_.map(indicators, (item, idx) => (
                        <option key={idx} value={item?.name}>{item?.name}</option>
                      ))}
                    </select>
                    {fieldStopLossConditions.meta.touched && fieldStopLossConditions.meta.error && (
                      <span className="text-danger text-sm">{fieldStopLossConditions.meta.error}</span>
                    )}
                  </div>
                  {
                    Object.keys(item.indicator || {}).map(key => {
                      if (key === 'name' || key === 'type') {
                        return null
                      }
                      if (item.indicator[key].type === 'enum') {
                        return (
                          <div className="col-md-2" key={key + idx}>
                            <select
                              className="form-control rounded mb-2"
                              value={item[key]}
                              onChange={(e) => {
                                const { value = [], onChange } = fieldStopLossConditions.input
                                onChange(value.map((c, index) => (index === idx ? {
                                  ...c,
                                  [key]: e.target.value
                                } : c)))
                              }}
                            >
                              <option>Select {key}...</option>
                              {
                                item.indicator[key].values.map(c => {
                                  return (
                                    <option key={c} value={c}>{c}</option>
                                  )
                                })
                              }
                            </select>
                          </div>
                        )
                      }

                      return (
                        <div className="col-md-2" key={key + idx}>
                          <input
                            type={item.indicator[key].type}
                            placeholder={item.indicator[key].placeholder || key}
                            className="form-control rounded mb-2"
                            value={item[key]}
                            onChange={(e) => {
                              const { value = [], onChange } = fieldStopLossConditions.input
                              onChange(value.map((c, index) => (index === idx ? {
                                ...c,
                                [key]: e.target.value
                              } : c)))
                            }}
                          />
                        </div>
                      )
                    })}
                  <div className="col-md-12 mb-3">
                    <button
                      type="button"
                      className="btn-danger btn-square mt-1"
                      onClick={() =>
                        fieldStopLossConditions.input.onChange(
                          fieldStopLossConditions.input.value.filter((c, index) => index !== idx),
                        )
                      }
                    >
                      <i className="fa fa-trash" />
                    </button>
                  </div>
                </div>
              </div>
            ))}

            <div className="col-md-12">
              <button
                type="button"
                className="btn btn-primary px-2 py-0 btn-sm"
                onClick={() => {
                  fieldStopLossConditions.input.onChange([...fieldStopLossConditions.input.value, { indicator: {} }])
                }}
              >
                + Add more
              </button>
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-group">
            <label>Contract Type</label>
            <select {...fieldContractType.input} className="form-control rounded">
              <option value="">Select...</option>
              <option value="put">Put</option>
              <option value="call">Call</option>
            </select>
            {fieldContractType.meta.touched && fieldContractType.meta.error && (
              <span className="text-danger text-sm">{fieldContractType.meta.error}</span>
            )}
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-group">
            <label>Strike Position</label>
            <select {...fieldStrikePosition.input} className="form-control rounded">
              <option value="0">Select...</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="16">16</option>
              <option value="17">17</option>
              <option value="18">18</option>
              <option value="19">19</option>
              <option value="20">20</option>
            </select>
            {fieldStrikePosition.meta.touched && fieldStrikePosition.meta.error && (
              <span className="text-danger text-sm">{fieldStrikePosition.meta.error}</span>
            )}
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-group">
            <label>Expiration Date Position</label>
            <select {...fieldExpirationDatePosition.input} className="form-control rounded">
              <option value="0">Select...</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="16">16</option>
              <option value="17">17</option>
              <option value="18">18</option>
              <option value="19">19</option>
              <option value="20">20</option>
            </select>
            {fieldExpirationDatePosition.meta.touched && fieldExpirationDatePosition.meta.error && (
              <span className="text-danger text-sm">{fieldExpirationDatePosition.meta.error}</span>
            )}
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-group">
            <label>Resolution</label>
            <select {...fieldResolution.input} className="form-control rounded">
              <option value="">Select...</option>
              <option value="1 minute">1 minute</option>
              <option value="2 minute">2 minute</option>
              <option value="3 minute">3 minute</option>
              <option value="5 minute">5 minute</option>
              <option value="10 minute">10 minute</option>
              <option value="15 minute">15 minute</option>
              <option value="30 minute">30 minute</option>
              <option value="60 minute">60 minute</option>
            </select>
            {fieldResolution.meta.touched && fieldResolution.meta.error && (
              <span className="text-danger text-sm">{fieldResolution.meta.error}</span>
            )}
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-group">
            <label>Price Target</label>
            <input {...fieldPriceTarget.input} placeholder="0.05" className="form-control rounded" />
            {fieldPriceTarget.meta.touched && fieldPriceTarget.meta.error && (
              <span className="text-danger text-sm">{fieldPriceTarget.meta.error}</span>
            )}
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-group">
            <label>Stop Loss</label>
            <input {...fieldStopLoss.input} placeholder="0.2" className="form-control rounded" />
            {fieldStopLoss.meta.touched && fieldStopLoss.meta.error && (
              <span className="text-danger text-sm">{fieldStopLoss.meta.error}</span>
            )}
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-group">
            <label>Option Price From</label>
            <input {...fieldOptionPriceFrom.input} placeholder="0.05" className="form-control rounded" />
            {fieldOptionPriceFrom.meta.touched && fieldOptionPriceFrom.meta.error && (
              <span className="text-danger text-sm">{fieldOptionPriceFrom.meta.error}</span>
            )}
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-group">
            <label>Option Price To</label>
            <input {...fieldOptionPriceTo.input} placeholder="0.1" className="form-control rounded" />
            {fieldOptionPriceTo.meta.touched && fieldOptionPriceTo.meta.error && (
              <span className="text-danger text-sm">{fieldOptionPriceTo.meta.error}</span>
            )}
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-group">
            <label>Account Size</label>
            <input {...fieldAccountSize.input} placeholder="100" className="form-control rounded" />
            {fieldAccountSize.meta.touched && fieldAccountSize.meta.error && (
              <span className="text-danger text-sm">{fieldAccountSize.meta.error}</span>
            )}
          </div>
        </div>
        <div className="col-md-12 mt-4">
          <div className="form-group">
            <button type="submit" className="btn btn-primary" disabled={pristine || submitting}>
              Save
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default CreateStrategyForm

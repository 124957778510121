export default {
  auth: {
    tokenName: 'btw_token',
    userName: 'btw_user',
  }
}

export const stocks = [
  {
    name: 'AAPL',
    value: 'AAPL'
  },
  {
    name: 'TSLA',
    value: 'TSLA'
  },
  {
    name: 'MSFT',
    value: 'MSFT'
  },
  {
    name: 'AMZN',
    value: 'AMZN'
  },
  {
    name: 'NFLX',
    value: 'NFLX'
  },
  {
    name: 'QQQ',
    value: 'QQQ'
  },
  {
    name: 'SPY',
    value: 'SPY'
  },
  {
    name: 'BA',
    value: 'BA'
  },
  {
    name: 'NIO',
    value: 'NIO'
  },
  {
    name: 'GOOG',
    value: 'GOOG'
  },
  {
    name: 'Z',
    value: 'Z'
  },
  {
    name: 'FB',
    value: 'FB'
  },
  {
    name: 'CRM',
    value: 'CRM'
  },
  {
    name: 'SNOW',
    value: 'SNOW'
  },
  {
    name: 'TWTR',
    value: 'TWTR'
  },
  {
    name: 'AMD',
    value: 'AMD'
  },
  {
    name: 'NVDA',
    value: 'NVDA'
  },
  {
    name: 'PYPL',
    value: 'PYPL'
  },
  {
    name: 'RBLX',
    value: 'RBLX'
  },
  {
    name: 'AMAT',
    value: 'AMAT'
  },
  {
    name: 'CHPT',
    value: 'CHPT'
  },
]
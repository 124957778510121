import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authService } from "../../services";
import TwoFactorAuthModal from "./TwoFactorAuthModal";
import { toast, ToastContainer } from "react-toastify";
import { onStoreUser } from "../../store/actions/auth";

function Profile() {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [secret, setSecret] = useState({
    otpAuthUrl: "",
    base32: "",
  });

  const generateQrCode = async () => {
    try {
      setIsLoading(true);
      const response = await authService.generateOtp({
        userId: user.id,
        email: user.email,
      });

      if (response.status === 200) {
        setSecret({
          otpAuthUrl: response.data.otpAuthUrl,
          base32: response.data.base32,
        });
        setOpenModal(true);
      }
    } catch (err) {
      toast.error(err.message || "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const disableOtp = async () => {
    try {
      setIsLoading(true);
      const response = await authService.disableOtp({
        userId: user.id,
      });

      if (response.status === 200) {
        toast.success("2FA disabled successfully");
      }

      dispatch(
        onStoreUser({
          ...user,
          otpEnabled: false,
          otpVerified: false,
        })
      );
    } catch (err) {
      toast.error(err.message || "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  console.log(user);
  return (
    <div className="container-fluid p-3">
      <ToastContainer />
      <div className="d-flex justify-content-between align-items-center mb-6">
        <div>
          <h2>Mobile App Authenticator (2FA)</h2>
          <p>Secoure your account with TOTP two-factor authenticator.</p>
          {user.otpEnabled ? (
            <button
              onClick={disableOtp}
              disabled={isLoading}
              className="btn btn-primary"
            >
              Disable 2FA
            </button>
          ) : (
            <button
              onClick={generateQrCode}
              disabled={isLoading}
              className="btn btn-primary cursor-pointer"
            >
              Generate 2FA
            </button>
          )}
        </div>
      </div>

      {openModal && (
        <TwoFactorAuthModal
          showModal={openModal}
          setShowModal={setOpenModal}
          secret={secret}
        />
      )}
    </div>
  );
}

export default React.memo(Profile);

import React, { useMemo, useState } from 'react'
import { useRequest } from '../../../services/api'
import AirTable from '../../ui/table'
import qs from 'querystring'
import AutoComplete from '../../ui/symbol-dropdown'
import moment from 'moment'

function IexRecommendationsAndPriceTargetListPage() {
  const [search, setSearch] = useState('')
  const query = qs.stringify({
    symbol: search,
  })
  const onInputChange = (e) => {
    setSearch(e.value)
  }
  const { data, error } = useRequest(`/admin/iex/recommendations-and-price-targets?${query}`, { revalidateOnFocus: false })
  const [requesting, setRequesting] = useState(false)

  const { recommendationsAndPriceTargets = [] } = data || {}

  const columns = useMemo(() => {
    return [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Symbol',
        accessor: 'symbol',
      },
      {
        Header: 'Key',
        accessor: 'key',
      },
      {
        Header: 'Sub Key',
        accessor: 'subkey',
      },
      {
        Header: 'Analyst Count',
        accessor: 'analystCount',
      },
      {
        Header: 'Market Consensus',
        accessor: 'marketConsensus',
      },
      {
        Header: 'Market Consensus Target Price',
        accessor: 'marketConsensusTargetPrice',
      },
      {
        Header: 'Consensus Date',
        accessor: 'consensusDate',
        Cell({ value }) {
          return moment(value).format('MM-DD-YYYY')
        }
      },
      {
        Header: 'Date',
        accessor: 'date',
        Cell({ value }) {
          return value ? moment.unix(value / 1000).format('MM-DD-YYYY HH:mm') : ""
        }
      },
      {
        Header: 'Updated',
        accessor: 'updated',
        Cell({ value }) {
          return value ? moment.unix(value / 1000).format('MM-DD-YYYY HH:mm') : ""
        }
      },
    ]
  }, [requesting])

  return (
    <div className="container-fluid p-3">
      <div className="d-flex justify-content-between align-items-center mb-6">
        <h2>IEX Recommendations & Price Targets</h2>
        <div style={{ width: '320px' }}>
          <AutoComplete onChange={onInputChange}/>
        </div>
      </div>
      <AirTable
        columns={columns}
        data={recommendationsAndPriceTargets}
        loading={!data && !error}
      />
    </div>
  )
}

export default React.memo(IexRecommendationsAndPriceTargetListPage)

import React, {useEffect} from 'react'
import styled from 'styled-components'
import {usePagination, useTable} from 'react-table'

const TableContainer = styled.div`
  overflow: hidden;
  position: relative;
  .table-data {
    overflow: scroll;
    position: relative;
    max-height: ${({tableHeight}) => (typeof tableHeight === 'number' ? `${tableHeight}px` : tableHeight)};
    border: 1px solid #d2d6de;
    z-index: 1;
  }
  table {
    border-spacing: 0;
    width: 100%;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th {
      position: sticky;
      top: 0;
      background-color: #ededed;
      z-index: 50;
      text-align: center;
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #d2d6de;
      border-right: 1px solid #d2d6de;
      &.loading {
        padding: 1.5rem;
      }
      :last-child {
        border-right: 0;
      }
    }
    tr.disabled {
      background-color: #ededed;
      opacity: 0.2;
    }
  }
`

function AirTable({
                    columns = [],
                    data = [],
                    totalPage = 0,
                    totalItem = 0,
                    loading = false,
                    rowClassName = () => null,
                    onChangePageIndex = () => null,
                    hidePaging = false,
                    pagging = false
                  }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // data

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: {pageIndex},
  } = useTable(
    {
      columns,
      data,
      initialState: {pageIndex: 0},
      manualPagination: true,
      pageCount: totalPage,
    },
    usePagination,
  )

  useEffect(() => {
    onChangePageIndex(pageIndex + 1)
  }, [pageIndex])

  // Render the UI for your table
  return (
    <TableContainer tableHeight="80vh">
      <div className="table-data bg-white rounded">
        <table {...getTableProps()}>
          <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
          </thead>
          <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()} className={rowClassName(row)}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
          {!loading && !data.length && (
            <tr>
              <td colSpan="10000" className="text-center">
                <div className="flex items-center justify-center w-full p-4">
                  <p className="m-0 text-info font-semibold">No data</p>
                </div>
              </td>
            </tr>
          )}
          {loading && (
            <tr>
              <td colSpan="10000" className="text-center p-4 loading">
                <div className="flex items-center justify-center w-full">
                  <p className="m-0">Loading...</p>
                </div>
              </td>
            </tr>
          )}
          </tbody>
        </table>
      </div>
      {pagging &&
      <div className="d-flex align-items-center justify-content-between pt-3">
        <div>
          <p className="m-0">
            Showing {data.length} of {totalItem}
          </p>
        </div>
        <div>
          <button className="w-48" onClick={() => previousPage()} disabled={!canPreviousPage}>
            Previous
          </button>
          <span className="mx-4">
            <span>Page </span>
            <strong>
              {pageOptions.length ? pageIndex + 1 : 0} of {pageOptions.length}{' '}
            </strong>
          </span>
          <button className="w-48" onClick={() => nextPage()} disabled={!canNextPage}>
            Next
          </button>
        </div>
      </div>
      }
    </TableContainer>
  )
}

export default React.memo(AirTable)
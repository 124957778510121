import React, {useMemo, useState} from 'react'
import {useRequest} from '../../services/api'
import AirTable from '../ui/table'
import moment from 'moment'

function NewsArticle() {
  const {data, error} = useRequest(`/admin/news-articles`)
  const [requesting, setRequesting] = useState(false)
  console.log(data)

  const {companyNews = []} = data || {}

  const columns = useMemo(() => {
    return [
      {
        Header: 'Category',
        accessor: 'category',
      },
      {
        Header: 'Related',
        accessor: 'related',
        Cell({value}) {
          return value.join()
        },
      },
      {
        Header: 'Headline',
        accessor: 'headline',
      },
      {
        Header: 'Summary',
        accessor: 'summary',
      },
      {
        Header: 'Date',
        accessor: 'datetime',
        Cell({value}) {
          return moment.unix(value).format('MMMM Do YYYY, h:mm')
        },
      },
      {
        Header: 'Image',
        accessor: 'image',
        Cell({value}) {
          return <a href={value} target="_blank">{value}</a>
        },
      },
      {
        Header: 'Url',
        accessor: 'url',
        Cell({value}) {
          return <a href={value} target="_blank">{value}</a>
        },
      },
      {
        Header: 'Source',
        accessor: 'source',
        Cell({value}) {
          return value.includes('http') ? <a href={value} target="_blank">{value}</a> : value
        },
      },
    ]
  }, [requesting])

  return (
    <div className="container-fluid p-3">
      <div className="flex justify-between items-center mb-6">
        <h2>Company News 2</h2>
      </div>
      <AirTable
        columns={columns}
        data={companyNews}
        loading={!data && !error}
      />
    </div>
  )
}

export default React.memo(NewsArticle)

import React, { useMemo, useState } from 'react'
import { useRequest } from '../../services/api'
import AirTable from "../ui/table"
import qs from "querystring";
import AutoComplete from "../ui/symbol-dropdown";
import {
  capitalCase,
} from 'change-case'
import Select from 'react-select'

const options = [
  { label: 'Annual', value: 'annual' },
  { label: 'Quarterly', value: 'quarterly' },
]

function Financials() {
  const [frequency, setFrequency] = useState('annual')
  const [search, setSearch] = useState('')
  const query = qs.stringify({
    symbol: search,
    type: 'bs',
    freq: frequency
  })

  const { data, error } = useRequest(`/admin/fin-hub/financials?${query}`)

  const { financials = [], symbol } = data || {}

  const columns = useMemo(() => {
    const cols = []

    if (financials.length === 0) {
      return cols
    }
    const single = financials[0]

    for (const col of Object.keys(single)) {
      cols.push({
        Header: capitalCase(col),
        accessor: col,
      })
    }

    return cols
  }, [financials])

  const onInputChange = (e) => {
    setSearch(e.value)
  }

  return (
    <div className="container-fluid p-3">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h2>Balance sheets: {symbol}</h2>
        <div style={{ width: '320px' }}>
          <label>Symbol:</label>
          <AutoComplete onChange={onInputChange} />
        </div>
        <div style={{ width: '320px' }}>
          <label>Frequency:</label>
          <Select
            options={options}
            styles={{
              menu: provided => ({ ...provided, zIndex: 9999 })
            }}
            defaultValue={{ label: 'Annual', value: 'annual' }}
            onChange={(e) => setFrequency(e.value)}
          />
        </div>
      </div>
      <AirTable
        columns={columns}
        data={financials}
        loading={!data && !error}
      />
    </div>
  )
}

export default React.memo(Financials)

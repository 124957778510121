import AuthHandler from './AuthHandler';

class StrategyService {
  constructor(baseUrl) {
    if (!baseUrl) {
      throw new Error('missing baseUrl parameter');
    }

    this.baseUrl = baseUrl;
    this.authHandler = new AuthHandler(baseUrl); // Initialize AuthHandler
    this.axiosInstance = this.authHandler.axiosInstance; // Use axiosInstance from AuthHandler
  }

  async create(data) {
    try {
      return await this.authHandler.requestWithTokenRefresh(() =>
        this.axiosInstance.put(`${this.baseUrl}/admin/options-auto-signals/create-strategy`, data)
      );
    } catch (error) {
      console.error("Error creating strategy:", error);
      throw error;
    }
  }

  async edit(data, id) {
    try {
      return await this.authHandler.requestWithTokenRefresh(() =>
        this.axiosInstance.post(`${this.baseUrl}/admin/options-auto-signals/edit-strategy/${id}`, data)
      );
    } catch (error) {
      console.error(`Error editing strategy with ID ${id}:`, error);
      throw error;
    }
  }

  async createManualTrade(data) {
    try {
      return await this.authHandler.requestWithTokenRefresh(() =>
        this.axiosInstance.post(`${this.baseUrl}/admin/manual-trades/buy`, data)
      );
    } catch (error) {
      console.error("Error creating manual trade:", error);
      throw error;
    }
  }

  async sellManualTrade(data) {
    try {
      return await this.authHandler.requestWithTokenRefresh(() =>
        this.axiosInstance.post(`${this.baseUrl}/admin/manual-trades/sell`, data)
      );
    } catch (error) {
      console.error("Error selling manual trade:", error);
      throw error;
    }
  }

  async delete(id) {
    try {
      return await this.authHandler.requestWithTokenRefresh(() =>
        this.axiosInstance.delete(`${this.baseUrl}/admin/options-auto-signals/${id}`)
      );
    } catch (error) {
      console.error(`Error deleting strategy with ID ${id}:`, error);
      throw error;
    }
  }

  async exportSymbols(query) {
    try {
      return await this.authHandler.requestWithTokenRefresh(() =>
        this.axiosInstance.get(`${this.baseUrl}/admin/exports/symbols?${query}`, { responseType: 'blob' })
      );
    } catch (error) {
      console.error("Error exporting symbols:", error);
      throw error;
    }
  }

  async exportStrategies(query) {
    try {
      return await this.authHandler.requestWithTokenRefresh(() =>
        this.axiosInstance.get(`${this.baseUrl}/admin/exports/strategies?${query}`, { responseType: 'blob' })
      );
    } catch (error) {
      console.error("Error exporting strategies:", error);
      throw error;
    }
  }

  async exportBestPerformingSymbols(query) {
    try {
      return await this.authHandler.requestWithTokenRefresh(() =>
        this.axiosInstance.get(`${this.baseUrl}/admin/exports/best-performing-symbols?${query}`, { responseType: 'blob' })
      );
    } catch (error) {
      console.error("Error exporting best performing symbols:", error);
      throw error;
    }
  }

  async exportBestPerformingStrategies(query) {
    try {
      return await this.authHandler.requestWithTokenRefresh(() =>
        this.axiosInstance.get(`${this.baseUrl}/admin/exports/best-performing-strategies?${query}`, { responseType: 'blob' })
      );
    } catch (error) {
      console.error("Error exporting best performing strategies:", error);
      throw error;
    }
  }

  async exportRawTrades(query) {
    try {
      return await this.authHandler.requestWithTokenRefresh(() =>
        this.axiosInstance.get(`${this.baseUrl}/admin/exports/raw-trades?${query}`, { responseType: 'blob' })
      );
    } catch (error) {
      console.error("Error exporting raw trades:", error);
      throw error;
    }
  }

  async exportPrices(query) {
    try {
      return await this.authHandler.requestWithTokenRefresh(() =>
        this.axiosInstance.get(`${this.baseUrl}/admin/exports/prices?${query}`)
      );
    } catch (error) {
      console.error("Error exporting prices:", error);
      throw error;
    }
  }
}

export default StrategyService;

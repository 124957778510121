import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes";
import HttpsRedirect from "react-https-redirect";
import { connect } from "react-redux";
import { onStoreUser } from "./store/actions/auth";
import constants from "./common/constants";
import { AuthProvider } from "./context/AuthContext";

class App extends Component {
  componentWillMount() {
    const user = localStorage.getItem(constants.auth.userName);

    if (user) {
      this.props.storeUserToState(JSON.parse(user));
    }
  }

  render() {
    const disabled = process.env.REACT_APP_HTTPS_DISABLED === "true";

    return (
      <HttpsRedirect disabled={disabled}>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </HttpsRedirect>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    storeUserToState: (data) => dispatch(onStoreUser(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

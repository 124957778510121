import AuthService from './authService'
import SymbolService from './symbolService'
import PeriodService from './periodService'
import SectorStockService from './sectorStockService'
import AdministratorService from './administratorService'
import StrategyService from './strategyService'
import SettingsService from './settingsService'
import TradierAccountsService from './tradierAccountsService'

const baseUrl = String(process.env.REACT_APP_BASE_API_URL)

export const authService = new AuthService(baseUrl)
export const symbolService = new SymbolService(baseUrl)
export const periodService = new PeriodService(baseUrl)
export const sectorStockService = new SectorStockService(baseUrl)
export const administratorService = new AdministratorService(baseUrl)
export const strategyService = new StrategyService(baseUrl)
export const settingsService = new SettingsService(baseUrl)
export const tradierAccountsService = new TradierAccountsService(baseUrl)

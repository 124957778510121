import React, { useMemo, useState } from 'react'
import { tradierAccountsService } from '../../services'
import { toast, ToastContainer } from 'react-toastify'
import AirTable from '../ui/table'
import { useRequest } from '../../services/api'

function TradierAccounts() {
  const { data, revalidate } = useRequest(`/admin/tradier-accounts`)

  const accounts = data ? data.accounts : []

  const onStatusToggle = async (id, isActive) => {
    await tradierAccountsService.setAccountStatus(id, Boolean(!isActive).toString())
    await revalidate()
  }

  const columns = useMemo(() => {
    return [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Account',
        accessor: 'account',
      },
      {
        Header: 'Account Type',
        accessor: 'type',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Cash Available',
        accessor: 'tradierAccountBalance.cashAvailable',
      },
      {
        Header: 'Total Cash',
        accessor: 'tradierAccountBalance.totalCash',
      },
      {
        Header: 'Date Created',
        accessor: 'dateCreated',
      },
      {
        Header: 'Option Level',
        accessor: 'optionLevel',
      },
      {
        Header: 'First name',
        accessor: 'user.firstName',
      },
      {
        Header: 'Last name',
        accessor: 'user.lastName',
      },
      {
        Header: 'Email',
        accessor: 'user.email',
      },
      {
        Header: 'Active',
        accessor: 'active',
        Cell({ value }) {
          return value ? 'Yes' : 'No'
        }
      },
      {
        Header: 'User ID',
        accessor: 'userId',
      },
      {
        Header: 'Open PL',
        accessor: 'tradierAccountBalance.openPl',
      },
      {
        Header: 'Close PL',
        accessor: 'tradierAccountBalance.closePl',
      },
      {
        Header: 'Margin Option BP',
        accessor: 'tradierAccountBalance.marginOptionBuyingPower',
      },
      {
        Header: 'PDT Option BP',
        accessor: 'tradierAccountBalance.pdtOptionBuyingPower',
      },
      {
        Header: 'Current PNL',
        accessor: 'currentPnl',
      },
      {
        Header: 'Details',
        accessor: (row) => row,
        Cell({ value: row }) {
          return (
            <div className="d-flex">
              <button
                onClick={async () => {
                  console.log('row', row)
                  await onStatusToggle(row.id, row.active)
                }}
              >
                {row.active ? 'Disable' : 'Enable'}
              </button>
            </div>
          )
        },
      },
    ]
  }, [])

  return (
    <div className="container-fluid p-3">
      <ToastContainer/>
      <h4>Tradier accounts</h4>
      <div className="row mt-5">
        <div className="col-md-12">
          <AirTable
            columns={columns}
            data={accounts}
            loading={!accounts}
          />
        </div>
      </div>
    </div>
  )
}

export default TradierAccounts

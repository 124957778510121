import React, { useMemo, useState } from 'react'
import { useRequest } from '../../../services/api'
import AirTable from '../../ui/table'
import qs from 'querystring'
import AutoComplete from '../../ui/symbol-dropdown'

function IexPeerGroupListPage() {
  const [search, setSearch] = useState('')
  const query = qs.stringify({
    symbol: search,
  })
  const onInputChange = (e) => {
    setSearch(e.value)
  }
  const { data, error } = useRequest(`/admin/iex/peer-groups?${query}`, { revalidateOnFocus: false })
  const [requesting, setRequesting] = useState(false)

  const { peerGroups = [] } = data || {}

  return (
    <div className="container-fluid p-3">
      <div className="d-flex justify-content-between align-items-center mb-5">
        <h2>IEX Peer Groups</h2>
        <div style={{ width: '320px' }}>
          <AutoComplete onChange={onInputChange}/>
        </div>
      </div>
      {peerGroups.map((item, idx) => (
        <div key={idx}>
          <h5>{item}</h5>
        </div>
      ))}
    </div>
  )
}

export default React.memo(IexPeerGroupListPage)

import React, {useMemo, useState} from 'react'
import {useRequest} from '../../services/api'
import AirTable from '../ui/table'
import moment from 'moment'
import qs from 'querystring'
import AutoComplete from '../ui/symbol-dropdown'

function StockCandles() {
  const [search, setSearch] = useState('')
  const query = qs.stringify({
    symbol: search,
  })
  const onInputChange = (e) => {
    setSearch(e.value)
  }
  const {data, error} = useRequest(`/admin/fin-hub/stock-candles?${query}`)
  const [requesting, setRequesting] = useState(false)

  const {stockCandles = []} = data || {}

  const columns = useMemo(() => {
    return [
      {
        Header: 'Current Price',
        accessor: 'currentPrice',
      },
      {
        Header: 'High Price',
        accessor: 'highPrice',
      },
      {
        Header: 'Low Price',
        accessor: 'lowPrice',
      },
      {
        Header: 'Open Price',
        accessor: 'openPrice',
      },
      {
        Header: 'Volume',
        accessor: 'volume',
      },
      {
        Header: 'Time',
        accessor: 'time',
        Cell({value}) {
          return moment(value).format('MMMM Do YYYY')
        },
      },
    ]
  }, [requesting])

  return (
    <div className="container-fluid p-3">
      <div className="d-flex justify-content-between align-items-center mb-6">
        <h2>Stock Candles</h2>
        <div style={{width: '320px'}}>
          <AutoComplete onChange={onInputChange}/>
        </div>
      </div>
      <AirTable
        columns={columns}
        data={stockCandles}
        loading={!data && !error}
      />
    </div>
  )
}

export default React.memo(StockCandles)

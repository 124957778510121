import AuthHandler from "./AuthHandler";

export default class AdministratorService {
  constructor(baseUrl) {
    if (!baseUrl) {
      throw new Error("missing baseUrl parameter");
    }

    this.baseUrl = baseUrl;
    this.authHandler = new AuthHandler(baseUrl); // Initialize AuthHandler
    this.axiosInstance = this.authHandler.axiosInstance; // Use axiosInstance from AuthHandler
  }

  async create(data) {
    try {
      return await this.authHandler.requestWithTokenRefresh(() =>
        this.axiosInstance.post(`${this.baseUrl}/admin/administrators`, data)
      );
    } catch (error) {
      console.error("Error creating administrator:", error);
      throw error;
    }
  }

  async delete(id) {
    try {
      return await this.authHandler.requestWithTokenRefresh(() =>
        this.axiosInstance.delete(`${this.baseUrl}/admin/administrators/${id}`)
      );
    } catch (error) {
      console.error(`Error deleting administrator with ID ${id}:`, error);
      throw error;
    }
  }

  async edit(data, id) {
    try {
      return await this.authHandler.requestWithTokenRefresh(() =>
        this.axiosInstance.put(
          `${this.baseUrl}/admin/administrators/${id}`,
          data
        )
      );
    } catch (error) {
      console.error(`Error editing administrator with ID ${id}:`, error);
      throw error;
    }
  }
}

import React, { useMemo, useState } from 'react'
import { useRequest } from '../../services/api'
import AirTable from '../ui/table'
import moment from "moment";
import qs from "querystring";
import AutoComplete from "../ui/symbol-dropdown";

function PriceTarget() {
  const [search, setSearch] = useState('')
  const query = qs.stringify({
    symbol: search,
  })
  const { data, error } = useRequest(`/admin/fin-hub/price-target?${query}`)
  const [requesting, setRequesting] = useState(false)

  const columns = useMemo(() => {
    return [
      {
        Header: 'Symbol',
        accessor: 'symbol',
      },
      {
        Header: 'Target High',
        accessor: 'targetHigh',
      },
      {
        Header: 'Target Low',
        accessor: 'targetLow',
      },
      {
        Header: 'Target Mean',
        accessor: 'targetMean',
      },
      {
        Header: 'Target Median',
        accessor: 'targetMedian',
      },
      {
        Header: 'Last Updated',
        accessor: 'lastUpdated',
        Cell({ value }) {
          return moment(value).format('MMMM Do YYYY, h:mm')
        },
      },
    ]
  }, [requesting])

  const onInputChange = (e) => {
    setSearch(e.value)
  }

  return (
    <div className="container-fluid p-3">
      <div className="d-flex justify-content-between align-items-center mb-6">
        <h2>Price Target</h2>
        <div style={{width: '320px'}}>
          <AutoComplete onChange={onInputChange}/>
        </div>
      </div>
      <AirTable
        columns={columns}
        data={data}
        loading={!data && !error}
      />
    </div>
  )
}

export default React.memo(PriceTarget)

import React, { useMemo, useState } from 'react'
import { useRequest } from '../../../services/api'
import AirTable from '../../ui/table'
import qs from 'querystring'
import AutoComplete from '../../ui/symbol-dropdown'
import moment from 'moment'

function IexFundOwnershipListPage() {
  const [search, setSearch] = useState('')
  const query = qs.stringify({
    symbol: search,
  })
  const onInputChange = (e) => {
    setSearch(e.value)
  }
  const { data, error } = useRequest(`/admin/iex/fund-ownership?${query}`, { revalidateOnFocus: false })
  console.log(data)
  const [requesting, setRequesting] = useState(false)

  const { fundOwnership = [] } = data || {}

  const columns = useMemo(() => {
    return [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Symbol',
        accessor: 'symbol',
      },
      {
        Header: 'Entity Proper Name',
        accessor: 'entityProperName',
      },
      {
        Header: 'Adj Holding',
        accessor: 'adjHolding',
      },
      {
        Header: 'Adj Mv',
        accessor: 'adjMv',
      },
      {
        Header: 'Reported Holding',
        accessor: 'reportedHolding',
      },
      {
        Header: 'Reported Mv',
        accessor: 'reportedMv',
      },
      {
        Header: 'Report Date',
        accessor: 'report_date',
        Cell({ value }) {
          return value ? moment.unix(value / 1000).format('MM-DD-YYYY HH:mm') : ""
        }
      },
      {
        Header: 'Date',
        accessor: 'date',
        Cell({ value }) {
          return value ? moment.unix(value / 1000).format('MM-DD-YYYY HH:mm') : ""
        }
      },
      {
        Header: 'Updated',
        accessor: 'updated',
        Cell({ value }) {
          return value ? moment.unix(value / 1000).format('MM-DD-YYYY HH:mm') : ""
        }
      },
    ]
  }, [requesting])

  return (
    <div className="container-fluid p-3">
      <div className="d-flex justify-content-between align-items-center mb-6">
        <h2>IEX Fund Ownership</h2>
        <div style={{ width: '320px' }}>
          <AutoComplete onChange={onInputChange}/>
        </div>
      </div>
      <AirTable
        columns={columns}
        data={fundOwnership}
        loading={!data && !error}
      />
    </div>
  )
}

export default React.memo(IexFundOwnershipListPage)

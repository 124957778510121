import React, { useCallback } from 'react'
import AdministratorForm from './AdministratorForm'
import { administratorService } from '../../services'
import { toast, ToastContainer } from 'react-toastify'

function AdministratorsCreatePage({ history }) {
  const onCreateAdministrator = useCallback(
    async (data, form) => {
      console.log(data)
      try {
        await administratorService.create(data)
        history.push('/administrators')
        toast.success('New administrator created')
      } catch (err) {
        toast.error(err.message)
        throw err
      }
    },
    [history],
  )
  return (
    <div className="p-3">
      <ToastContainer/>
      <div className="row">
        <div className="col-md-12">
          <h3>Create new administrator</h3>
        </div>
        <div className="col-lg-8 col-md-12">
          <AdministratorForm onSave={onCreateAdministrator}/>
        </div>
      </div>
    </div>
  )
}

export default AdministratorsCreatePage
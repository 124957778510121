import React, { useMemo, useState } from 'react'
import { useRequest } from '../../services/api'
import AirTable from '../ui/table'

function StockRequestsListPage() {
  const [page, setPage] = useState(1)
  const { data, error } = useRequest(`/admin/stock-requests?page=${page}&pageSize=50`)
  const [requesting, setRequesting] = useState(false)

  const { stockRequests = [], totalItems, totalPages } = data || {}

  const columns = useMemo(() => {
    return [
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Symbol',
        accessor: 'symbol',
      },
    ]
  }, [requesting])

  return (
    <div className="container-fluid p-3">
      <div className="flex justify-between items-center mb-6">
        <h2>Stock Requests</h2>
      </div>
      <AirTable
        columns={columns}
        data={stockRequests}
        loading={!data && !error}
        totalItem={totalItems}
        totalPage={totalPages}
        onChangePageIndex={setPage}
        pagging
      />
    </div>
  )
}

export default React.memo(StockRequestsListPage)

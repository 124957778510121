import React, { useMemo, useState } from 'react'
import AirTable from '../ui/table'
import { useRequest } from '../../services/api'
import _ from 'lodash'
import moment from 'moment'
import DatePicker from '../datepicker'

function OptionsTrackRecordPage() {
  const [date, setDate] = useState('')

  const { data } = useRequest(`/admin/fin-hub/tos-aggregates?date=${date ? moment.utc(date).format('YYYY-MM-DD') : ''}`, { refreshInterval: 30000 })

  const { items = [], totalItems, totalPages } = data || {}

  const columns = useMemo(() => {
    return [
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Options #',
        accessor: 'totalNumber',
      },
      {
        Header: '15m Green %',
        accessor: 'fifteenMinGreen',
      },
      {
        Header: '15m Red %',
        accessor: 'fifteenMinRed',
      },
      {
        Header: '15m No Change %',
        accessor: 'fifteenMinNoChange',
      },
      {
        Header: '30m Green %',
        accessor: 'thirtyMinGreen',
      },
      {
        Header: '30m Red %',
        accessor: 'thirtyMinRed',
      },
      {
        Header: '30m No Change %',
        accessor: 'thirtyMinNoChange',
      },
      {
        Header: '1h Green %',
        accessor: 'oneHourGreen',
      },
      {
        Header: '1h Red %',
        accessor: 'oneHourRed',
      },
      {
        Header: '1h No Change %',
        accessor: 'oneHourNoChange',
      },
      {
        Header: '2h Green %',
        accessor: 'twoHoursGreen',
      },
      {
        Header: '2h Red %',
        accessor: 'twoHoursRed',
      },
      {
        Header: '2h No Change %',
        accessor: 'twoHoursNoChange',
      },
      {
        Header: '4h Green %',
        accessor: 'fourHoursGreen',
      },
      {
        Header: '4h Red %',
        accessor: 'fourHoursRed',
      },
      {
        Header: '4h No Change %',
        accessor: 'fourHoursNoChange',
      },
      {
        Header: '1d Green %',
        accessor: 'oneDayGreen',
      },
      {
        Header: '1d Red %',
        accessor: 'oneDayRed',
      },
      {
        Header: '1d No Change %',
        accessor: 'oneDayNoChange',
      },
      {
        Header: '2d Green %',
        accessor: 'twoDaysGreen',
      },
      {
        Header: '2d Red %',
        accessor: 'twoDaysRed',
      },
      {
        Header: '2d No Change',
        accessor: 'twoDaysNoChange',
      },
      {
        Header: '15min avg return %',
        accessor: 'fifteenMinAvg',
      },
      {
        Header: '30min avg return %',
        accessor: 'thirtyMinAvg',
      },
      {
        Header: '1h avg return %',
        accessor: 'oneHourAvg',
      },
      {
        Header: '2h avg return %',
        accessor: 'twoHoursAvg',
      },
      {
        Header: '1d avg return %',
        accessor: 'oneDayAvg',
      },
      {
        Header: '2d avg return %',
        accessor: 'twoDayAvg',
      },
    ]
  }, [items])

  return (
    <div className="container-fluid p-3">
      <div className="row">
        <div className="w-full">
          <label className="font-bold text-sm">Select date</label>
          <div className="d-flex align-items-center w-100">
            <DatePicker
              className="border w-100 py-2"
              value={date && moment(date)}
              onChange={(e) => {
                setDate(e)
              }}
            />
            <i
              className="fas fa-times ml-3"
              onClick={() => setDate('')}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="d-flex w-100 justify-content-between align-items-center mb-2">
            <h2>TOS aggregates</h2>
          </div>
          <AirTable
            columns={columns}
            data={items}
            loading={_.isEmpty(data)}
            totalItem={totalItems}
            totalPage={totalPages}
            pagging
          />
        </div>
      </div>
    </div>
  )
}

export default React.memo(OptionsTrackRecordPage)

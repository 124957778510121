// src/contexts/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";

// Create Context
const AuthContext = createContext();

// Custom hook for accessing the context
export const useAuth = () => useContext(AuthContext);

// Auth Provider Component
export const AuthProvider = ({ children }) => {
  const user = useSelector((state) => state.auth.user);
  const [currentUser, setCurrentUser] = useState(user);

  // useEffect(() => {
  //   setCurrentUser(user);

  //   const currentPath = window.location.pathname;
  //   if (user) {
  //     if (user.otpEnabled && currentPath !== "/verify-otp") {
  //       window.location.href = "/verify-otp"; // Assume this is the path for OTP verification
  //     } else if (!user.otpEnabled && currentPath !== "/profile") {
  //       window.location.href = "/profile"; // The user's profile page
  //     }
  //   }
  // }, [user, window.location.pathname]);

  return (
    <AuthContext.Provider value={{ currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};

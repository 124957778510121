import React, { useMemo } from 'react'
import { useRequest } from '../../services/api'
import AirTable from "../ui/table"
import { capitalCase, } from 'change-case'

function SpacsLatestFilings() {
  const {data, error} = useRequest('/admin/spacs/latest-filings')

  const {latestFilings = []} = data || {}

  const columns = useMemo(() => {
   return [
     {
       Header: 'ID',
       accessor: 'id',
     },
     {
       Header: 'Title',
       accessor: 'title',
     },
     {
       Header: 'Accessionnum',
       accessor: 'accessionnum',
     },
     {
       Header: 'Cik',
       accessor: 'cik',
     },
     {
       Header: 'Edgar Last Updated',
       accessor: 'edgarlastupdated',
     },
     {
       Header: 'File Size',
       accessor: 'filesize',
     },
     {
       Header: 'Filing Date',
       accessor: 'filingdate',
     },
     {
       Header: 'Filing Number',
       accessor: 'filingnumber',
     },
     {
       Header: 'Filing Type',
       accessor: 'filingtype',
     },
     {
       Header: 'Link',
       accessor: 'link',
       Cell({value}) {
         return (
           <a href={value} target="_blank">{value}</a>
         )
       }
     },
   ]
  }, [latestFilings])

  return (
    <div className="container-fluid p-3">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h2>SPAC's Latest Filings</h2>
      </div>
      <AirTable
        columns={columns}
        data={latestFilings}
        loading={!data && !error}
      />
    </div>
  )
}

export default React.memo(SpacsLatestFilings)

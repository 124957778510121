import React from 'react'
import TooltipTrigger from 'react-popper-tooltip'
import 'react-popper-tooltip/dist/styles.css'

const modifiers = [
  {
    name: 'offset',
    enabled: true,
    options: {
      offset: [0, 4],
    },
  },
]

const Trigger = (children: React.ReactNode) => ({
                                                  triggerRef,
                                                  getTriggerProps,
                                                }: ChildrenArg) => (
  <span
    {...getTriggerProps({
      ref: triggerRef,
    })}
  >
    {children}
  </span>
)

const Tooltip = (tooltip: React.ReactNode, hideArrow?: boolean) => ({
                                                                      arrowRef,
                                                                      tooltipRef,
                                                                      getArrowProps,
                                                                      getTooltipProps,
                                                                      placement,
                                                                    }: TooltipArg) => (
  <div
    {...getTooltipProps({
      className: 'tooltip-container',
      ref: tooltipRef,
    })}
  >
    {!hideArrow && (
      <div
        {...getArrowProps({
          className: 'tooltip-arrow',
          'data-placement': placement,
          ref: arrowRef,
        })}
      />
    )}
    {tooltip}
  </div>
)

const Popover = React.memo(({ tooltip, children, hideArrow, ...props }) => (
  <TooltipTrigger
    {...props}
    modifiers={modifiers}
    tooltip={Tooltip(tooltip, hideArrow)}
  >
    {Trigger(children)}
  </TooltipTrigger>
))

export default Popover

export class StateContainer extends React.PureComponent {
  state: StateContainerState = {
    on: false,
  }

  render() {
    return this.props.children({
      on: this.state.on,
      set: this.set,
      toggle: this.toggle,
    })
  }

  set = (on: boolean) => this.setState({ on })
  toggle = () => this.setState((prevState) => ({ on: !prevState.on }))
}
import AuthHandler from './AuthHandler';

class SettingsService {
  constructor(baseUrl) {
    if (!baseUrl) {
      throw new Error('missing baseUrl parameter');
    }

    this.baseUrl = baseUrl;
    this.authHandler = new AuthHandler(baseUrl); // Initialize AuthHandler
    this.axiosInstance = this.authHandler.axiosInstance; // Use axiosInstance from AuthHandler
  }

  async setInstanceStatus(data) {
    try {
      return await this.authHandler.requestWithTokenRefresh(() =>
        this.axiosInstance.post(`${this.baseUrl}/admin/settings/status`, data)
      );
    } catch (error) {
      console.error("Error setting instance status:", error);
      throw error;
    }
  }

  async forceCloseAllTrades() {
    try {
      return await this.authHandler.requestWithTokenRefresh(() =>
        this.axiosInstance.post(`${this.baseUrl}/admin/settings/force-close-all`, {})
      );
    } catch (error) {
      console.error("Error forcing close all trades:", error);
      throw error;
    }
  }
}

export default SettingsService;

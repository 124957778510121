import AuthHandler from "./AuthHandler";

class TradierAccountsService {
  constructor(baseUrl) {
    if (!baseUrl) {
      throw new Error("missing baseUrl parameter");
    }

    this.baseUrl = baseUrl;
    this.authHandler = new AuthHandler(baseUrl); // Initialize AuthHandler
    this.axiosInstance = this.authHandler.axiosInstance; // Use axiosInstance from AuthHandler
  }

  async setAccountStatus(id, active) {
    try {
      return await this.authHandler.requestWithTokenRefresh(() =>
        this.axiosInstance.post(
          `${this.baseUrl}/admin/tradier-accounts/${id}/status`,
          { active }
        )
      );
    } catch (error) {
      console.error(`Error setting account status for ID ${id}:`, error);
      throw error;
    }
  }

  async disableAll() {
    try {
      return await this.authHandler.requestWithTokenRefresh(() =>
        this.axiosInstance.post(
          `${this.baseUrl}/admin/tradier-accounts/disable-all`,
          {}
        )
      );
    } catch (error) {
      console.error("Error disabling all accounts:", error);
      throw error;
    }
  }

  async enableAll() {
    try {
      return await this.authHandler.requestWithTokenRefresh(() =>
        this.axiosInstance.post(
          `${this.baseUrl}/admin/tradier-accounts/enable-all`,
          {}
        )
      );
    } catch (error) {
      console.error("Error enabling all accounts:", error);
      throw error;
    }
  }
}

export default TradierAccountsService;

import AuthHandler from "./AuthHandler";

class AuthService {
  constructor(baseUrl) {
    if (!baseUrl) {
      throw new Error("missing baseUrl parameter");
    }

    this.baseUrl = baseUrl;
    this.authHandler = new AuthHandler(baseUrl); // Initialize AuthHandler
    this.axiosInstance = this.authHandler.axiosInstance; // Use axiosInstance from AuthHandler
  }

  async login(data) {
    try {
      return await this.axiosInstance.post(`${this.baseUrl}/admin/administrators/login`, data);
    } catch (error) {
      console.error("Error logging in:", error);
      throw error;
    }
  }

  async generateOtp(data) {
    try {
      return await this.authHandler.requestWithTokenRefresh(() =>
        this.axiosInstance.post(`${this.baseUrl}/api/auth/otp/generate`, data)
      );
    } catch (error) {
      console.error("Error generating OTP:", error);
      throw error;
    }
  }

  async verifyOtp(data) {
    try {
      return await this.authHandler.requestWithTokenRefresh(() =>
        this.axiosInstance.post(`${this.baseUrl}/api/auth/otp/verify`, data)
      );
    } catch (error) {
      console.error("Error verifying OTP:", error);
      throw error;
    }
  }

  async validateOtp(data) {
    try {
      return await this.authHandler.requestWithTokenRefresh(() =>
        this.axiosInstance.post(`${this.baseUrl}/api/auth/otp/validate`, data)
      );
    } catch (error) {
      console.error("Error validating OTP:", error);
      throw error;
    }
  }

  async login2(data) {
    try {
      return await this.authHandler.requestWithTokenRefresh(() =>
        this.axiosInstance.post(`${this.baseUrl}/api/auth/login`, data)
      );
    } catch (error) {
      console.error("Error logging in (version 2):", error);
      throw error;
    }
  }

  async disableOtp(data) {
    try {
      return await this.authHandler.requestWithTokenRefresh(() =>
        this.axiosInstance.post(`${this.baseUrl}/api/auth/otp/disable`, data)
      );
    } catch (error) {
      console.error("Error disabling OTP:", error);
      throw error;
    }
  }
}

export default AuthService;

import React, { useEffect, useMemo, useState } from 'react'
import AirTable from "../ui/table"
import { ToastContainer } from 'react-toastify'
import qs from 'querystring'
import axios from 'axios'
import { withRouter } from 'react-router'
import moment from 'moment'

function TradeLogsListPage({ location }) {
  const locationSearch = qs.parse(location.search)
  const [data, setData] = useState([])
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(true)
  const [from, setFrom] = useState('')
  const [to, setTo] = useState('')
  const [type, setType] = useState('')
  const [processName, setProcessName] = useState('')
  const [strategyName, setStrategyName] = useState('')
  const [sessionId, setSessionId] = useState('')
  const [symbol, setSymbol] = useState('')
  const [query, setQuery] = useState(qs.stringify({
    pageSize: 500,
    from: locationSearch.from,
    to: locationSearch.to,
    sessionId: locationSearch.sessionId,
    symbol: locationSearch.symbol,
    type: locationSearch.type,
    processName: locationSearch.processName,
    strategyName: locationSearch.strategyName,
  }))

  const handleSearch = () => {
    const obj = qs.stringify({
      pageSize: 500,
      from: from ? moment(from).format('YYYY-MM-DD HH:mm') : '',
      to: to ? moment(to).format('YYYY-MM-DD HH:mm') : '',
      sessionId,
      symbol,
      type,
      processName,
      strategyName,
    })
    setQuery(obj)

    setTimeout(() => {
      window.history.replaceState(null, 'Trade Logs', `/trade-logs?${obj}`);
    }, 500)
  }

  useEffect(() => {
    if (locationSearch.from) {
      setFrom(locationSearch.from)
    }
    if (locationSearch.page) {
      setPage(locationSearch.page)
    }
    if (locationSearch.to) {
      setTo(locationSearch.to)
    }
    if (locationSearch.sessionId) {
      setSessionId(locationSearch.sessionId)
    }
    if (locationSearch.symbol) {
      setSymbol(locationSearch.symbol)
    }
    if (locationSearch.type) {
      setType(locationSearch.type)
    }
    if (locationSearch.type) {
      setProcessName(locationSearch.processName)
    }
    if (locationSearch.type) {
      setStrategyName(locationSearch.strategyName)
    }
  }, [location, locationSearch.from, locationSearch.page, locationSearch.processName, locationSearch.sessionId, locationSearch.strategyName, locationSearch.symbol, locationSearch.to, locationSearch.type])

  console.log(page)

  useEffect(() => {
    setLoading(true)
    const delayDebounceFn = setTimeout(() => {
      axios.get(`https://api.trillions.cc/admin/trade-logs?${query}&page=${page}`).then((res) => {
        setData(res.data)
        setLoading(false)
      }).finally(() => {
        setLoading(false)
      })
    }, 500)
    return () => clearTimeout(delayDebounceFn)

  }, [page, location.search, query])

  const { logs = [], totalItems, totalPages } = data || {}

  const columns = useMemo(() => {
    return [
      // {
      //   Header: 'Id',
      //   accessor: 'id',
      // },
      {
        Header: 'Symbol',
        accessor: 'symbol',
      },
      {
        Header: 'Last Candles',
        accessor: 'lastCandles',
        Cell({ value }) {
          return <ul style={{ minWidth: value.length > 0 ? '600px' : 'unset' }}>
            {(value || []).map(i => (
              <li>{i}</li>
            ))}
          </ul>
        }
      },
      {
        Header: 'Strategy',
        accessor: 'strategy',
      },
      {
        Header: 'Session ID',
        accessor: 'sessionId',
      },
      {
        Header: 'Err',
        accessor: 'err',
      },
      {
        Header: 'Order',
        accessor: 'order',
      },
      {
        Header: 'Order Result',
        accessor: 'orderResult',
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
      },
      {
        Header: 'Process Name',
        accessor: 'processName',
      },
      {
        Header: 'Ticker',
        accessor: 'ticker',
      },
      {
        Header: 'Message',
        accessor: 'message',
      },
      {
        Header: 'Indicator',
        accessor: 'indicator',
      },
      {
        Header: 'Indicator Data',
        accessor: 'indicatorData',
        Cell({ value }) {
          return <ul style={{ minWidth: value.length > 0 ? '600px' : 'unset' }}>
            {(value || []).map(i => (
              <li>{i}</li>
            ))}
          </ul>
        }
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Options Contract',
        accessor: 'optionsContract',
      },
      {
        Header: 'Thread ID',
        accessor: 'threadId',
      },
      {
        Header: 'Err Str',
        accessor: 'errStr',
      },
      {
        Header: 'Signal',
        accessor: 'signal',
      },
    ]
  }, [])

  return (
    <div className="p-3">
      <ToastContainer />
      <div className="row">
        <div className="col-md-12 pb-3">
          <div className="d-flex justify-content-between align-items-center">
            <h3>Trade Logs</h3>
          </div>
        </div>
        <div className="col-md-12">
          <div className="row align-items-center">
            <div className="col-md-6">
              <label>From</label>
              <div className="d-flex align-items-center">
                <input
                  className="form-control rounded mb-2"
                  value={from}
                  onChange={(e) => setFrom(e.target.value)}
                  type="datetime-local"
                />
                <button className="mb-2 btn btn-primary" onClick={() => setFrom('')}>x</button>
              </div>
            </div>
            <div className="col-md-6">
              <label>To</label>
              <div className="d-flex align-items-center">
                <input
                  className="form-control rounded mb-2"
                  value={to}
                  type="datetime-local"
                  onChange={(e) => setTo(e.target.value)}
                />
                <button className="mb-2 btn btn-primary" onClick={() => setTo('')}>x</button>
              </div>
            </div>
            <div className="col-md-6">
              <label>Session ID</label>
              <div className="d-flex align-items-center">
                <input
                  className="form-control rounded mb-2"
                  value={sessionId}
                  type="text"
                  onChange={(e) => setSessionId(e.target.value)}
                />
                <button className="mb-2 btn btn-primary" onClick={() => setSessionId('')}>x</button>
              </div>
            </div>
            <div className="col-md-6">
              <label>Type</label>
              <div className="d-flex align-items-center">
                <select
                  className="form-control rounded mb-2"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                >
                  <option>{' '}</option>
                  <option value="error">Error</option>
                  <option value="info">Info</option>
                </select>
                <button className="mb-2 btn btn-primary" onClick={() => setType('')}>x</button>
              </div>
            </div>
            <div className="col-md-6">
              <label>Process</label>
              <div className="d-flex align-items-center">
                <select
                  className="form-control rounded mb-2"
                  value={processName}
                  onChange={(e) => setProcessName(e.target.value)}
                >
                  <option>{' '}</option>
                  <option value="option-scanner">Option scanner</option>
                  <option value="opener">Trade opener</option>
                  <option value="pt-sl-closer">PT SL closer</option>
                  <option value="dynamic-closer">Dynamic closer</option>
                  <option value="force-closer">Force closerr</option>
                </select>
                <button className="mb-2 btn btn-primary" onClick={() => setProcessName('')}>x</button>
              </div>
            </div>
            <div className="col-md-6">
              <label>Symbol</label>
              <div className="d-flex align-items-center">
                <input
                  className="form-control rounded mb-2"
                  value={symbol}
                  type="text"
                  onChange={(e) => setSymbol(e.target.value)}
                />
                <button className="mb-2 btn btn-primary" onClick={() => setSymbol('')}>x</button>
              </div>
            </div>
            <div className="col-md-6">
              <label>Strategy name</label>
              <div className="d-flex align-items-center">
                <input
                  className="form-control rounded mb-2"
                  value={strategyName}
                  type="text"
                  onChange={(e) => setStrategyName(e.target.value)}
                />
                <button className="mb-2 btn btn-primary" onClick={() => setStrategyName('')}>x</button>
              </div>
            </div>
            <div className="col-md-6">
              <button
                className="btn btn-primary px-5"
                onClick={() => handleSearch()}
                disabled={loading}
              >
                {loading ? 'Searching...' : 'Search'}
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <AirTable
            columns={columns}
            data={loading ? [] : logs}
            loading={loading}
            totalItem={totalItems}
            totalPage={totalPages}
            onChangePageIndex={setPage}
            pagging
          />
        </div>
      </div>
    </div>
  )
}

export default React.memo(withRouter(TradeLogsListPage))

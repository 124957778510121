import React, {useMemo, useState} from 'react'
import {useRequest} from '../../services/api'
import moment from "moment"
import AirTable from "../ui/table"
import qs from 'querystring'
import AutoComplete from "../ui/symbol-dropdown";

function FundOwnership() {
  const [search, setSearch] = useState('')
  const query = qs.stringify({
    symbol: search,
  })

  const {data, error} = useRequest(`/admin/fin-hub/fund-ownership?${query}`)

  const {symbol, ownership} = data?.fundOwnership || {}

  const onInputChange = (e) => {
    setSearch(e.value)
  }

  const getTotalShare = () => {
    if (!ownership) {
      return 0
    }

    let total = 0

    for (const own of ownership) {
      total += own.share
    }

    return total
  }

  const columns = useMemo(() => {
    return [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Portfolio Percent',
        accessor: 'portfolioPercent',
      },
      {
        Header: 'Share',
        accessor: 'share',
      },
      {
        Header: 'Change',
        accessor: 'change',
      },
      {
        Header: 'Filling Date',
        accessor: 'filingDate',
        Cell({value}) {
          return moment(value).format('MMMM Do YYYY')
        },
      }
    ]
  }, [])

  return (
    <div className="container-fluid p-3">
      <div className="d-flex justify-content-between align-items-center mb-6">
        <div>
          <h2>Fund Ownership: {symbol}</h2>
          <h3>Total share: {getTotalShare()}</h3>
        </div>
        <div style={{ width: '320px' }}>
          <AutoComplete onChange={onInputChange} />
        </div>
      </div>
      <AirTable
        columns={columns}
        data={ownership}
        loading={!data && !error}
      />
    </div>
  )
}

export default React.memo(FundOwnership)

import React, { useCallback } from 'react'
import { useRequest } from '../../services/api'
import AdministratorForm from './AdministratorForm'
import { administratorService } from '../../services'
import { useHistory } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'

function AdministratorEdit({ match: { params: { id } } }) {
  const history = useHistory()

  const { data, revalidate } = useRequest(`/admin/administrators/${id}`, {
    refreshInterval: 0,
    compare(a, b) {
      return a && b && a.updatedAt === b.updatedAt
    },
  })

  const onUpdateAdministrator = useCallback(async (data, form) => {
    try {
      await administratorService.edit(data, id)
      toast.success('Updated')
      await revalidate()
      history.push('/administrators')
    } catch (err) {
      console.error(err)
      toast.error(err.message)
      throw err
    }
  }, [])

  console.log(data)

  return (
    <div>
      <ToastContainer/>
      {data &&
      <div className="p-3">
        <div className="row">
          <div className="col-md-12">
            <h3>Update Administrator</h3>
          </div>
          <div className="col-lg-8 col-md-12">
            <AdministratorForm data={data} onSave={onUpdateAdministrator}/>
          </div>
        </div>
      </div>
      }
    </div>
  )
}

export default AdministratorEdit
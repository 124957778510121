import React, { Component, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Route, Switch, withRouter, useHistory } from "react-router-dom";
import constants from "./common/constants";
import AdministratorsCreatePage from "./components/administrators/create";
import AdministratorEdit from "./components/administrators/edit";
import AdministratorsListPage from "./components/administrators/list";
import AnalyticsBigQueryListPage from "./components/analytics-big-query/list";
import AnalyticsListPage from "./components/analytics/list";
import AphaOptionsDifferences from "./components/apha-options-differences/list";
import BalanceSheetDifferences from "./components/balance-sheet-differences/list";
import BalanceSheets from "./components/balance-sheets/list";
import BarchartOptions from "./components/barchart-options/list";
import BasicFinancials from "./components/basic-financials/list";
import BenzingaRatings from "./components/benzinga-ratings/list";
import CashflowStatements from "./components/cashflow-statements/list";
import CompanyNews from "./components/company-news/list";
import DashboardAllVisitsListPage from "./components/dashboard-all-visits/list";
import DashboardBouncesByPageListPage from "./components/dashboard-bounces-by-page/list";
import DashboardConversionsByLastTouchListPage from "./components/dashboard-conversions-by-last-touch/list";
import DashboardConversionsByReferrerListPage from "./components/dashboard-conversions-by-referrer/list";
import DashboardConversionsByUtmsListPage from "./components/dashboard-conversions-by-utms/list";
import DashboardConversionsByPageListPage from "./components/dashboard-conversions-page/list";
import DashboardConversionsListPage from "./components/dashboard-conversions/list";
import DashboardRepeatedVisitsListPage from "./components/dashboard-repeated-visits/list";
import DashboardTrafficByPageListPage from "./components/dashboard-traffic-by-page/list";
import DashboardTrafficByReferrerListPage from "./components/dashboard-traffic-by-referrer/list";
import DashboardTrafficBySourceListPage from "./components/dashboard-traffic-by-source/list";
import AnalyticsDevListPage from "./components/dev-analytics/list";
import EarningEstimatesListPage from "./components/earning-estimates/list";
import EarningsCalendar from "./components/earnings-calendar/list";
import EarningsEstimates from "./components/earnings-estimates/list";
import EarningsNetIncomeGrowth from "./components/earnings-net-income-growth/list";
import EtfsHoldings from "./components/etfs-holdings/list";
import ExportsPage from "./components/exports";
import FinancialsReported from "./components/financials-reported/list";
import FundOwnership from "./components/funder-ownership/list";
import IexFundOwnershipListPage from "./components/iex/fund-ownership/list";
import IexPeerGroupListPage from "./components/iex/peer-groups/list";
import IexRecommendationsAndPriceTargetListPage from "./components/iex/recommendations-and-price-targets/list";
import IncomeStatementDifferences from "./components/income-statements-differences";
import IncomeStatements from "./components/income-statements/list";
import IpoCalendar from "./components/ipo-calendar/list";
import LastPriceUpdates from "./components/last-price-updates/list";
import Login from "./components/login/login";
import ManualTradesPage from "./components/manual-trades";
import MonthlyPriceChangeListPage from "./components/monthly-price-change/list";
import NewsArticle from "./components/news-article/list";
import NewsSentiment from "./components/news-sentiment/list";
import News from "./components/news/list";
import OptionChainsPage from "./components/option-chains/list";
import OptionsTrackRecordPage from "./components/options-track-record/list";
import PressReleases from "./components/press-releases/list";
import PriceTarget from "./components/price-target/list";
import Profile from "./components/profile";
import Quotes from "./components/quotes/list";
import RecommendationTrends from "./components/recommendation-trends/list";
import RevenueEstimates from "./components/revenue-estimates/list";
import SalesRevenueGrowth from "./components/sales-revenue-growth/list";
import SearchLogsListPage from "./components/search-logs/list";
import SectorStocksCreatePage from "./components/sector-stocks/create";
import SectorStocksEditPage from "./components/sector-stocks/edit";
import SectorStocksListPage from "./components/sector-stocks/list";
import SettingsPage from "./components/settings";
import SignalStrategiesPage from "./components/signal-strategies";
import SpacsAllCompanies from "./components/spacs-all-companies";
import SpacsAllTeams from "./components/spacs-all-teams";
import SpacsLatestFilings from "./components/spacs-latest-filings";
import StockCandles from "./components/stock-candles/list";
import StockDividends from "./components/stock-dividends/list";
import StockNewsArticles from "./components/stock-news-articles/list";
import StockOptions from "./components/stock-options/list";
import StockRequestsListPage from "./components/stock-requests/list";
import StockSymbols from "./components/stock-symbols/list";
import TOSAggregates from "./components/tos-aggregates/list";
import TradeLogsListPage from "./components/trade-logs/list";
import Trades from "./components/trades-grouped/list";
import TradierAccounts from "./components/tradier-accounts";
import UpgradeDowngrade from "./components/upgrade-downgrade/list";
import UsersListPage from "./components/users/list";
import Footer from "./ui/footer";
import Header from "./ui/header";
import Navigation from "./ui/navigation";
import { AuthProvider } from "./context/AuthContext";
import ProfileValidateOtp from "./components/profile-validate-otp";

class Routes extends Component {
  render() {
    const user = localStorage.getItem(constants.auth.userName);
    const parsed = JSON.parse(user);

    console.log(parsed);

    if (user) {
      if (parsed?.otpEnabled && !parsed?.otpVerified) {
        return (
          <Switch>
            <Route component={OtpVerifyContainer} />
          </Switch>
        );
      } else {
        return (
          <Switch>
            <Route component={DefaultContainer} />
          </Switch>
        );
      }
    }

    return (
      <Switch>
        <Route component={LoginContainer} />
      </Switch>
    );
  }
}

const LoginContainer = () => (
  <div className="container-fluid d-flex justify-content-center align-items-center">
    <Switch>
      <Route component={Login} />
    </Switch>
  </div>
);

const OtpVerifyContainer = () => (
  <div className="container-fluid d-flex justify-content-center align-items-center">
    <Switch>
      <Route component={ProfileValidateOtp} />
    </Switch>
  </div>
);

const DefaultContainer = () => {
  return (
    <AuthProvider>
      <div className="body-wrapper">
        <Header />
        <Navigation />
        <div className="content-wrapper">
          <Switch>
            <Route exact path="/stock-candles" component={StockCandles} />
            <Route exact path="/quote" component={Quotes} />
            <Route exact path="/price-target" component={PriceTarget} />
            <Route exact path="/fund-ownership" component={FundOwnership} />
            <Route
              exact
              path="/recommendation-trends"
              component={RecommendationTrends}
            />
            <Route
              exact
              path="/upgrade-downgrade"
              component={UpgradeDowngrade}
            />
            <Route exact path="/basic-financials" component={BasicFinancials} />
            <Route
              exact
              path="/last-price-updates"
              component={LastPriceUpdates}
            />
            <Route exact path="/news-sentiment" component={NewsSentiment} />
            <Route exact path="/company-news" component={CompanyNews} />
            <Route exact path="/news" component={News} />
            <Route exact path="/press-releases" component={PressReleases} />
            <Route exact path="/trades" component={Trades} />
            <Route exact path="/stock-symbols" component={StockSymbols} />
            <Route exact path="/stock-dividends" component={StockDividends} />
            <Route exact path="/etfs-holdings" component={EtfsHoldings} />
            <Route exact path="/ipo-calendar" component={IpoCalendar} />
            <Route
              exact
              path="/earnings-calendar"
              component={EarningsCalendar}
            />
            <Route
              exact
              path="/revenue-estimates"
              component={RevenueEstimates}
            />
            <Route
              exact
              path="/financials-reported"
              component={FinancialsReported}
            />
            <Route
              exact
              path="/income-statements"
              component={IncomeStatements}
            />
            <Route exact path="/balance-sheets" component={BalanceSheets} />
            <Route
              exact
              path="/cashflow-statements"
              component={CashflowStatements}
            />
            <Route
              exact
              path="/sales-revenue-growth"
              component={SalesRevenueGrowth}
            />
            <Route
              exact
              path="/earnings-net-income-growth"
              component={EarningsNetIncomeGrowth}
            />
            <Route
              exact
              path="/earnings-estimates"
              component={EarningsEstimates}
            />
            <Route exact path="/news-article" component={NewsArticle} />
            <Route exact path="/stock-options" component={StockOptions} />
            <Route exact path="/benzinga-ratings" component={BenzingaRatings} />
            <Route exact path="/barchart-options" component={BarchartOptions} />
            <Route
              exact
              path="/apha-options-differences"
              component={AphaOptionsDifferences}
            />
            <Route
              exact
              path="/income-statement-differences"
              component={IncomeStatementDifferences}
            />
            <Route
              exact
              path="/balance-sheet-differences"
              component={BalanceSheetDifferences}
            />
            <Route
              exact
              path="/spacs-all-companies"
              component={SpacsAllCompanies}
            />
            <Route exact path="/spacs-all-teams" component={SpacsAllTeams} />
            <Route
              exact
              path="/spacs-latest-filings"
              component={SpacsLatestFilings}
            />
            <Route
              exact
              path="/stock-news-articles"
              component={StockNewsArticles}
            />
            <Route
              exact
              path="/sector-stocks"
              component={SectorStocksListPage}
            />
            <Route
              exact
              path="/sector-stocks/create"
              component={SectorStocksCreatePage}
            />
            <Route
              exact
              path="/sector-stocks/detail/:symbol"
              component={SectorStocksEditPage}
            />
            <Route
              exact
              path="/iex/fund-ownership"
              component={IexFundOwnershipListPage}
            />
            <Route
              exact
              path="/iex/peer-groups"
              component={IexPeerGroupListPage}
            />
            <Route
              exact
              path="/iex/recommendations-and-price-targets"
              component={IexRecommendationsAndPriceTargetListPage}
            />
            <Route
              exact
              path="/stock-requests"
              component={StockRequestsListPage}
            />
            <Route exact path="/search-logs" component={SearchLogsListPage} />
            <Route
              exact
              path="/earning-estimates"
              component={EarningEstimatesListPage}
            />
            <Route
              exact
              path="/administrators"
              component={AdministratorsListPage}
            />
            <Route
              exact
              path="/administrators/create"
              component={AdministratorsCreatePage}
            />
            <Route
              exact
              path="/administrators/detail/:id"
              component={AdministratorEdit}
            />
            <Route exact path="/analytics" component={AnalyticsListPage} />
            <Route
              exact
              path="/analytics-big-query"
              component={AnalyticsBigQueryListPage}
            />
            <Route
              exact
              path="/dev-analytics"
              component={AnalyticsDevListPage}
            />
            <Route exact path="/users" component={UsersListPage} />
            <Route
              exact
              path="/dashboard-conversions-by-source"
              component={DashboardConversionsListPage}
            />
            <Route
              exact
              path="/dashboard-conversions-by-page"
              component={DashboardConversionsByPageListPage}
            />
            <Route
              exact
              path="/dashboard-repeated-visits"
              component={DashboardRepeatedVisitsListPage}
            />
            <Route
              exact
              path="/dashboard-all-visits"
              component={DashboardAllVisitsListPage}
            />
            <Route
              exact
              path="/dashboard-bounces-by-page"
              component={DashboardBouncesByPageListPage}
            />
            <Route
              exact
              path="/dashboard-conversions-by-utms"
              component={DashboardConversionsByUtmsListPage}
            />
            <Route
              exact
              path="/dashboard-traffic-by-page"
              component={DashboardTrafficByPageListPage}
            />
            <Route
              exact
              path="/dashboard-traffic-by-source"
              component={DashboardTrafficBySourceListPage}
            />
            <Route
              exact
              path="/dashboard-conversions-by-last-touch"
              component={DashboardConversionsByLastTouchListPage}
            />
            <Route
              exact
              path="/dashboard-conversions-by-referrer"
              component={DashboardConversionsByReferrerListPage}
            />
            <Route
              exact
              path="/dashboard-traffic-by-referrer"
              component={DashboardTrafficByReferrerListPage}
            />
            <Route
              exact
              path="/conversions-by-source"
              component={DashboardConversionsListPage}
            />
            <Route
              exact
              path="/conversions-by-page"
              component={DashboardConversionsByPageListPage}
            />
            <Route
              exact
              path="/repeated-visits"
              component={DashboardRepeatedVisitsListPage}
            />
            <Route
              exact
              path="/all-visits"
              component={DashboardAllVisitsListPage}
            />
            <Route
              exact
              path="/bounces-by-page"
              component={DashboardBouncesByPageListPage}
            />
            <Route
              exact
              path="/conversions-by-utms"
              component={DashboardConversionsByUtmsListPage}
            />
            <Route
              exact
              path="/traffic-by-page"
              component={DashboardTrafficByPageListPage}
            />
            <Route
              exact
              path="/traffic-by-source"
              component={DashboardTrafficBySourceListPage}
            />
            <Route
              exact
              path="/conversions-by-last-touch"
              component={DashboardConversionsByLastTouchListPage}
            />
            <Route
              exact
              path="/conversions-by-referrer"
              component={DashboardConversionsByReferrerListPage}
            />
            <Route
              exact
              path="/traffic-by-referrer"
              component={DashboardTrafficByReferrerListPage}
            />
            <Route exact path="/option-chains" component={OptionChainsPage} />
            <Route
              exact
              path="/options-track-record"
              component={OptionsTrackRecordPage}
            />
            <Route exact path="/tos-aggregates" component={TOSAggregates} />
            <Route
              exact
              path="/monthly-price-change"
              component={MonthlyPriceChangeListPage}
            />
            <Route
              exact
              path="/signal-strategies"
              component={SignalStrategiesPage}
            />
            <Route exact path="/exports" component={ExportsPage} />
            <Route exact path="/trade-logs" component={TradeLogsListPage} />
            <Route exact path="/settings" component={SettingsPage} />
            <Route exact path="/manual-trades" component={ManualTradesPage} />
            <Route exact path="/tradier-accounts" component={TradierAccounts} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/validate-otp" component={ProfileValidateOtp} />
          </Switch>
        </div>
        <Footer />
      </div>
    </AuthProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default withRouter(connect(mapStateToProps, null)(Routes));

import axios from "axios";
import useSWR from "swr";
import Cookies from "js-cookie";

export const coreAPI = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL || "http://localhost:3001",
  timeout: 120000, // 120s,
});

export function useRequest(request, { initialData, ...config } = {}) {
  const fetchWithToken = async (req) => {
    const accessToken = Cookies.get("accessToken"); // Get the access token
    if (accessToken) {
      coreAPI.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      delete coreAPI.defaults.headers.common.Authorization;
    }

    try {
      const response = await coreAPI(req);
      return response.data;
    } catch (error) {
      // Check if error is due to expired access token
      const refreshToken = Cookies.get("refreshToken");
      if (error.response && error.response.status === 401 && refreshToken) {
        // Get the refresh toke
        try {
          // Attempt to refresh the access token using coreAPI
          const refreshResponse = await coreAPI.post("/api/auth/refresh", {
            refreshToken,
          });
          const newAccessToken = refreshResponse.data.accessToken;
          // Update the access token cookie
          Cookies.set("accessToken", newAccessToken);

          // Update the authorization header and retry the original request
          coreAPI.defaults.headers.common.Authorization = `Bearer ${newAccessToken}`;
          const retryResponse = await coreAPI(req);
          return retryResponse.data;
        } catch (refreshError) {
          console.log("???");
          // Remove cookies and redirect to login on failure
          Cookies.remove("accessToken");
          Cookies.remove("refreshToken");
          localStorage.removeItem("btw_user");
          window.location.replace("/login");
          console.error("Failed to refresh access token:", refreshError);
          throw refreshError;
        }
      } else {
        console.log("???");
        // Remove cookies and redirect to login on failure
        Cookies.remove("accessToken");
        Cookies.remove("refreshToken");
        localStorage.removeItem("btw_user");
        window.location.replace("/login");
        console.error("Failed to refresh access token:", error);
        throw error;
      }
    }
  };

  return useSWR(
    request
      ? typeof request === "string"
        ? request
        : JSON.stringify(request)
      : null,
    () =>
      fetchWithToken(typeof request === "string" ? { url: request } : request),
    {
      ...config,
      initialData: initialData && {
        status: 200,
        statusText: "InitialData",
        headers: {},
        data: initialData,
      },
    }
  );
}

class API {
  static setToken() {
    const storage = localStorage.getItem("btw_user");

    const { token } = JSON.parse(storage);

    coreAPI.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
}

export default API;

import React, { useMemo, useState } from 'react'
import { useRequest } from '../../services/api'
import AirTable from '../ui/table'

function StockSymbols() {
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const { data, error } = useRequest(`/admin/stock-symbols?page=${page}&pageSize=50&q=${search}`)

  const stockSymbols = data ? data.stockSymbols : []
  const {totalItems, totalPages} = data || {}

  const columns = useMemo(() => {
    return [
      {
        Header: 'Symbol',
        accessor: 'symbol',
      },
      {
        Header: 'Display Symbol',
        accessor: 'displaySymbol',
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Currency',
        accessor: 'currency',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
    ]
  }, [])

  return (
    <div className="container-fluid p-3">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h2 className="mb-0">Stock Symbols</h2>
        <input
          style={{ minWidth: '150px', height: '30px', fontSize: '14px' }}
          className="border-none rounded outline-none rounded-tr-none rounded-br-none"
          type="search"
          placeholder="Search..."
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <AirTable
        columns={columns}
        data={stockSymbols}
        loading={!data && !error}
        totalItem={totalItems}
        totalPage={totalPages}
        onChangePageIndex={setPage}
        pagging
      />
    </div>
  )
}

export default React.memo(StockSymbols)


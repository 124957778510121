import React, { useMemo, useState } from 'react'
import { useRequest } from '../../services/api'
import AirTable from '../ui/table'
import { Link } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import Popover from '../../ui/popper'
import { sectorStockService } from '../../services'
import qs from 'querystring'
import debounce from 'debounce-async'

function SectorStocksListPage() {
  const [requesting, setRequesting] = useState(false)
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState()

  const query = qs.stringify({
    page,
    pageSize: 200,
    q: search
  })

  const { data, error, revalidate } = useRequest(`/admin/sector-stocks?${query}`)
  const { sectorStocks = [], totalPages, totalItems } = data || {}

  const columns = useMemo(() => {
    return [
      {
        Header: 'Company Name',
        accessor: 'companyName',
      },
      {
        Header: 'Symbol',
        accessor: 'symbol',
      },
      {
        Header: 'Sectors',
        accessor: 'sectors',
        Cell({ value }) {
          return Object.keys(value).map(function (k) {
            return value[k].name
          }).join(", ")
        }
      },
      {
        Header: 'Details',
        accessor: (row) => row,
        Cell({ value: row }) {
          return (
            <div className="d-flex justify-content-center align-items-center">
              <Link to={`/sector-stocks/detail/${row.symbol}`}>
                <button className="btn-primary btn text-white rounded">Detail</button>
              </Link>
              <Popover
                trigger="click"
                tooltip={
                  <div className="p-4" style={{ maxWidth: 200 }}>
                    <p className="">Are you sure that you want to delete this item?</p>
                    <div className="d-flex justify-content-end">
                      <button
                        className="btn w-24 btn-sm btn-danger"
                        onClick={async () => {
                          try {
                            setRequesting(true)
                            await sectorStockService.delete(row.symbol)
                            await revalidate()
                            toast.success(`Successfully deleted item with ID: ${row.symbol}`)
                          } catch (err) {
                            toast.success(err.message)
                          } finally {
                            setRequesting(false)
                          }
                        }}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                }
              >
                <button className="btn-danger btn text-white rounded ml-2">
                  Delete
                </button>
              </Popover>
            </div>
          )
        },
      }
    ]
  }, [requesting])

  return (
    <>
      <ToastContainer/>
      <div className="container-fluid p-3">
        <div className="d-flex justify-content-between align-items-center">
          <h2>Sector Stocks</h2>
          <Link to="/sector-stocks/create">
            <a className="btn btn-primary text-white">Create</a>
          </Link>
        </div>
        <div className="mb-3">
          <input
            className="form-control w-25"
            placeholder="Search"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <AirTable
          columns={columns}
          data={sectorStocks}
          loading={!data && !error}
          totalPage={totalPages}
          totalItem={totalItems}
          onChangePageIndex={setPage}
          pagging
        />
      </div>
    </>
  )
}

export default React.memo(SectorStocksListPage)

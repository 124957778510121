import constants from '../common/constants'

export default {
  getUser: () => {
    let user = localStorage.getItem(constants.auth.userName)

    if (!user) {
      throw new Error('User is missing in local storage')
    }

    return JSON.parse(user)
  },

  formatCurrency: (value) => {
    if (!value || isNaN(value)) {
      return ''
    }

    const currencyFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    return currencyFormatter.format(value)
  },
}

import React, { useMemo, useState } from 'react'
import { useRequest } from '../../services/api'
import AirTable from "../ui/table"
import qs from "querystring";
import AutoComplete from "../ui/symbol-dropdown";

function EtfsHoldings() {
  const [search, setSearch] = useState('')
  const query = qs.stringify({
    symbol: search,
  })
  const { data, error } = useRequest(`/admin/fin-hub/etfs-holdings?${query}`)

  const { symbol, holdings = [], atDate } = data || {}

  const columns = useMemo(() => {
    return [
      {
        Header: 'Symbol',
        accessor: 'symbol',
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Value',
        accessor: 'value',
      },
      {
        Header: 'Percent',
        accessor: 'percent',
      },
      {
        Header: 'Share',
        accessor: 'share',
      },
      {
        Header: 'Cusip',
        accessor: 'cusip',
      },
      {
        Header: 'Isin',
        accessor: 'isin',
      },
    ]
  }, [])

  const onInputChange = (e) => {
    setSearch(e.value)
  }

  return (
    <div className="container-fluid p-3">
      <div className="d-flex justify-content-between align-items-center mb-6">
        <h2>ETFS Holdings: {symbol}</h2>
        <h3>At date: {data ? data.atDate : ''}</h3>
        <div style={{ width: '320px' }}>
          <AutoComplete
            onChange={onInputChange}
            defaultOption={{label: 'SPY', value: 'SPY'}}
          />
        </div>
      </div>
      <AirTable
        columns={columns}
        data={holdings}
        loading={!data && !error}
      />
    </div>
  )
}

export default React.memo(EtfsHoldings)

import React, {useMemo, useState} from 'react'
import {useRequest} from '../../services/api'
import moment from "moment"
import AirTable from "../ui/table"
import qs from "querystring";
import AutoComplete from "../ui/symbol-dropdown";

function EarningsEstimates() {
  const [search, setSearch] = useState('')
  const query = qs.stringify({
    symbol: search,
  })
  const {data, error} = useRequest(`admin/fin-hub/earnings-estimates?${query}`)
  const [requesting, setRequesting] = useState(false)

  const columns = useMemo(() => {
    return [
      {
        Header: 'EPS High',
        accessor: 'epsHigh',
      },
      {
        Header: 'EPS Avg',
        accessor: 'epsAvg',
      },
      {
        Header: 'EPS Low',
        accessor: 'epsLow',
      },
      {
        Header: 'Number Analysts',
        accessor: 'numberAnalysts',
      },
      {
        Header: 'Period',
        accessor: 'period'
      },
    ]
  }, [requesting])

  const onInputChange = (e) => {
    setSearch(e.value)
  }

  return (
    <div className="container-fluid p-3">
      <div className="d-flex justify-content-between align-items-center mb-6">
        <h2>Earnings Estimates: {data?.earningsEstimates?.symbol} - {data?.earningsEstimates?.freq} </h2>
        <div style={{width: '320px'}}>
          <AutoComplete onChange={onInputChange}/>
        </div>
      </div>
      <AirTable
        columns={columns}
        data={data?.earningsEstimates?.data}
        loading={!data && !error}
      />
    </div>
  )
}

export default React.memo(EarningsEstimates)

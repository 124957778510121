import * as loginActions from '../actions/auth'

const initialState = {
  user: null,
  errorMessage: null,
  loading: false,
  redirectToHomePage: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case loginActions.LOGIN_STARTED:
      return {
        ...state,
        loading: true,
      }
    case loginActions.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.user,
      }
    case loginActions.LOGIN_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: action.errorMessage
      }
    default:
      return state
  }
}

export default reducer

import React, { useMemo, useState } from 'react'
import ManualTradeForm from './form'
import { strategyService } from '../../services'
import { toast, ToastContainer } from 'react-toastify'
import AirTable from '../ui/table'
import { useRequest } from '../../services/api'
import moment from 'moment'

function ManualTradesPage() {
  const [page, setPage] = useState(1)

  const { data, revalidate } = useRequest('/admin/manual-trades/orders')
  const { orders = [] } = data || {}

  const { data: positionsData, revalidate: positionsRevalidate } = useRequest(`/admin/manual-trades/positions`)
  const { positions = [] } = positionsData || {}

  const onSave = async (data) => {
    await strategyService.createManualTrade(data)
    toast.success('New manual trade created')
    await revalidate()
    await positionsRevalidate()
  }

  const onSell = async (data) => {
    await strategyService.sellManualTrade(data)
    toast.success('New manual trade sold')
    await revalidate()
    await positionsRevalidate()
  }

  const columns = useMemo(() => {
    return [
      {
        Header: 'Option Symbol',
        accessor: 'symbol',
      },
      {
        Header: 'Avg Fill Price',
        accessor: 'avg_fill_price',
      },
      {
        Header: 'Last Fill Price',
        accessor: 'last_fill_price',
      },
      {
        Header: 'Quantity',
        accessor: 'quantity',
      },
      {
        Header: 'Remaining Quantity',
        accessor: 'remaining_quantity',
      },
      {
        Header: 'Remaining Fill Quantity',
        accessor: 'last_fill_quantity',
      },
      {
        Header: 'Exec Quantity',
        accessor: 'exec_quantity',
      },
      {
        Header: 'Class',
        accessor: 'duration',
      },
      {
        Header: 'Class',
        accessor: 'class',
      },
      {
        Header: 'Side',
        accessor: 'side',
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Transaction Date',
        accessor: 'transaction_date',
        Cell({ value }) {
          return moment(value).format('MM/DD/YYYY HH:mm')
        }
      },
    ]
  }, [])

  const positionColumns = useMemo(() => {
    return [
      {
        Header: 'Symbol',
        accessor: 'symbol',
      },
      {
        Header: 'Cost Basis',
        accessor: 'cost_basis',
      },
      {
        Header: 'Quantity',
        accessor: 'quantity',
      },
      {
        Header: 'Date Acquired',
        accessor: 'date_acquired',
        Cell({ value }) {
          return value ? moment(value).format('MM/DD/YYYY HH:mm') : ''
        }
      },
      {
        Header: 'Details',
        accessor: (row) => row,
        Cell({ value: row }) {
          const [value, setValue] = useState()
          return (
            <div className="d-flex">
              <input
                className="w-50"
                placeholder="Number of Contracts"
                type="number"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
              <button
                className="btn btn-sm btn-danger ml-3"
                onClick={async () => {
                  await onSell({
                    optionContract: row.symbol,
                    numberOfContracts: Number(value) || 1
                  })
                  setValue(null)
                }}
              >
                Sell
              </button>
            </div>
          )
        },
      },
    ]
  }, [])

  return (
    <div className="container-fluid p-3">
      <ToastContainer/>
      <h4>Manual Trades</h4>
      <ManualTradeForm onSave={onSave}/>
      <div className="row mt-5">
        <div className="col-md-12">
          <h4>Orders</h4>
          <AirTable
            columns={columns}
            data={orders}
            loading={!data}
          />
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-md-12">
          <h4>Positions</h4>
          <AirTable
            columns={positionColumns}
            data={positions}
            loading={!positionsData}
          />
        </div>
      </div>
    </div>
  )
}

export default ManualTradesPage

import React, { Component } from 'react'

class Footer extends Component {
  render () {
    return (
      <footer className="main-footer">
        <strong>Copyright © {new Date().getFullYear()}  <a href="https://empoder.com" target="_blank">Empoder</a>. </strong>
          All rights reserved.
      </footer>
    )
  }
}

export default Footer

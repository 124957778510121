import React, { useEffect, useMemo, useState } from 'react'
import { useRequest } from '../../services/api'
import AirTable from "../ui/table"
import { ToastContainer } from 'react-toastify'
import qs from 'querystring'
import moment from 'moment'
import axios from 'axios'
import { withRouter } from 'react-router'

function DashboardTrafficByPageListPage({ location }) {
  const [data, setData] = useState([])
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(true)

  const isProduction = location.search === '?full=true'
  const query = qs.stringify({
    page,
    pageSize: 50,
    from: !isProduction ? `2022-01-05` : `2022-01-24`,
    to: !isProduction ? `2022-01-24` : `9999-01-01`
  })

  useEffect(() => {
    axios.get(`https://cltr.empoder.com/admin/dashboard/traffic-by-page?${query}`).then((res) => {
      setData(res.data)
      setLoading(false)
    })
  }, [page, location.search])

  const { events = [], total } = data || {}

  console.log(events)

  const columns = useMemo(() => {
    return [
      {
        Header: 'URL',
        accessor: 'url',
      },
      {
        Header: 'Page Views',
        accessor: 'pageViews',
      },
    ]
  }, [])

  return (
    <div className="p-3">
      <ToastContainer/>
      <div className="row">
        <div className="col-md-12 pb-3">
          <div className="d-flex justify-content-between align-items-center">
            <h3>Traffic by page</h3>
          </div>
        </div>
        <div className="col-md-12">
          <AirTable
            columns={columns}
            data={events}
            loading={loading}
          />
          <p>Total: {total}</p>
        </div>
      </div>
    </div>
  )
}

export default React.memo(withRouter(DashboardTrafficByPageListPage))

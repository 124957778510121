import React, { useEffect, useMemo, useState } from 'react'
import AirTable from '../ui/table'
import axios from 'axios'
import moment from 'moment'
import qs from 'querystring'
import { capitalCase } from 'change-case'

function AnalyticsDevListPage() {
  const [page, setPage] = useState(1)
  const [data, setData] = useState([])

  const query = qs.stringify({
    page,
    pageSize: 500,
  })

  useEffect(() => {
    axios.get(`https://cltr.empoder.com/api/empoder/bq-dev?${query}`).then((res) => setData(res.data))
  }, [page])

  const { events = [], totalPages, totalItems } = data || {}

  const columns = useMemo(() => {
    return [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: 'UTM Medium',
        accessor: 'utmMedium',
      },
      {
        Header: 'UTM Campaign',
        accessor: 'utmCampaign',
      },
      {
        Header: 'UTM Source',
        accessor: 'utmSource',
      },
      {
        Header: 'UTM ID',
        accessor: 'utmId',
      },
      {
        Header: 'UTM Term',
        accessor: 'utmTerm',
      },
      {
        Header: 'UTM Content',
        accessor: 'utmContent',
      },
      {
        Header: 'Target',
        accessor: 'eventTarget',
      },
      {
        Header: 'Label',
        accessor: 'eventLabel',
      },
      {
        Header: 'Event',
        accessor: 'event',
      },
      {
        Header: 'Value',
        accessor: 'value',
      },
      {
        Header: 'URL',
        accessor: 'url',
      },
      {
        Header: 'User ID',
        accessor: 'userId',
      },
      {
        Header: 'Session ID',
        accessor: 'sessionId',
      },
      {
        Header: 'Unique ID',
        accessor: 'uniqueId',
      },
      {
        Header: 'User Agent',
        accessor: 'userAgent',
      },
      {
        Header: 'Referrer',
        accessor: 'referrer',
      },
      {
        Header: 'Origin',
        accessor: 'origin',
      },
      {
        Header: 'IP Address',
        accessor: 'ipAddress',
      },
      {
        Header: 'Browser Name',
        accessor: 'browserName',
      },
      {
        Header: 'Browser Version',
        accessor: 'browserVersion',
      },
      {
        Header: 'OS Name',
        accessor: 'osName',
      },
      {
        Header: 'OS Version',
        accessor: 'osVersion',
      },
      {
        Header: 'Device Model',
        accessor: 'deviceModel',
      },
      {
        Header: 'Device Type',
        accessor: 'deviceType',
      },
      {
        Header: 'Device Vendor',
        accessor: 'deviceVendor',
      },
      {
        Header: 'Referral Code',
        accessor: 'referralCode',
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
      },
    ]
  }, [events])

  return (
    <div className="container-fluid p-3">
      <div className="flex justify-between items-center mb-6">
        <h2>Development Analytics</h2>
      </div>
      <AirTable
        columns={columns}
        data={events}
        loading={events.length <= 0}
        totalItem={totalItems}
        totalPage={totalPages}
        onChangePageIndex={setPage}
        pagging
      />
    </div>
  )
}

export default React.memo(AnalyticsDevListPage)

import React, { Component } from "react"
import { NavLink } from "react-router-dom"

class Navigation extends Component {
  render() {
    return (
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <NavLink to="/" className="brand-link">
          <span className="brand-text font-weight-light ml-3">Admin Trillions</span>
        </NavLink>
        <div className="sidebar">
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-item">
                <NavLink to="/trade-logs" className="nav-link" activeClassName="active">
                  <p>Trade Logs</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/settings" className="nav-link" activeClassName="active">
                  <p>Settings</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/manual-trades" className="nav-link" activeClassName="active">
                  <p>Manual Trades</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/tradier-accounts" className="nav-link" activeClassName="active">
                  <p>Tradier accounts</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/signal-strategies" className="nav-link" activeClassName="active">
                  <p>Signal Strategies</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/exports" className="nav-link" activeClassName="active">
                  <p>Exports</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/option-chains" className="nav-link" activeClassName="active">
                  <p>Option Chains</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/options-track-record" className="nav-link" activeClassName="active">
                  <p>Options Track Record</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/monthly-price-change" className="nav-link" activeClassName="active">
                  <p>Monthly Price Change</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/tos-aggregates" className="nav-link" activeClassName="active">
                  <p>TOS aggregates</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link">
                  <p>
                    IEX
                    <i className="right fas fa-angle-left r-4"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview" style={{ display: 'none' }}>
                  <li className="nav-item">
                    <a className="nav-link p-0 pl-2">
                      <NavLink to="/iex/fund-ownership" className="nav-link" activeClassName="active">
                        <p>Fund ownership</p>
                      </NavLink>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link p-0 pl-2">
                      <NavLink to="/iex/peer-groups" className="nav-link" activeClassName="active">
                        <p>Peer groups</p>
                      </NavLink>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link p-0 pl-2">
                      <NavLink to="/iex/recommendations-and-price-targets" className="nav-link"
                        activeClassName="active">
                        <p>Recommendation & price targets</p>
                      </NavLink>
                    </a>
                  </li>
                </ul>
              </li>
              {/*<li className="nav-item">*/}
              {/*  <NavLink to="/stock-candles" className="nav-link" activeClassName="active">*/}
              {/*    <p>Stock Candles</p>*/}
              {/*  </NavLink>*/}
              {/*</li>*/}
              <li className="nav-item">
                <NavLink to="/stock-symbols" className="nav-link" activeClassName="active">
                  <p>Stock Symbols</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/stock-requests" className="nav-link" activeClassName="active">
                  <p>Stock Requests</p>
                </NavLink>
              </li>
              {/*<li className="nav-item">*/}
              {/*  <NavLink to="/stock-dividends" className="nav-link" activeClassName="active">*/}
              {/*    <p>Stock Dividends</p>*/}
              {/*  </NavLink>*/}
              {/*</li>*/}
              <li className="nav-item">
                <NavLink to="/stock-options" className="nav-link" activeClassName="active">
                  <p>Stock Options</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/sector-stocks" className="nav-link" activeClassName="active">
                  <p>Sector Stocks</p>
                </NavLink>
              </li>
              {/*<li className="nav-item">*/}
              {/*  <NavLink to="/quote" className="nav-link" activeClassName="active">*/}
              {/*    <p>Quote</p>*/}
              {/*  </NavLink>*/}
              {/*</li>*/}
              {/*<li className="nav-item">*/}
              {/*  <NavLink to="/price-target" className="nav-link" activeClassName="active">*/}
              {/*    <p>Price Target</p>*/}
              {/*  </NavLink>*/}
              {/*</li>*/}
              {/*<li className="nav-item">*/}
              {/*  <NavLink to="/fund-ownership" className="nav-link" activeClassName="active">*/}
              {/*    <p>Fund Ownership</p>*/}
              {/*  </NavLink>*/}
              {/*</li>*/}
              {/*<li className="nav-item">*/}
              {/*  <NavLink to="/recommendation-trends" className="nav-link" activeClassName="active">*/}
              {/*    <p>Recommendation Trends</p>*/}
              {/*  </NavLink>*/}
              {/*</li>*/}
              {/*<li className="nav-item">*/}
              {/*  <NavLink to="/upgrade-downgrade" className="nav-link" activeClassName="active">*/}
              {/*    <p>Upgrade Downgrade</p>*/}
              {/*  </NavLink>*/}
              {/*</li>*/}
              {/*<li className="nav-item">*/}
              {/*  <NavLink to="/basic-financials" className="nav-link" activeClassName="active">*/}
              {/*    <p>Basic Financials</p>*/}
              {/*  </NavLink>*/}
              {/*</li>*/}
              <li className="nav-item">
                <NavLink to="/last-price-updates" className="nav-link" activeClassName="active">
                  <p>Last Price Updates</p>
                </NavLink>
              </li>
              {/*<li className="nav-item">*/}
              {/*  <NavLink to="/news-sentiment" className="nav-link" activeClassName="active">*/}
              {/*    <p>News Sentiment</p>*/}
              {/*  </NavLink>*/}
              {/*</li>*/}
              {/*<li className="nav-item">*/}
              {/*  <NavLink to="/company-news" className="nav-link" activeClassName="active">*/}
              {/*    <p>Company News</p>*/}
              {/*  </NavLink>*/}
              {/*</li>*/}
              <li className="nav-item">
                <NavLink to="/news-article" className="nav-link" activeClassName="active">
                  <p>Company News 2</p>
                </NavLink>
              </li>
              {/*<li className="nav-item">*/}
              {/*  <NavLink to="/news" className="nav-link" activeClassName="active">*/}
              {/*    <p>News</p>*/}
              {/*  </NavLink>*/}
              {/*</li>*/}
              <li className="nav-item">
                <NavLink to="/stock-news-articles" className="nav-link" activeClassName="active">
                  <p>Stock News Articles</p>
                </NavLink>
              </li>
              {/*<li className="nav-item">*/}
              {/*  <NavLink to="/press-releases" className="nav-link" activeClassName="active">*/}
              {/*    <p>Press Releases</p>*/}
              {/*  </NavLink>*/}
              {/*</li>*/}
              {/*<li className="nav-item">*/}
              {/*  <NavLink to="/trades" className="nav-link" activeClassName="active">*/}
              {/*    <p>Trades grouped by hour</p>*/}
              {/*  </NavLink>*/}
              {/*</li>*/}
              {/*<li className="nav-item">*/}
              {/*  <NavLink to="/etfs-holdings" className="nav-link" activeClassName="active">*/}
              {/*    <p>Etfs Holdings</p>*/}
              {/*  </NavLink>*/}
              {/*</li>*/}
              {/*<li className="nav-item">*/}
              {/*  <NavLink to="/ipo-calendar" className="nav-link" activeClassName="active">*/}
              {/*    <p>IPO Calendar</p>*/}
              {/*  </NavLink>*/}
              {/*</li>*/}
              {/*<li className="nav-item">*/}
              {/*  <NavLink to="/earnings-calendar" className="nav-link" activeClassName="active">*/}
              {/*    <p>Earnings Calendar</p>*/}
              {/*  </NavLink>*/}
              {/*</li>*/}
              {/*<li className="nav-item">*/}
              {/*  <NavLink to="/revenue-estimates" className="nav-link" activeClassName="active">*/}
              {/*    <p>Revenue Estimates</p>*/}
              {/*  </NavLink>*/}
              {/*</li>*/}
              {/*<li className="nav-item">*/}
              {/*  <NavLink to="/income-statements" className="nav-link" activeClassName="active">*/}
              {/*    <p>Income statements</p>*/}
              {/*  </NavLink>*/}
              {/*</li>*/}
              {/*<li className="nav-item">*/}
              {/*  <NavLink to="/balance-sheets" className="nav-link" activeClassName="active">*/}
              {/*    <p>Balance sheets</p>*/}
              {/*  </NavLink>*/}
              {/*</li>*/}
              {/*<li className="nav-item">*/}
              {/*  <NavLink to="/cashflow-statements" className="nav-link" activeClassName="active">*/}
              {/*    <p>Cash flow statements</p>*/}
              {/*  </NavLink>*/}
              {/*</li>*/}
              {/*<li className="nav-item">*/}
              {/*  <NavLink to="/financials-reported" className="nav-link" activeClassName="active">*/}
              {/*    <p>Financials Reported</p>*/}
              {/*  </NavLink>*/}
              {/*</li>*/}
              {/*<li className="nav-item">*/}
              {/*  <NavLink to="/sales-revenue-growth" className="nav-link" activeClassName="active">*/}
              {/*    <p>Sales Revenue Growth</p>*/}
              {/*  </NavLink>*/}
              {/*</li>*/}
              {/*<li className="nav-item">*/}
              {/*  <NavLink to="/earnings-net-income-growth" className="nav-link" activeClassName="active">*/}
              {/*    <p>Earnings net income growth</p>*/}
              {/*  </NavLink>*/}
              {/*</li>*/}
              {/*<li className="nav-item">*/}
              {/*  <NavLink to="/earnings-estimates" className="nav-link" activeClassName="active">*/}
              {/*    <p>Earnings Estimates</p>*/}
              {/*  </NavLink>*/}
              {/*</li>*/}
              <li className="nav-item">
                <NavLink to="/benzinga-ratings" className="nav-link" activeClassName="active">
                  <p>Benzinga Ratings</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/barchart-options" className="nav-link" activeClassName="active">
                  <p>Barchart Options</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/apha-options-differences" className="nav-link" activeClassName="active">
                  <p>APHA Options Differences</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/income-statement-differences" className="nav-link" activeClassName="active">
                  <p>Income Statement Differences</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/balance-sheet-differences" className="nav-link" activeClassName="active">
                  <p>Balance Sheets Differences</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/spacs-all-companies" className="nav-link" activeClassName="active">
                  <p>SPACs All Companies</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/spacs-all-teams" className="nav-link" activeClassName="active">
                  <p>SPACs All Teams</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/spacs-latest-filings" className="nav-link" activeClassName="active">
                  <p>SPACs Latest Filings</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/earning-estimates" className="nav-link" activeClassName="active">
                  <p>Earning Estimates</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/users" className="nav-link" activeClassName="active">
                  <p>Users</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <a href="#" className="nav-link">
                  <p>
                    Dashboard Full experience
                    <i className="right fas fa-angle-left r-4" />
                  </p>
                </a>
                <ul className="nav nav-treeview" style={{ display: 'none' }}>
                  <li className="nav-item">
                    <a className="nav-link p-0 pl-2">
                      <NavLink to="/conversions-by-source?full=true" className="nav-link" activeClassName="active">
                        <p>Conversions by source</p>
                      </NavLink>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link p-0 pl-2">
                      <NavLink to="/conversions-by-utms?full=true" className="nav-link" activeClassName="active">
                        <p>Conversions by utms</p>
                      </NavLink>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link p-0 pl-2">
                      <NavLink to="/conversions-by-last-touch?full=true" className="nav-link" activeClassName="active">
                        <p>Conversions by last touch</p>
                      </NavLink>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link p-0 pl-2">
                      <NavLink to="/conversions-by-referrer?full=true" className="nav-link" activeClassName="active">
                        <p>Conversions by referrer</p>
                      </NavLink>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link p-0 pl-2">
                      <NavLink to="/conversions-by-page?full=true" className="nav-link" activeClassName="active">
                        <p>Conversions by page</p>
                      </NavLink>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link p-0 pl-2">
                      <NavLink to="/repeated-visits?full=true" className="nav-link" activeClassName="active">
                        <p>Repeated visits</p>
                      </NavLink>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link p-0 pl-2">
                      <NavLink to="/all-visits?full=true" className="nav-link" activeClassName="active">
                        <p>All visits</p>
                      </NavLink>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link p-0 pl-2">
                      <NavLink to="/bounces-by-page?full=true" className="nav-link" activeClassName="active">
                        <p>Bounces By Page</p>
                      </NavLink>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link p-0 pl-2">
                      <NavLink to="/traffic-by-page?full=true" className="nav-link" activeClassName="active">
                        <p>Traffic by page</p>
                      </NavLink>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link p-0 pl-2">
                      <NavLink to="/traffic-by-source?full=true" className="nav-link" activeClassName="active">
                        <p>Traffic by source</p>
                      </NavLink>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link p-0 pl-2">
                      <NavLink to="/traffic-by-referrer?full=true" className="nav-link" activeClassName="active">
                        <p>Traffic by referrer</p>
                      </NavLink>
                    </a>
                  </li>
                </ul>
              </li>

              <li className="nav-item">
                <a href="#" className="nav-link">
                  <p>
                    Dashboard Teaser
                    <i className="right fas fa-angle-left r-4" />
                  </p>
                </a>
                <ul className="nav nav-treeview" style={{ display: 'none' }}>
                  <li className="nav-item">
                    <a className="nav-link p-0 pl-2">
                      <NavLink to="/dashboard-conversions-by-source" className="nav-link" activeClassName="active">
                        <p>Conversions by source</p>
                      </NavLink>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link p-0 pl-2">
                      <NavLink to="/dashboard-conversions-by-utms" className="nav-link" activeClassName="active">
                        <p>Conversions by utms</p>
                      </NavLink>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link p-0 pl-2">
                      <NavLink to="/dashboard-conversions-by-last-touch" className="nav-link" activeClassName="active">
                        <p>Conversions by last touch</p>
                      </NavLink>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link p-0 pl-2">
                      <NavLink to="/dashboard-conversions-by-referrer" className="nav-link" activeClassName="active">
                        <p>Conversions by referrer</p>
                      </NavLink>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link p-0 pl-2">
                      <NavLink to="/dashboard-conversions-by-page" className="nav-link" activeClassName="active">
                        <p>Conversions by page</p>
                      </NavLink>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link p-0 pl-2">
                      <NavLink to="/dashboard-repeated-visits" className="nav-link" activeClassName="active">
                        <p>Repeated visits</p>
                      </NavLink>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link p-0 pl-2">
                      <NavLink to="/dashboard-all-visits" className="nav-link" activeClassName="active">
                        <p>All visits</p>
                      </NavLink>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link p-0 pl-2">
                      <NavLink to="/dashboard-bounces-by-page" className="nav-link" activeClassName="active">
                        <p>Bounces By Page</p>
                      </NavLink>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link p-0 pl-2">
                      <NavLink to="/dashboard-traffic-by-page" className="nav-link" activeClassName="active">
                        <p>Traffic by page</p>
                      </NavLink>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link p-0 pl-2">
                      <NavLink to="/dashboard-traffic-by-source" className="nav-link" activeClassName="active">
                        <p>Traffic by source</p>
                      </NavLink>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link p-0 pl-2">
                      <NavLink to="/dashboard-traffic-by-referrer" className="nav-link" activeClassName="active">
                        <p>Traffic by referrer</p>
                      </NavLink>
                    </a>
                  </li>
                </ul>
              </li>

              <li className="nav-item">
                <NavLink to="/analytics" className="nav-link" activeClassName="active">
                  <p>Analytics</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/analytics-big-query" className="nav-link" activeClassName="active">
                  <p>Analytics Big Query</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/dev-analytics" className="nav-link" activeClassName="active">
                  <p>Development Analytics</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/search-logs" className="nav-link" activeClassName="active">
                  <p>Search Logs</p>
                </NavLink>
              </li>
              <li className="nav-item mb-5">
                <NavLink to="/administrators" className="nav-link" activeClassName="active">
                  <p>Administrators</p>
                </NavLink>
              </li>
            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
    )
  }
}

export default Navigation

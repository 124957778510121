import React, { useEffect, useMemo, useState } from 'react'
import AirTable from '../ui/table'
import qs from 'querystring'
import { coreAPI, useRequest } from '../../services/api'
import Select from 'react-select'
import _ from 'lodash'
import { useSearchSectorStocks } from '../../helpers/hooks/shared'
import AutoComplete from '../ui/autocomplete'
import moment from 'moment'
import DatePicker from '../datepicker'
import { toast } from 'react-toastify'

function OptionsTrackRecordPage() {
  const [date, setDate] = useState('')
  const [symbol, setSymbol] = useState({})
  const [group, setGroup] = useState({ name: 'David', value: 'David' })
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [arrayItems, setArrayItems] = useState([])

  const query = qs.stringify({
    symbol: symbol.value || "",
    group: group.value,
    page,
    pageSize: 50,
  })

  const onButtonClick = () => {
    window.open(`${process.env.REACT_APP_BASE_API_URL}/admin/fin-hub/options-track-record-csv?date=${date ? moment(date).format('YYYY-MM-DD') : ''}`, '_blank')
  }

  const { data, error } = useRequest(`/admin/fin-hub/options-track-record?${query}`, { refreshInterval: 10000 })
  const stocks = useSearchSectorStocks(search)

  const stocksOptions = stocks?.map((s) => {
    return {
      value: s,
      label: s,
    }
  })

  const onKeyUp = (e) => {
    setSearch(e.target.value)
  }

  const { items = [], totalItems, totalPages } = data || {}

  useEffect(() => {
    setArrayItems(items)

    if(arrayItems.length > 0) {
      items.map((item, i) => {
        Object.values(item).forEach((iteme, x) => {
          const objectKey = Object.keys(item)[x] || null
          item[`${objectKey}Blink`] = item[objectKey] !== arrayItems[i][objectKey]
        })
      })
    }
  }, [data])

  const columns = useMemo(() => {
    return [
      {
        Header: 'Symbol',
        accessor: 'symbol',
      },
      {
        Header: 'Ticker',
        accessor: 'ticker',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Date',
        accessor: 'date',
      },
      {
        Header: 'Pattern created at',
        accessor: 'patternCreatedAt',
      },
      {
        Header: 'Resolution',
        accessor: 'resolution',
      },
      {
        Header: 'Price',
        accessor: 'close',
      },
      {
        Header: '15m Price',
        accessor: 'fifteenMinPrice',
      },
      {
        Header: '15m % diff',
        accessor: 'fifteenMinPercentDiff',
      },
      {
        Header: '30m Price',
        accessor: 'thirtyMinPrice',
      },
      {
        Header: '30m % diff',
        accessor: 'thirtyMinPercentDiff',
      },
      {
        Header: '1h Price',
        accessor: 'oneHourPrice',
      },
      {
        Header: '1h % diff',
        accessor: 'oneHourPercentDiff',
      },
      {
        Header: '2h Price',
        accessor: 'twoHoursPrice',
      },
      {
        Header: '2h % diff',
        accessor: 'twoHoursPercentDiff',
      },
      {
        Header: '4h Price',
        accessor: 'fourHoursPrice',
      },
      {
        Header: '4h % diff',
        accessor: 'fourHoursPercentDiff',
      },
      {
        Header: '1d Price',
        accessor: 'oneDayPrice',
      },
      {
        Header: '1d % diff',
        accessor: 'oneDayPercentDiff',
      },
      {
        Header: '2d Price',
        accessor: 'twoDaysPrice',
      },
      {
        Header: '2d % diff',
        accessor: 'twoDaysPercentDiff',
      },
      {
        Header: '3d Price',
        accessor: 'threeDaysPrice',
      },
      {
        Header: '3d % diff',
        accessor: 'threeDaysPercentDiff',
      },
    ]
  }, [data])

  const renderBlinkingItems = () => {
    return items.map((item, i) => (
      <div className="d-flex">
        {Object.keys(item).map(key => {
          if(!key.includes('Blink') && item[key]) {
            return (
              <p className={`${item[`${key}Blink`] ? 'blink' : ''}`} style={{ minWidth: '180px', textAlign: 'center', fontSize: '12px' }}>
                {key} <br/>
                {item[key]}
              </p>
            )
          }
        })}
      </div>
    ))
  }

  return (
    <div className="container-fluid p-3">
      <div className="row">
        <div className="col-md-6">
          <div className="flex justify-between items-center mb-3">
            <label className="font-bold text-sm">Select symbol</label>
            <div className="d-flex w-100 align-items-center justify-content-center">
              <div className="w-100">
                <AutoComplete
                  options={stocksOptions}
                  value={stocksOptions.filter((o) => o.symbol === symbol.name)[0] || ""}
                  onChange={(e) => {
                    console.log(e)
                    setSymbol(e)
                    setPage(1)
                  }}
                  inputProps={{ className: "bg-snow w-100 text-sm rounded-lg font-poppins border" }}
                  onKeyUp={(e) => {
                    console.log(e, 'keyup')
                    onKeyUp(e)
                  }}
                  getOptionLabel={(option) => option.symbol}
                  getOptionValue={(option) => option.symbol}
                  className=""
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: '0',
                      backgroundColor: 'white',
                      boxShadow: 'none',
                    }),
                    singleValue: (provided) => ({ ...provided, paddingLeft: '5px' }),
                    menu: (provided) => ({
                      ...provided,
                      left: '0px',
                      zIndex: 99999999
                    }),
                    valueContainer: (provided) => ({
                      ...provided,
                      padding: 0,
                    }),
                    indicatorSeparator: () => ({ display: 'none' }),
                  }}
                />
              </div>
              <i
                className="fas fa-times ml-3"
                onClick={() => setSymbol({})}
              />
            </div>
            <label className="font-bold text-sm">Select group</label>

            <div className="d-flex w-100 align-items-center justify-content-center">
              <Select
                options={[
                  // { name: 'Pattern Recognition', value: 'Pattern Recognition' },
                  // { name: 'TOS', value: 'TOS' },
                  { name: 'David', value: 'David' }
                ]}
                value={group}
                onChange={(e) => {
                  setGroup(e)
                  setPage(1)
                }}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.name}
                className="bg-snow w-100 text-sm rounded-lg font-poppins border"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    border: '0',
                    backgroundColor: 'white',
                    boxShadow: 'none',
                  }),
                  singleValue: (provided) => ({ ...provided, paddingLeft: '5px' }),
                  menu: (provided) => ({
                    ...provided,
                    left: '0px',
                    zIndex: 99999999
                  }),
                  valueContainer: (provided) => ({
                    ...provided,
                    padding: 0,
                  }),
                  indicatorSeparator: () => ({ display: 'none' }),
                }}
              />
              <i
                className="fas fa-times ml-3"
                onClick={() => setGroup({})}
              />
            </div>
            <div className="w-full">
              <label className="font-bold text-sm">Select date</label>
              <div className="d-flex align-items-center w-100">
                <DatePicker
                  className="border w-100 py-2"
                  value={date && moment(date)}
                  onChange={(e) => {
                    setDate(e)
                  }}
                />
                <i
                  className="fas fa-times ml-3"
                  onClick={() => setDate('')}
                />
              </div>
              <button
                className="btn btn-primary mt-1"
                onClick={onButtonClick}
              >
                Download CSV
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="d-flex w-100 justify-content-between align-items-center mb-2">
            <h2>Options Track Record</h2>
          </div>
          <AirTable
            columns={columns}
            data={items}
            loading={_.isEmpty(data)}
            totalItem={totalItems}
            totalPage={totalPages}
            onChangePageIndex={setPage}
            pagging
          />
          <div className="mt-5" style={{ overflow: 'scroll' }}>{renderBlinkingItems()}</div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(OptionsTrackRecordPage)

import React, { useMemo, useState } from 'react'
import { useRequest } from '../../services/api'
import AirTable from '../ui/table'
import AutoComplete from '../ui/symbol-dropdown'
import qs from 'querystring'

function Quotes() {
  const [search, setSearch] = useState('')
  const query = qs.stringify({
    symbol: search,
  })
  const onInputChange = (e) => {
    setSearch(e.value)
  }
  const {data, error} = useRequest(`/admin/fin-hub/quote?${query}`)
  const [requesting, setRequesting] = useState(false)

  const columns = useMemo(() => {
    return [
      {
        Header: 'Current Price',
        accessor: 'currentPrice',
      },
      {
        Header: 'High Price',
        accessor: 'highPrice',
      },
      {
        Header: 'Low Price',
        accessor: 'lowPrice',
      },
      {
        Header: 'Open Price',
        accessor: 'openPrice',
      },
      {
        Header: 'Previous Close Price',
        accessor: 'previousClosePrice',
      },
    ]
  }, [requesting])

  return (
    <div className="container-fluid p-3">
      <div className="d-flex justify-content-between align-items-center mb-6">
        <h2>Quotes</h2>
        <div style={{width: '320px'}}>
          <AutoComplete onChange={onInputChange}/>
        </div>
      </div>
      <AirTable
        columns={columns}
        data={data}
        loading={!data && !error}
      />
    </div>
  )
}

export default React.memo(Quotes)

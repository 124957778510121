import React, { useMemo } from 'react'
import { useRequest } from '../../services/api'
import AirTable from '../ui/table'
import moment from 'moment'

function Trades() {
  const { data, error } = useRequest(`/admin/trades/grouped/day`)

  const { trades = [] } = data || {}

  const columns = useMemo(() => {
    return [
      {
        Header: 'Symbol',
        accessor: 'symbol',
      },
      {
        Header: 'Total Volume',
        accessor: 'totalVolume',
      },
      {
        Header: 'Day',
        accessor: 'day',
        Cell({ value }) {
          return moment(value).format('MMMM Do YYYY, h:mm')
        },
      }
    ]
  }, [])

  return (
    <div className="container-fluid p-3">
      <div className="flex justify-between items-center mb-6">
        <h2>Trades</h2>
      </div>
      <AirTable
        columns={columns}
        data={trades}
        loading={!data && !error}
      />
    </div>
  )
}

export default React.memo(Trades)


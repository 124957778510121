import AuthHandler from './AuthHandler';

class PeriodService {
  constructor(baseUrl) {
    if (!baseUrl) {
      throw new Error('missing baseUrl parameter');
    }

    this.baseUrl = baseUrl;
    this.authHandler = new AuthHandler(baseUrl); // Initialize AuthHandler
    this.axiosInstance = this.authHandler.axiosInstance; // Use axiosInstance from AuthHandler
  }

  async getPeriods(search = "") {
    try {
      return await this.authHandler.requestWithTokenRefresh(() =>
        this.axiosInstance.get(`${this.baseUrl}/admin/barchart/periods`)
      );
    } catch (error) {
      console.error("Error getting periods:", error);
      throw error;
    }
  }
}

export default PeriodService;

import React, { useCallback, useEffect, useState } from 'react'
import { coreAPI, useRequest } from '../../services/api'
import Form from './Form'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { sectorStockService } from '../../services'
import axios from 'axios'

function SectorStocksEditPage({ match: { params: { symbol } } }) {
  const history = useHistory()
  const [data, setData] = useState({})

  const getData = async () => {
    const { data: sectorStocks } = await coreAPI.get(`/admin/sector-stocks/${symbol}`)

    setData(sectorStocks)
  }

  useEffect(() => {
    getData()
  }, [])

  const onUpdate = useCallback(async (data, form) => {
    try {
      await sectorStockService.edit(data, symbol)
      toast.success('Updated')
      await getData()
      history.push('/sector-stocks')
    } catch (err) {
      return toast.error(err.response.data.message)
    }
  }, [])

  return (
    <div>
      {data &&
      <div className="p-3">
        <div className="row">
          <div className="col-md-12">
            <h3>Update Sector Stock</h3>
          </div>
          <div className="col-12">
            <Form data={data} onSave={onUpdate}/>
          </div>
        </div>
      </div>
      }
    </div>
  )
}

export default SectorStocksEditPage
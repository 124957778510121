import React, {useMemo, useState} from 'react'
import {useRequest} from '../../services/api'
import moment from "moment"
import AirTable from "../ui/table"
import qs from "querystring";
import AutoComplete from "../ui/symbol-dropdown";

function FinancialsReported() {
  const [search, setSearch] = useState('')
  const query = qs.stringify({
    symbol: search,
  })
  const {data, error} = useRequest(`/admin/fin-hub/financials-reported?${query}`)
  const [requesting, setRequesting] = useState(false)

  const {cik, symbol} = data || {}

  const columns = useMemo(() => {
    return [
      {
        Header: 'symbol',
        accessor: 'symbol',
      },
      {
        Header: 'Cik',
        accessor: 'cik',
      },
      {
        Header: 'Form',
        accessor: 'form',
      },
      {
        Header: 'Access Number',
        accessor: 'accessNumber',
      },
      {
        Header: 'Quarter',
        accessor: 'quarter',
      },
      {
        Header: 'Year',
        accessor: 'year',
      },
      {
        Header: 'Start Date',
        accessor: 'startDate',
        Cell({ value }) {
          return moment(value).format('MMMM Do YYYY h:mm')
        },
      },
      {
        Header: 'Accepted Date',
        accessor: 'acceptedDate',
        Cell({ value }) {
          return moment(value).format('MMMM Do YYYY h:mm')
        },
      },
      {
        Header: 'Filed Date',
        accessor: 'filedDate',
        Cell({ value }) {
          return moment(value).format('MMMM Do YYYY h:mm')
        },
      },
      {
        Header: 'End Date',
        accessor: 'endDate',
        Cell({ value }) {
          return moment(value).format('MMMM Do YYYY h:mm')
        },
      },
    ]
  }, [requesting])

  const onInputChange = (e) => {
    setSearch(e.value)
  }

  return (
    <div className="container-fluid p-3">
      <div className="d-flex justify-content-between align-items-center mb-6">
        <h2>Financials Reported: {symbol} - {cik}</h2>
        <div style={{width: '320px'}}>
          <AutoComplete onChange={onInputChange}/>
        </div>
      </div>
      <AirTable
        columns={columns}
        data={data?.data}
        loading={!data && !error}
      />
    </div>
  )
}

export default React.memo(FinancialsReported)

import React, { useMemo, useState } from 'react'
import { useRequest } from '../../services/api'
import AirTable from '../ui/table'
import moment from 'moment'
import AutoComplete from "../ui/symbol-dropdown";
import qs from "querystring";

function NewsSentiment() {
  const [search, setSearch] = useState('')
  const query = qs.stringify({
    symbol: search,
  })
  const { data, error } = useRequest(`/admin/fin-hub/news-sentiment?${query}`)
  const [requesting, setRequesting] = useState(false)

  const { newsSentiment = [] } = data || {}

  const columns = useMemo(() => {
    return [
      {
        Header: 'Symbol',
        accessor: 'symbol',
      },
      {
        Header: 'Company News Score',
        accessor: 'companyNewsScore',
      },
      {
        Header: 'Sector Average Bullish %',
        accessor: 'sectorAverageBullishPercent',
      },
      {
        Header: 'Sector Average News Score',
        accessor: 'sectorAverageNewsScore',
      },
      {
        Header: 'Sentiment Bearish %',
        accessor: 'sentiment.bearishPercent',
      },
      {
        Header: 'Sentiment Bullish %',
        accessor: 'sentiment.bullishPercent',
      },
      {
        Header: 'Articles in Last Week',
        accessor: 'buzz.articlesInLastWeek',
      },
      {
        Header: 'Buzz',
        accessor: 'buzz.buzz',
      },
      {
        Header: 'Weekly Average',
        accessor: 'buzz.weeklyAverage',
      },
    ]
  }, [requesting])

  const onInputChange = (e) => {
    setSearch(e.value)
  }

  return (
    <div className="container-fluid p-3">
      <div className="d-flex justify-content-between align-items-center mb-6">
        <h2>News Sentiment</h2>
        <div style={{width: '320px'}}>
          <AutoComplete onChange={onInputChange}/>
        </div>
      </div>
      <AirTable
        columns={columns}
        data={newsSentiment}
        loading={!data && !error}
      />
    </div>
  )
}

export default React.memo(NewsSentiment)

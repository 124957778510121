import React from 'react'
import { useField, useForm } from 'react-final-form-hooks'
import { required } from '../../helpers/hooks/validators'
import { toast } from 'react-toastify'
import { strategyService } from '../../services'

function EditStrategyForm({ revalidate, data, closeModal }) {
  const { form, handleSubmit, pristine, submitting } = useForm({
    async onSubmit(values, formAPI) {
      await strategyService.edit(values, data.id)
      toast.success('Strategy successfully edited')
      await revalidate()
      closeModal()
    },
    initialValues: {
      active: data.active,
      env: data.env,
      accountSize: data.accountSize,
      optionPriceFrom: data.optionPriceFrom,
      optionPriceTo: data.optionPriceTo,
    }
  })

  const fieldEnv = useField('env', form, required)
  const fieldActive = useField('active', form, required)
  const fieldAccountSize = useField('accountSize', form, required)
  const fieldOptionPriceFrom = useField('optionPriceFrom', form, required)
  const fieldOptionPriceTo = useField('optionPriceTo', form, required)

  return (
    <form className="bg-white rounded p-3" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <label>Environment</label>
            <select {...fieldEnv.input} className="form-control rounded">
              <option value="">Select...</option>
              <option value="test">Test</option>
              <option value="live">Live</option>
              <option value="sandbox">Sandbox</option>
            </select>
            {fieldEnv.meta.touched && fieldEnv.meta.error && (
              <span className="text-danger text-sm">{fieldEnv.meta.error}</span>
            )}
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-group">
            <label>Active</label>
            <select {...fieldActive.input} className="form-control rounded">
              <option value="">Select...</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
            {fieldActive.meta.touched && fieldActive.meta.error && (
              <span className="text-danger text-sm">{fieldActive.meta.error}</span>
            )}
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-group">
            <label>Option Price From</label>
            <input {...fieldOptionPriceFrom.input} placeholder="0.51" className="form-control rounded" />
            {fieldOptionPriceFrom.meta.touched && fieldOptionPriceFrom.meta.error && (
              <span className="text-danger text-sm">{fieldOptionPriceFrom.meta.error}</span>
            )}
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-group">
            <label>Option Price To</label>
            <input {...fieldOptionPriceTo.input} placeholder="0.70" className="form-control rounded" />
            {fieldOptionPriceTo.meta.touched && fieldOptionPriceTo.meta.error && (
              <span className="text-danger text-sm">{fieldOptionPriceTo.meta.error}</span>
            )}
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-group">
            <label>Account Size</label>
            <input {...fieldAccountSize.input} placeholder="100" className="form-control rounded" />
            {fieldAccountSize.meta.touched && fieldAccountSize.meta.error && (
              <span className="text-danger text-sm">{fieldAccountSize.meta.error}</span>
            )}
          </div>
        </div>
        <div className="col-md-12 mt-4">
          <div className="form-group">
            <button type="submit" className="btn btn-primary" disabled={pristine || submitting}>
              Edit
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default EditStrategyForm

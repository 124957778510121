// import React, { Component } from "react"
// import Table from '../../ui/table'
// import {pressReleases} from '../../services'
// import { ToastContainer, toast } from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css'
// import Pagination from "react-js-pagination"
//
// class PressReleases extends Component {
//   state = {
//     loading: true,
//     majorDevelopment: [],
//   }
//
//   async getPressReleases (page = 1) {
//     const result = await pressReleases.getAll(page)
//     console.log(result.data)
//     this.setState({
//       loading: false,
//       majorDevelopment: !result.data.majorDevelopment.length? [result.data.majorDevelopment] : result.data.majorDevelopment,
//       symbol: result.data.symbol,
//       // totalPages: result.data.totalPages,
//       // pageSize: result.data.pageSize,
//       // activePage: result.data.currentPage,
//       // totalItems: result.data.totalItems,
//     })
//   }
//
//   // handlePageChange = async (pageNumber) => {
//   //   this.setState({activePage: pageNumber})
//   //
//   //   await this.getnewsSentiment(pageNumber)
//   // }
//
//   async componentDidMount () {
//     await this.getPressReleases()
//   }
//
//   render () {
//     return (
//       <div>
//         <ToastContainer />
//         <section className="content-header">
//           <div className="container-fluid">
//             <div className="row mb-2">
//               <div className="col-sm-6">
//                 <h1>Press Releases: {this.state.symbol}</h1>
//               </div>
//             </div>
//           </div>
//         </section>
//         <div className="container-fluid">
//           <div className="dataTables_wrapper form-inline dt-bootstrap">
//             <div className="row w-100">
//               <div className="col-md-12 col-sm-12 col-12">
//                 {
//                   <Table
//                     loading={this.state.loading}
//                     fieldNames={[
//                       'symbol',
//                       'description',
//                       'headline',
//                       'datetime',
//                     ]}
//                     columnNames={[
//                       'Symbol',
//                       'Description',
//                       'Headline',
//                       'Date',
//                     ]}
//                     rows={this.state.majorDevelopment}
//                     basePath='stock-candles'
//                   />
//                 }
//                 {/*<Pagination*/}
//                 {/*  activePage={this.state.activePage}*/}
//                 {/*  itemsCountPerPage={this.state.pageSize}*/}
//                 {/*  totalItemsCount={this.state.totalItems}*/}
//                 {/*  pageRangeDisplayed={this.state.totalPages}*/}
//                 {/*  onChange={this.handlePageChange.bind(this)}*/}
//                 {/*/>*/}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     )
//   }
// }
//
// export default PressReleases

import React, { useMemo, useState } from 'react'
import { useRequest } from '../../services/api'
import AirTable from '../ui/table'
import moment from 'moment'
import AutoComplete from "../ui/symbol-dropdown";
import qs from "querystring";

function PressReleases() {
  const [search, setSearch] = useState('')
  const query = qs.stringify({
    symbol: search,
  })
  const { data, error } = useRequest(`/admin/fin-hub/press-releases?${query}`)
  const [requesting, setRequesting] = useState(false)

  const { majorDevelopment = [], symbol } = data || {}

  const columns = useMemo(() => {
    return [
      {
        Header: 'Symbol',
        accessor: 'symbol',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Headline',
        accessor: 'headline',
      },
      {
        Header: 'Date',
        accessor: 'datetime',
        Cell({ value }) {
          return moment(value).format('MMMM Do YYYY, h:mm')
        },
      }
    ]
  }, [requesting])

  const onInputChange = (e) => {
    setSearch(e.value)
  }

  return (
    <div className="container-fluid p-3">
      <div className="d-flex justify-content-between align-items-center mb-6">
        <h2>Press Releases: {symbol}</h2>
        <div style={{width: '320px'}}>
          <AutoComplete onChange={onInputChange}/>
        </div>
      </div>
      <AirTable
        columns={columns}
        data={majorDevelopment}
        loading={!data && !error}
      />
    </div>
  )
}

export default React.memo(PressReleases)


import React, { useMemo, useState } from 'react'
import { useRequest } from '../../services/api'
import AirTable from '../ui/table'
import moment from 'moment'
import AutoComplete from "../ui/symbol-dropdown";
import qs from "querystring";

function CompanyNews() {
  const [search, setSearch] = useState('')
  const query = qs.stringify({
    symbol: search,
  })
  const { data, error } = useRequest(`/admin/fin-hub/company-news?${query}`)
  const [requesting, setRequesting] = useState(false)

  const { companyNews = [] } = data || {}

  const columns = useMemo(() => {
    return [
      {
        Header: 'Category',
        accessor: 'category',
      },
      {
        Header: 'Related',
        accessor: 'related',
      },
      {
        Header: 'Headline',
        accessor: 'headline',
      },
      {
        Header: 'Summary',
        accessor: 'summary',
      },
      {
        Header: 'Date',
        accessor: 'datetime',
        Cell({ value }) {
          return moment(value).format('MMMM Do YYYY, h:mm')
        },
      },
      {
        Header: 'Image',
        accessor: 'image',
        Cell({ value }) {
          return <a href={value} target="_blank">{value}</a>
        },
      },
      {
        Header: 'Url',
        accessor: 'url',
        Cell({ value }) {
          return <a href={value} target="_blank">{value}</a>
        },
      },
      {
        Header: 'Source',
        accessor: 'source',
      },
    ]
  }, [requesting])

  const onInputChange = (e) => {
    setSearch(e.value)
  }

  return (
    <div className="container-fluid p-3">
      <div className="d-flex justify-content-between align-items-center mb-6">
        <h2>Company News</h2>
        <div style={{width: '320px'}}>
          <AutoComplete onChange={onInputChange}/>
        </div>
      </div>
      <AirTable
        columns={columns}
        data={companyNews}
        loading={!data && !error}
      />
    </div>
  )
}

export default React.memo(CompanyNews)

import React from 'react'
import { useField, useForm } from 'react-final-form-hooks'
import { required } from '../../helpers/hooks/validators'
import { toast } from 'react-toastify'
import Select from 'react-select'

const symbols = [
    'C',
    'DAL',
    'TLRY',
    'IWM',
    'SPY',
    'QQQ',
    'AMD',
    'NVDA',
    'NFLX',
    'META',
    'AMZN',
    'GME',
    'AMC',
    'PLTR',
    'AAPL',
    'MU',
    'COIN',
    'OXY',
    'XOM',
    'BABA',
    'NIO',
    'TGT',
    'WMT',
    'BA',
    'MSFT',
    'SOFI',
    'DKNG',
    'BAC',
    'TSLA',
    'GOOGL',
    'ABNB',
    'AFRM',
    'AI',
    'CS',
    'INTC',
    'JPM',
    'KRE',
    'SQ',
    'TLT',
    'UVXY',
    'WFC',
    'ARKK',
    'DIS',
    'EEM',
    'GLD',
    'PATH',
    'PYPL',
    'RBLX',
    'ROKU',
    'SCHW',
    'SHOP',
    'SLV',
    'SNAP',
    'SNOW',
]
const SYMBOLS = symbols.map(s => ({ label: s, value: s }))


function ManualTradeForm({ onSave = () => null }) {
  const { form, handleSubmit, pristine, submitting } = useForm({
    async onSubmit(values, formAPI) {
      values.symbol = values.symbol.value
      try {
        await onSave({ ...values, }, formAPI)
      } catch (err) {
        toast.error(err.response.data.message)
      }
    },
  })

  const fieldSymbol = useField('symbol', form, required)
  const fieldOptionType = useField('optionType', form, required)
  const fieldNumberOfContracts = useField('numberOfContracts', form, required)

  return (
    <form className="bg-white rounded p-3" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <label>Symbol</label>
            <Select
              name="symbol"
              options={SYMBOLS}
              className="basic-multi-select"
              classNamePrefix="select"
              {...fieldSymbol.input}
            />
            {fieldSymbol.meta.touched && fieldSymbol.meta.error && (
              <span className="text-danger text-sm">{fieldSymbol.meta.error}</span>
            )}
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-group">
            <label>Option Type</label>
            <select {...fieldOptionType.input} className="form-control rounded">
              <option value="">Select...</option>
              <option value="put">Put</option>
              <option value="call">Call</option>
            </select>
            {fieldOptionType.meta.touched && fieldOptionType.meta.error && (
              <span className="text-danger text-sm">{fieldOptionType.meta.error}</span>
            )}
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-group">
            <label>Number of Contracts</label>
            <input {...fieldNumberOfContracts.input} placeholder="Number of Contracts" className="form-control rounded" />
            {fieldNumberOfContracts.meta.touched && fieldNumberOfContracts.meta.error && (
              <span className="text-danger text-sm">{fieldNumberOfContracts.meta.error}</span>
            )}
          </div>
        </div>
        <div className="col-md-12 mt-4">
          <div className="form-group">
            <button type="submit" className="btn btn-primary" disabled={pristine || submitting}>
              Open Order
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default ManualTradeForm

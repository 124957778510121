import React, { useMemo } from 'react'
import { useRequest } from '../../services/api'
import AirTable from "../ui/table"
import { capitalCase, } from 'change-case'

function SpacsAllTeams() {
  const {data, error} = useRequest('/admin/spacs/teams')

  const {teams = []} = data || {}

  const columns = useMemo(() => {
    const cols = []

    if (teams.length === 0) {
      return cols
    }
    const single = teams
    for (const col of Object.keys(single)) {
      cols.push({
        Header: capitalCase(col),
        accessor: col,
      })
    }

    return cols
  }, [teams])

  return (
    <div className="container-fluid p-3">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h2>SPAC's All Teams</h2>
      </div>

      <AirTable
        columns={columns}
        data={[teams]}
        loading={!data && !error}
      />
    </div>
  )
}

export default React.memo(SpacsAllTeams)

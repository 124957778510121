import React, { useMemo, useState } from "react";
import { useRequest } from "../../services/api";
import { Link } from "react-router-dom";
import AirTable from "../ui/table";
import Popover from "../../ui/popper";
import { administratorService } from "../../services";
import { toast, ToastContainer } from "react-toastify";

function AdministratorsListPage() {
  const { data, error, revalidate } = useRequest(`/admin/administrators`);
  const [requesting, setRequesting] = useState(false);

  const { administrators = [] } = data || {};

  const columns = useMemo(() => {
    return [
      {
        Header: "First Name",
        accessor: "firstName",
      },
      {
        Header: "Last Name",
        accessor: "lastName",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Details",
        accessor: (row) => row,
        Cell({ value: row }) {
          return (
            <div className="flex justify-center items-center">
              <Link to={`/administrators/detail/${row.id}`}>
                <button className="btn btn-sm btn-primary">Detail</button>
              </Link>
              <Popover
                trigger="click"
                tooltip={
                  <div className="p-4" style={{ maxWidth: 200 }}>
                    <p className="">
                      Are you sure that you want to delete this admin?
                    </p>
                    <div className="flex justify-end">
                      <button
                        className="btn btn-sm btn-danger"
                        onClick={async () => {
                          try {
                            setRequesting(true);
                            await administratorService.delete(row.id);
                            await revalidate();
                            toast.success(
                              `Successfully deleted administrator with ID: ${row.id}`
                            );
                          } catch (err) {
                            console.error(err);
                            toast.success(err.message);
                          } finally {
                            setRequesting(false);
                          }
                        }}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                }
              >
                <button className="btn btn-sm btn-danger ml-3">Delete</button>
              </Popover>
            </div>
          );
        },
      },
    ];
  }, [requesting]);

  return (
    <div className="p-3">
      <ToastContainer />
      <div className="row">
        <div className="col-md-12 pb-3">
          <div className="d-flex justify-content-between align-items-center">
            <h3>Administrators</h3>
            <Link to="/administrators/create">
              <button className="btn btn-primary rounded">Create New</button>
            </Link>
          </div>
        </div>
        <div className="col-md-12">
          <AirTable
            columns={columns}
            data={administrators}
            totalItem={administrators.length}
            loading={!data && !error}
          />
        </div>
      </div>
    </div>
  );
}

export default React.memo(AdministratorsListPage);

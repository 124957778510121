import React from "react";
import { useField, useForm } from "react-final-form-hooks";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { required } from "../../helpers/hooks/validators";
import { authService } from "../../services";
import { onStoreUser } from "../../store/actions/auth";
import { useHistory } from "react-router-dom";

function ProfileValidateOtp() {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const history = useHistory();

  const { form, handleSubmit, pristine, submitting } = useForm({
    async onSubmit(values, formAPI) {
      try {
        const { data } = await authService.validateOtp(values, formAPI);
        toast.success("2FA validated successfully");

        dispatch(
          onStoreUser({
            ...user,
            otpEnabled: data.otpEnabled,
            otpVerified: data.otpVerified,
          })
        );

        history.push('/trade-logs');
      } catch (err) {
        return toast.error("Invalid authentication code.");
      }
    },
    initialValues: {
      token: "",
    },
  });

  const fieldToken = useField("token", form, required);

  console.log(user);
  return (
    <div className="container-fluid p-3">
      <ToastContainer />
      <div className="mb-6">
        <div>
          <h2> Welcome Back</h2>
          <p>
            Verify the Authentication Code. Open the two-step verification app
            on your mobile device to get your verification code.
          </p>
        </div>
        <form className="w-50" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <input
                  {...fieldToken.input}
                  placeholder="Enter token"
                  className="form-control rounded"
                />
                {fieldToken.meta.touched && fieldToken.meta.error && (
                  <span className="text-danger text-sm">
                    {fieldToken.meta.error}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={pristine || submitting}
                >
                  Verify
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default React.memo(ProfileValidateOtp);

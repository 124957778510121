import React, { useMemo, useState } from 'react'
import { useRequest } from '../../services/api'
import moment from "moment"
import AirTable from "../ui/table"
import helpers from '../../services/helpers'

function SalesRevenueGrowth() {
  const { data, error } = useRequest(`/admin/fin-hub/sales-revenue-growth`)

  const { financials = [] } = data || {}

  const columns = useMemo(() => {
    return [
      {
        Header: 'Actual Revenue',
        accessor: 'actualRevenue',
        Cell: ({ value }) => {
          return helpers.formatCurrency(value)
        }
      },
      {
        Header: 'Estimate High',
        accessor: 'estimateHigh',
        Cell: ({ value }) => {
          return helpers.formatCurrency(value)
        }
      },
      {
        Header: 'Estimate Avg',
        accessor: 'estimateAvg',
        Cell: ({ value }) => {
          return helpers.formatCurrency(value)
        }
      },
      {
        Header: 'Estimate Low',
        accessor: 'estimateLow',
        Cell: ({ value }) => {
          return helpers.formatCurrency(value)
        }
      },
      {
        Header: 'Growth From Last Year Quarter',
        accessor: 'growthFromLastYearQuarter',
      },
      {
        Header: 'Growth From Previous Quarter',
        accessor: 'growthFromPreviousQuarter',
      },
      {
        Header: 'Period',
        accessor: 'period',
        Cell({ value }) {
          return moment(value).format('MMMM Do YYYY')
        },
      },
    ]
  }, [])

  return (
    <div className="container-fluid p-3">
      <div className="flex justify-between items-center mb-6">
        <h2>Sales Revenue Growth</h2>
      </div>
      <AirTable
        columns={columns}
        data={financials}
        loading={!data && !error}
      />
    </div>
  )
}

export default React.memo(SalesRevenueGrowth)

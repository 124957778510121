import React, { useMemo, useState } from 'react'
import { useRequest } from '../../services/api'
import AirTable from '../ui/table'
import moment from "moment";
import qs from "querystring";
import AutoComplete from "../ui/symbol-dropdown";

function StockOptions() {
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const query = qs.stringify({
    symbol: search,
    page,
    pageSize: 50,
  })

  const { data, error } = useRequest(`/admin/stock-options?${query}`)
  const { totalItems, totalPages } = data || {}

  const columns = useMemo(() => {
    return [
      {
        Header: 'Symbol',
        accessor: 'symbol',
      },
      {
        Header: 'Ask',
        accessor: 'ask',
      },
      {
        Header: 'Bid',
        accessor: 'bid',
      },
      {
        Header: 'CFI Code',
        accessor: 'cfiCode',
      },
      {
        Header: 'Close',
        accessor: 'close',
      },
      {
        Header: 'Closing Price',
        accessor: 'closingPrice',
      },
      {
        Header: 'Contract Description',
        accessor: 'contractDescription',
      },
      {
        Header: 'Contract Name',
        accessor: 'contractName',
      },
      {
        Header: 'Contract Size',
        accessor: 'contractSize',
      },
      {
        Header: 'Currency',
        accessor: 'currency',
      },
      {
        Header: 'Exchange Code',
        accessor: 'exchangeCode',
      },
      {
        Header: 'Exchange Mic',
        accessor: 'exchangeMic',
      },
      {
        Header: 'Exercise Style',
        accessor: 'exerciseStyle',
      },
      {
        Header: 'Expiration Date',
        accessor: 'expirationDate',
        Cell({ value }) {
          return moment(value).format('MMMM Do YYYY, h:mm')
        },
      },
      {
        Header: 'Figi',
        accessor: 'figi',
      },
      {
        Header: 'High',
        accessor: 'high',
      },
      {
        Header: 'Is Adjusted',
        accessor: 'isAdjusted',
        Cell({ value }) {
          return value ? 'Yes' : 'No'
        },
      },
      {
        Header: 'Last Trade Date',
        accessor: 'lastTradeDate',
        Cell({ value }) {
          return moment(value).format('MMMM Do YYYY, h:mm')
        },
      },
      {
        Header: 'Last Trade Time',
        accessor: 'lastTradeTime',
      },
      {
        Header: 'Last Updated',
        accessor: 'lastUpdated',
        Cell({ value }) {
          return moment(value).format('MMMM Do YYYY, h:mm')
        },
      },
      {
        Header: 'Low',
        accessor: 'low',
      },
      {
        Header: 'Open',
        accessor: 'open',
      },
      {
        Header: 'Margin Price',
        accessor: 'marginPrice',
      },
      {
        Header: 'Open Interest',
        accessor: 'openInterest',
      },
      {
        Header: 'Settlement Price',
        accessor: 'settlementPrice',
      },
      {
        Header: 'Side',
        accessor: 'side',
      },
      {
        Header: 'Strike Price',
        accessor: 'strikePrice',
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Volume',
        accessor: 'volume',
      },
      {
        Header: 'External Id',
        accessor: 'externalId',
      },
      {
        Header: 'Date',
        accessor: 'date',
        Cell({ value }) {
          return moment(value).format('MMMM Do YYYY, h:mm')
        },
      },
      {
        Header: 'Updated',
        accessor: 'updated',
        Cell({ value }) {
          return moment(value).format('MMMM Do YYYY, h:mm')
        },
      },
      {
        Header: 'Source',
        accessor: 'source',
      },
      {
        Header: 'Key',
        accessor: 'key',
      },
      {
        Header: 'Sub Key',
        accessor: 'subkey',
      },
    ]
  }, [])

  const onInputChange = (e) => {
    setSearch(e.value)
  }

  return (
    <div className="container-fluid p-3">
      <div className="d-flex justify-content-between align-items-center mb-6">
        <h2>Stock Options</h2>
        <div style={{ width: '320px' }}>
          <AutoComplete onChange={onInputChange} />
        </div>
      </div>
      <AirTable
        columns={columns}
        data={data?.stockOptions}
        loading={!data && !error}
        totalItem={totalItems}
        totalPage={totalPages}
        onChangePageIndex={setPage}
        pagging
      />
    </div>
  )
}

export default React.memo(StockOptions)

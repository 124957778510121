import React, {useMemo, useState} from 'react'
import {useRequest} from '../../services/api'
import moment from "moment"
import AirTable from "../ui/table"
import helpers from '../../services/helpers'
import AutoComplete from "../ui/symbol-dropdown";
import qs from "querystring";

function RevenueEstimates() {
  const [search, setSearch] = useState('')
  const query = qs.stringify({
    symbol: search,
  })
  const {data, error} = useRequest(`/admin/fin-hub/revenue-estimates?${query}`)
  const [requesting, setRequesting] = useState(false)

  const {freq, symbol} = data || {}

  const columns = useMemo(() => {
    return [
      {
        Header: 'Revenue High',
        accessor: 'revenueHigh',
        Cell: ({ value }) => {
          return helpers.formatCurrency(value)
        }
      },
      {
        Header: 'Revenue Avg',
        accessor: 'revenueAvg',
        Cell: ({ value }) => {
          return helpers.formatCurrency(value)
        }
      },
      {
        Header: 'Revenue Low',
        accessor: 'revenueLow',
        Cell: ({ value }) => {
          return helpers.formatCurrency(value)
        }
      },
      {
        Header: 'Number Analysts',
        accessor: 'numberAnalysts',
      },
      {
        Header: 'Period',
        accessor: 'period',
        Cell({ value }) {
          return moment(value).format('MMMM Do YYYY')
        },
      },
    ]
  }, [requesting])

  const onInputChange = (e) => {
    setSearch(e.value)
  }

  return (
    <div className="container-fluid p-3">
      <div className="d-flex justify-content-between align-items-center mb-6">
        <h2>Revenue Estimates: {symbol} - {freq}</h2>
        <div style={{width: '320px'}}>
          <AutoComplete onChange={onInputChange}/>
        </div>
      </div>
      <AirTable
        columns={columns}
        data={data?.data}
        loading={!data && !error}
      />
    </div>
  )
}

export default React.memo(RevenueEstimates)

import React, {useMemo, useState} from 'react'
import {useRequest} from '../../services/api'
import AirTable from '../ui/table'
import moment from 'moment'
import AutoComplete from '../ui/periods-dropdown'
import { usePeriods } from '../../helpers/hooks/shared'

function AphaOptionsDifferences() {
  const [search, setSearch] = useState('')
  const periods = usePeriods()
  const {data, error} = useRequest(periods.length > 0 ? `/admin/barchart/saved-options/${search || periods[0]}` : null)

  const [requesting, setRequesting] = useState(false)

  const {options = []} = data || {}

  const columns = useMemo(() => {
    return [
      {
        Header: 'Symbol',
        accessor: 'symbol',
      },
      {
        Header: 'Bid Size',
        accessor: 'bid_size',
      },
      {
        Header: 'Change',
        accessor: 'change',
      },
      {
        Header: 'Code',
        accessor: 'code',
      },
      {
        Header: 'Delta',
        accessor: 'delta',
      },
      {
        Header: 'Exchange',
        accessor: 'exchange',
      },
      {
        Header: 'Expiration Type',
        accessor: 'expiration_type',
      },
      {
        Header: 'Gama',
        accessor: 'gama',
      },
      {
        Header: 'High',
        accessor: 'high',
      },
      {
        Header: 'Last',
        accessor: 'last',
      },
      {
        Header: 'Low',
        accessor: 'low',
      },
      {
        Header: 'Open',
        accessor: 'open',
      },
      {
        Header: 'Open Interest',
        accessor: 'open_interest',
      },
      {
        Header: 'Percent Change',
        accessor: 'percent_change',
      },
      {
        Header: 'Premium',
        accessor: 'premium',
      },
      {
        Header: 'Previous',
        accessor: 'previous',
      },
      {
        Header: 'Rho',
        accessor: 'rho',
      },
      {
        Header: 'Settlment',
        accessor: 'settlment',
      },
      {
        Header: 'Strike',
        accessor: 'strike',
      },
      {
        Header: 'Theta',
        accessor: 'theta',
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Vega',
        accessor: 'vega',
      },
      {
        Header: 'Volatility',
        accessor: 'volatility',
      },
      {
        Header: 'Volume',
        accessor: 'volume',
      },
      {
        Header: 'Period',
        accessor: 'period',
        Cell({value}) {
          return value ? moment(value).format('MM-DD-YYYY HH:mm') : ""
        }
      },
      {
        Header: 'Ask Date',
        accessor: 'ask_date',
        Cell({value}) {
          return moment(value).format('MM-DD-YYYY HH:mm')
        }
      },
      {
        Header: 'Bid Date',
        accessor: 'bid_date',
        Cell({value}) {
          return moment(value).format('MM-DD-YYYY HH:mm')
        }
      },
      {
        Header: 'Last Trade Date',
        accessor: 'last_trade_date',
        Cell({value}) {
          return value ? moment(value).format('MM-DD-YYYY HH:mm') : ""
        }
      },
      {
        Header: 'Expiration Date',
        accessor: 'expiration_date',
        Cell({value}) {
          return moment(value).format('MM-DD-YYYY HH:mm')
        }
      },
      {
        Header: 'Date',
        accessor: 'date',
        Cell({value}) {
          return moment(value).format('MM-DD-YYYY HH:mm')
        }
      },
    ]
  }, [requesting])

  const onInputChange = (e) => {
    setSearch(e.value)
  }

  return (
    <div className="container-fluid p-3">
      <div className="d-flex justify-content-between align-items-center mb-6">
        <h2>APHA Options Differences</h2>
        <div style={{width: '320px'}}>
          <AutoComplete onChange={onInputChange} periods={periods}/>
        </div>
      </div>
      <AirTable
        columns={columns}
        data={options}
        loading={!data && !error}
      />
    </div>
  )
}

export default React.memo(AphaOptionsDifferences)

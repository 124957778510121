import axios from "axios";
import Cookies from "js-cookie";

class AuthHandler {
  constructor(baseUrl) {
    if (!baseUrl) {
      throw new Error("missing baseUrl parameter");
    }

    this.baseUrl = baseUrl;
    this.axiosInstance = axios.create({
      baseURL: this.baseUrl,
    });

    this.setAuthInterceptor();
  }

  setAuthInterceptor() {
    this.axiosInstance.interceptors.request.use(
      async (config) => {
        const accessToken = Cookies.get("accessToken");
        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  async refreshToken(refreshToken) {
    try {
      const refreshResponse = await axios.post(`${this.baseUrl}/api/auth/refresh`, {
        refreshToken,
      });
      return refreshResponse.data;
    } catch (error) {
      throw new Error("Failed to refresh access token");
    }
  }

  async requestWithTokenRefresh(requestFunc) {
    try {
      return await requestFunc();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        const refreshToken = Cookies.get("refreshToken"); // Get the refresh token
        if (refreshToken) {
          try {
            // Attempt to refresh the access token
            const refreshResponse = await this.refreshToken(refreshToken);
            const newAccessToken = refreshResponse.accessToken;
            // Update the access token cookie
            Cookies.set("accessToken", newAccessToken);
            // Retry the original request
            return await requestFunc();
          } catch (refreshError) {
            Cookies.remove("accessToken");
            Cookies.remove("refreshToken");
            localStorage.removeItem("btw_user");
            window.location.replace("/login");
            throw new Error("Failed to refresh access token");
          }
        }
      }
      throw error;
    }
  }
}

export default AuthHandler;
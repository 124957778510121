import React from 'react'
import Select from 'react-select'

function AutoComplete({ selectedOptions, options, defaultOption, onKeyUp, onChange, value, isMulti }) {
  const defaultValue =  options?.filter(o => selectedOptions?.find(s => o.value === s)) || []

  return (
    <div onKeyUp={onKeyUp}>
      <Select
        styles={{
          // Fixes the overlapping problem of the component
          menu: provided => ({ ...provided, zIndex: 9999 })
        }}
        options={options}
        isMulti={isMulti}
        onChange={onChange}
        defaultValue={defaultOption && defaultValue}
      />
    </div>
  )
}

export default AutoComplete
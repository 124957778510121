import React, { useMemo } from 'react'
import { useRequest } from '../../services/api'
import AirTable from "../ui/table"
import { capitalCase, } from 'change-case'

function SpacsAllCompanies() {
  const {data, error} = useRequest('/admin/spacs/all-companies')

  const {companies = []} = data || {}

  const columns = useMemo(() => {
    const cols = []

    if (companies.length === 0) {
      return cols
    }
    const single = companies[0]

    for (const col of Object.keys(single)) {
      cols.push({
        Header: capitalCase(col),
        accessor: col,
      })
    }

    return cols
  }, [companies])

  return (
    <div className="container-fluid p-3">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h2>SPAC's All Companies</h2>
      </div>
      <AirTable
        columns={columns}
        data={companies}
        loading={!data && !error}
      />
    </div>
  )
}

export default React.memo(SpacsAllCompanies)
